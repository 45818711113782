import React from "react";
import CardAlcohol from "../../Component/technology/CardAlcohol";

const AlcoholProduction = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="grainCardTable">
            <div className="grainBioXim">
              <CardAlcohol />
            </div>
            <table>
              <tr>
                <td>Наименование ферментного препарата</td>
                <td>Применение</td>
                <td>Дозировка</td>
                <td>Фасовка</td>
              </tr>
              <tr className="tableCenter">
                <th></th>
                <th>
                  <center>ОСАХАРИВАЮЩИЕ ФЕРМЕНТНЫЕ ПРЕПАРАТЫ</center>
                </th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th>
                  <p> Saczyme Plus 2X</p>
                  <i>Глюкоамилазная активность</i>
                  ГлС 20000 ед./мл + АС 250 ед./мл по ГОСТ 34440-2018
                </th>
                <th>
                  Комплексный ферментный препарат глюкоамилазы и альфа-амилазы.
                  Работает в диапазоне температуры от 30 до 65°C, диапазон рН от
                  3 до 6.5.
                </th>
                <th>0,5-0,6 кг/тонну условного крахмала</th>
                <th> 25 кг</th>
              </tr>
              <tr>
                <th>
                  <p>Saczyme Yield/Сахзайм Илд</p>
                  <i>Комплексный препарат</i> <br />
                  ГлС 14000 ед./мл по ГОСТ 34440-2018 <br />
                  <i>α-амилаза + целлюлолитический комплекс </i>
                </th>
                <th>
                  Препарат премиум класса, содержащий помимо глюкоамилaзы и
                  кислой альфа-амилазы новый целлюлолитический комплекс,
                  позволяющий увеличить выход спирта за счет выделения из
                  клеточной стенки зерна дополнительные сбраживаемые сахара.
                </th>
                <th>0,6-0,9 кг/тонну условного крахмала </th>
                <th> 25 кг</th>
              </tr>
              <tr className="tableCenter">
                <th></th>
                <th>
                  <center>ПРЕПАРАТЫ АЛЬФА-АМИЛАЗЫ</center>
                </th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th>
                  <p>LpHera</p> <i>Термостабильная α-амилаза</i> <br />
                  АС=1500 ед./мл. При Т=30°C АС=8000 ед./мл. При Т=95°C по ГОСТ
                  34440-2018
                </th>
                <th>
                  Термостабильная α-амилаза, имеющая самый широкий рабочий
                  диапазон температуры и рН. Самый стабильный препарат.
                  Выдерживает температурный режим до 96°C в течение всего
                  процесса разваривания, а также прекрасно работает при рН 4,5.
                </th>
                <th>0,2-0,35 кг/тонну условного крахмала</th>
                <th>30 кг</th>
              </tr>
              <tr>
                <th>
                  <p>Liquoflow Yield</p>
                  <i>Термостабильная α-амилаза </i>
                </th>
                <th>
                  Новая термостабильная α-амилаза, содержащая в себе
                  дополнительное количество термостабильной глюкоамилазы и
                  протеазы. Применение данного продукта позволяет добиться
                  увеличения выхода спирта.
                </th>
                <th>0,2-0,4 кг/тонну условного крахмала </th>
                <th>30 кг</th>
              </tr>
              <tr>
                <th>
                  <p>Liquoflow Go2X/Ликвофло Гоу 2Х</p>
                  <i>Термостабильная α-амилаза </i>
                </th>
                <th>
                  Снижение вязкости, без необходимости наличия кальция,
                  разжижение в широком диапазоне рН
                </th>
                <th>0,15-0,25 кг/тонну условного крахмала</th>
                <th>30 кг</th>
              </tr>
              <tr>
                <th>
                  <p>BAN 480 LS/БАН 480ЛС</p>
                  <i>Мезофильная α-амилаза</i>
                  <br />
                  АС=3500 ед./мл. При Т=30°C по ГОСТ 34440-2018
                </th>
                <th>
                  Высокоактивная бактериальная α-амилаза для гидролиза
                  α-1,4гликозидных связей в амилозе и амилопектине, уменьшая
                  вязкость и разжижая клейстеризованный крахмал.
                </th>
                <th>0,4-0,6 кг/тонну условного крахмала </th>
                <th>30 кг</th>
              </tr>
              <tr>
                <th>
                  <p>Fungamyl 800 L/Фунгамил 800Л</p>
                  <i>Грибная α-амилаза</i> <br />
                  Активность: АС = 6500 ед./мл По ГОСТ Р 54330-2011
                </th>
                <th>
                  Мальтогенная α-амилаза, расщепляющая α-1,4 глюкозидные связи,
                  образуя декстрины, олигосахариды и большое количество
                  мальтозы. Работает в диапазоне температуры от 30 до 60°C, pH
                  от 4.0 до 6.0.
                </th>
                <th>0,1-0,2 кг/тонну условного крахмала </th>
                <th>30 кг</th>
              </tr>
              <tr className="tableCenter">
                <th></th>
                <th>
                  <center>ПРЕПАРАТЫ ДЛЯ ВОЗДЕЙСТВИЯ НА НКП</center>
                </th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th>
                  <p>Viscoferm</p>
                  <i>
                    Комплексный препарат ксиланазная, целлюлолитическая,
                    α-амилазная, β-глюканазная и др. активности
                  </i>
                </th>
                <th>
                  Комплексный препарат для всех видов сырья. Применяется для
                  снижения вязкости при переработке высококонцентрированньж
                  замесов ( 22–30 % СВ ).
                </th>
                <th>0,1-0,3 кг/т СВ зерна</th>
                <th>25 кг</th>
              </tr>
              <tr>
                <th>
                  <p>Viscozyme НТ FG/Вискозим HT FG</p>
                  <i>Комплексный препарат</i>
                  <br />
                  Для переработки высококонцентрированного сусла
                </th>
                <th>
                  Препарат для снижения вязкости зерновых замесов. Основной
                  особенностью препарата является термостабильность. Препарат
                  работает в диапазоне температур 30–95°C. Диапазон pH 4,5-6,0.
                </th>
                <th>0,1-0,3 кг/т СВ зерна</th>
                <th>25 кг</th>
              </tr>
              <tr className="tableCenter">
                <th></th>
                <th>
                  <center>
                    ПРЕПАРАТЫ ДЛЯ ИНТЕНСИФИКАЦИИ ПРОЦЕССОВ БРОЖЕНИЯ И УВЕЛИЧЕНИЯ
                    ВЫХОДА СПИРТА
                  </center>
                </th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th>
                  <p>Saczyme CMash/Сахзайм ЦМаш</p>
                  <i>Комплексный ферментный препарат</i>
                </th>
                <th>
                  Фермент для интенсификации процесса брожения. Содержит
                  целлюлитический комплекс активностей, позволяющий
                  гидролизовать нерастворённый крахмал.
                </th>
                <th>0,05 - 0,15 кг/тонну условного крахмала</th>
                <th>25 кг</th>
              </tr>
              <tr>
                <th>
                  <p>ProEx FG/ПроЭкс FG</p>
                  <i>Кислая протеаза</i>
                </th>
                <th>
                  Препарат кислой протеазы для интенсификации брожения.
                  Позволяет увеличить выход спирта и снизить задачу аминного
                  питания для дрожжей.{" "}
                </th>
                <th>0,02–0,08 </th>
                <th>25 кг</th>
              </tr>
              <tr className="tableCenter">
                <th></th>
                <th>
                  <center>СПЕЦИАЛЬНЫЕ ФЕРМЕНТНЫЕ ПРЕПАРАТЫ</center>
                </th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th>
                  <p>FitoflowFG/Фитофло FG</p>
                  <i>Препарат фитазы</i>
                </th>
                <th>
                  Увеличение сроков бесперебойной работы предприятия за счет
                  уменьшения отложений на теплообменниках, брагоректификационных
                  колоннах и выпарном оборудовании.
                </th>
                <th>4,5 – 18 грамм/т СВ зерна</th>
                <th>25 кг</th>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlcoholProduction;
