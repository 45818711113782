import { Image } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const FrontiaGLutenEx = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="mainImg">
            <img src="/img/pshenica-ban.jpg" alt="" />
            <h3>
              Frontia GlutenEx – повышение эффективности <br />
              мокрого процесса переработки пшеницы
            </h3>
          </div>
          <div className="elementor-element">
            <h3 className="text__h3">
              Технология Frontia® – повышение эффективности <br />
              мокрого процесса переработки кукурузы
            </h3>
            <div
              style={{ border: "1.5px solid #48AA43", background: "#48AA43" }}
            ></div>
            <p>
              В 2021 году компания «Новозаймс» совместно с партнёрами
              UZBIOPRODUCT осуществили промышленное освоение на крупнейших
              предприятиях отрасли
              <span>
                новой, эффективной и преобразующей переработку крахмала
                технологии Frontia GlutenEx
              </span>
            </p>
            <div className="elementor-section">
              <img src="/img/frontia-pic.jpg" alt="" />
              <div>
                <h5>
                  Ключевыми факторами применения технологии являются возможность
                  ведения мокрого процесса с{" "}
                  <span>
                    увеличением выхода крахмала, снижением объема замочной воды
                    и нагрузки на очистные сооружения, снижением
                    энергопотребления на выпарку
                  </span>{" "}
                  и другими улучшениями технико – экономических показателей
                  производства.
                </h5>

                <i>
                  ** Для трансфера технологии Frontia® необходим внутренний
                  аудит для уточнения расчёта экономического эффекта для
                  конкретного предприятия
                </i>
              </div>
            </div>
          </div>
          <div className="elementor-heading">
            <h3 className="text__h3">
              Технология Frontia® – повышение эффективности <br />
              мокрого процесса переработки кукурузы
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="elementor-widget-wrap">
              <img src="/img/pshenica-pic.jpg" alt="" />
              <ul>
                <li>Увеличение содержания белка в глютене до 3%</li>
                <li>
                  Увеличение производительности на существующем оборудовании
                </li>
                <li>Улучшение выделения крахмала и повышение его качества</li>
                <li>Улучшение разделения пентозанов</li>
                <li>
                  Снижение энергозатрат в процессе выпарки и сушки продуктов за
                  счет увеличения содержания сухих веществ
                </li>
                <li>Снижение расхода воды</li>
              </ul>
            </div>
          </div>
          <div className="elementor-widget-heading">
            <h3 className="text__h3">
              Принципиальная схема ферментативной сепарации пшеницы <br /> с
              использованием технологии Frontia® GlutenEx
            </h3>
            <div
              style={{ border: "1.5px solid #48AA43", background: "#48AA43" }}
            ></div>
            <div className="elementor-widget-wrap">
              <Image src="/img/pshenica-pic-two.jpg" alt="" />
            </div>
          </div>
          <div className="elementor-container">
            <h3 className="text__h3">
              Этапы запуска технологии Frontia® Fiberwash
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="elementor-container-Card">
              <p className="text-h3">
                Реализация проекта не требует <br /> дополнительных инвестиций в{" "}
                <br />
                оборудование. Затраты на <br /> ферментные препараты окупаются с{" "}
                <br />
                момента запуска технологии
              </p>
              <ul>
                <li>Технический аудит</li>
                <li>
                  Предварительный расчет эффективности технологии – ТЭО проекта
                </li>
                <li>Программы предоставления промышленных образцов</li>
                <li>
                  Составление программы запуска технологии и контроля параметров
                  процесса
                </li>
                <li>
                  Техническое сопровождение на всех этапах проведения
                  промышленных тестов
                </li>
                <li>
                  Оценка эффективности испытаний и принятие решения о запуске
                  технологии
                </li>
              </ul>
            </div>
          </div>
          <div
            style={{ border: "1.5px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="bioTasksPharm">
            <h3 className="text__h3">
              ПОЛУЧИТЕ БИОТЕХНОЛОГИЧЕСКИЕ РЕШЕНИЯ ВАШИХ ЗАДАЧ!
            </h3>
            <div
              style={{ border: "1px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="li">
              <p>НАШИ КОМПЕТЕНЦИИ</p>
              <li>
                Разработка и освоение промышленных биотехнологий глубокой
                переработки сырья животного и растительного происхождения и
                производства продуктов для жизни с уникальными биологическими
                свойствами
              </li>
              <li>
                Производство, дистрибуция и применение ферментативного
                гидролизата белка в различных индустриях
              </li>
              <li>
                Проектирование и запуск производств глубокой переработки сырья,
                в том числе лицензионных, обеспечение трансфера технологии,
                особые условия приобретения оборудования и поставки необходимых
                ферментных препаратов
              </li>
            </div>
            <div
              style={{ border: "1px solid#067BC2", background: "#067BC2" }}
            ></div>
            <h4 className="text__h3">
              СВЯЖИТЕСЬ С НАМИ ЛЮБЫМ УДОБНЫМ ДЛЯ ВАС СПОСОБОМ
            </h4>
            <div className="bioTasksButton">
              <Link to="/contact">
                <button>Контакты</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrontiaGLutenEx;
