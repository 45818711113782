import { Image } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const CardProteinHydroly = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="mainImg">
            <img src="/img/cardProtein.jpg" alt="" />
            <h3>
              Гидролизат куриного белка « HCP Premium 150» для промышленных и
              диагностических питательных сред
            </h3>
          </div>
          <div className="chickenProteinInformation">
            <div className="chickenProteinInformationLeft">
              <img src="/img/company2.jpg" alt="" />
              <p>
                Биотехнологии и технологические линии , поставляемые партнёрами
                UZBIOPRODUCT , универсальны и используются для индустриального
                производства широкой гаммы гидролизатов белка с заданными
                свойствами из различных видов сырья
              </p>
            </div>
            <div className="chickenProteinInformationRight">
              <h3 className="text_h3">
                ГИДРОЛИЗАТ КУРИНОГО БЕЛКА «HCP PREMIUM 150». ОБЩАЯ ИНФОРМАЦИЯ
              </h3>
              <div
                style={{
                  border: "1.5px solid #EE3A43",
                  background: "#EE3A43",
                  width: "100%",
                }}
              ></div>
              <div>
                <div className="chickenProteinInformationCard">
                  <img src="/img/logoGolichka.png" alt="" />
                  <p>
                    Гидролизат куриного белка «HCP Premium 150» производится из
                    свежего куриного сырья пищевого стандарта с высоким
                    содержанием мяса, поступающего с птицеперерабатывающего
                    комплекса.
                  </p>
                </div>
                <div className="chickenProteinInformationCard">
                  <img src="/img/logoGolichka.png" alt="" />
                  <p>
                    Технология производства продукта «HCP Premium 150» включает
                    в себя процессы направленного ферментативного гидролиза
                    куриного белка и его последующей ультрафильтрации на
                    мембранной установке.
                  </p>
                </div>
                <div className="chickenProteinInformationCard">
                  <img src="/img/logoGolichka.png" alt="" />
                  <p>
                    Гидролизат куриного белка «HCP Premium 150» представляет
                    собой аминно-пептидный комплекс с высоким содержанием
                    коротких и среднихпептидов, а также свободных аминокислот.
                  </p>
                </div>
              </div>
              <img src="/img/chickenUn.jpg" alt="" />
            </div>
          </div>
          <div
            style={{ border: "1.5px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="chickenProtein">
            <h3 className="text__h3">
              ГИДРОЛИЗАТ КУРИНОГО БЕЛКА «HCP PREMIUM 150. СОСТАВ
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="chickenProteinTable">
              <table>
                <tr>
                  <th>ПАРАМЕТР </th>
                  <th>HCP PREMIUM 150</th>
                </tr>
                <tr>
                  <td>Содержание белка</td>
                  <td>4% (±2%)</td>
                </tr>
                <tr>
                  <td>Содержание влаги</td>
                  <td>88% (±2%)</td>
                </tr>
                <tr>
                  <td>Содержание жира</td>
                  <td>0% (±0,5%)</td>
                </tr>
                <tr>
                  <td>Содержание золы</td>
                  <td>7% (±1%)</td>
                </tr>
                <tr>
                  <td>Содержание углеводов</td>
                  <td>0% (± 0,5%)</td>
                </tr>
                <tr>
                  <td>Содержание аминного азота</td>
                  <td>4,5 – 5%</td>
                </tr>
                <tr>
                  <td>Содержание соли</td>
                  <td>2,5% (±0,5%)</td>
                </tr>
              </table>
            </div>
            <h5>
              * В продукте отсутствуют ингибиторы — антибиотики и другие
              антимикробные химиотерапевтические вещества.
            </h5>
          </div>
          <div className="biologicalProperties">
            <div className="biologicalPropertiesLine">
              <h3 className="text__h3">КЛЮЧЕВЫЕ БИОЛОГИЧЕСКИЕ СВОЙСТВА</h3>
              <div
                style={{
                  border: "1.5px solid #48AA43",
                  background: "#48AA43",
                  width: "30%",
                  justifySelf: "center",
                }}
              ></div>
            </div>
            <div className="biologicalPropertiesImg">
              <Image src="/img/biologicalProperties1.jpg" />
              <Image src="/img/biologicalProperties2.jpg" />
            </div>
          </div>
          <div className="mainApplications">
            <h3 className="text_h3">ОСНОВНЫЕ ОБЛАСТИ ПРИМЕНЕНИЯ</h3>
            <div
              style={{ border: "1.5px solid #F49F0A", background: "#F49F0A" }}
            ></div>
            <div className="mainApplicationsCard">
              <div>
                <img src="/img/mainApplications.jpg" alt="" />
                <br />
                <br />
                <img src="/img/mainApplications1.jpg" alt="" />
              </div>
              <div>
                <p>
                  Благодаря своим биологическим свойствам гидролизат куриного
                  белка «HCP Premium 150» может использоваться в качестве
                  источника аминного азота (ростового фактора) в составе
                  различных питательных сред, в том числе и для культивирования
                  микроорганизмов, требовательных к составу ПС.
                </p>
                <p>
                  В настоящее время гидролизат куриного белка эффективно
                  используется многими российскими микробиологическими и
                  биотехнологическими компаниями в составе своих питательных
                  сред при индустриальном производстве следующей продукции:
                </p>
                <ul>
                  <li>
                    Пробиотики для пищевой, фармацевтической и кормовой
                    индустрий.
                  </li>
                  <li>Микробиологические удобрения.</li>
                  <li>Стартовые культуры для молочной и мясной индустрий.</li>
                  <li>Ферменты</li>
                  <li>Вакцины</li>
                  <li>Вакцины</li>
                  <li>
                    Биопродукты для очистки индустриальных и бытовых сточных
                    вод.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="bioTasks">
            <h3 className="texth3">
              ПОЛУЧИТЕ БИОТЕХНОЛОГИЧЕСКИЕ РЕШЕНИЯ ВАШИХ ЗАДАЧ!
            </h3>
            <div
              style={{ border: "1px solid #48AA43", background: "#48AA43" }}
            ></div>
            <p>Наши услуги и компетенции:</p>

            <div className="li">
              <li>Общая информация о предлагаемом компоненте</li>
              <li>Общие рекомендации по условиям применения</li>
              <li>
                Предоставление бесплатных образцов для анализа и тестирования
              </li>
              <li>
                Коммерческие поставки гидролизата куриного белка «HCP Premium
                150»
              </li>
              <li>Технологический сервис</li>
            </div>
            <div
              style={{ border: "1px solid#067BC2", background: "#067BC2" }}
            ></div>
            <h4 className="text__h3">
              СВЯЖИТЕСЬ С НАМИ ЛЮБЫМ УДОБНЫМ ДЛЯ ВАС СПОСОБОМ
            </h4>
            <div className="bioTasksButton">
              <Link to="/contact">
                <button>Контакты</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardProteinHydroly;
