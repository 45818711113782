import React from "react";
import { Link } from "react-router-dom";

const GrainFeed = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="mainImg">
            <img src="/img/zokban.jpg" alt="" />
            <h3>
              Производство гидролизованной, биодоступной и гипоаллергенной
              зерновой основы Кормов ( ЗОК)
            </h3>
          </div>
          <div className="grainFeedBases">
            <h3 className="text__h3">
              ГИДРОЛИЗОВАННЫЕ ЗЕРНОВЫЕ ОСНОВЫ КОРМОВ ( ЗОК )
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <h4>
              Высокая степень усвоения - Гипоаллергенность - Высокая кормовая и
              биологическая ценность
            </h4>
            <div className="grainFeedBasesCards">
              <img src="/img/zok-pic.jpg" alt="" />
              <div>
                <p>
                  Эффективная технология ферментативной обработки зерна,
                  используемого в кормах:
                </p>
                <ul>
                  <li>
                    Обеспечивает оптимальный углеводно-протеиновый баланс кормов
                  </li>
                  <li>Увеличивает поедаемость кормов</li>
                  <li>Ускоряет привесы молодняка и откормочных животных</li>
                  <li>Повышает суточные надои</li>
                  <li>Решает проблему дефицита сахаров и белков в отрасли</li>
                  <li>Значительно снижает потребления премиксов</li>
                </ul>
                <h5>
                  Главное в ЗОК — это лекгоусваиваемые низкомолекулярные сахара,
                  до 77 % состоящие из ГЛЮКОЗЫ, которая получается в 10 раз
                  более дешёвой, чем на рынке
                </h5>
                <h6>
                  Остальные составляющие (белки, жиры и пр.) остаются в составе
                  ЗОК в тех же количествах, что были в исходном сырье, но в
                  более усвояемой форме
                </h6>
              </div>
            </div>
          </div>
          <div className="economyGrain">
            <h3 className="text_h3">ЭКОНОМИКА</h3>
            <div
              style={{ border: "1.5px solid #47AA42", background: "#47AA42" }}
            ></div>
            <h4>
              Глюкоза - идеальное решение для балансировки сахаров в рационах
              животных
            </h4>
            <div className="economyGrainCards">
              <div>
                <p>
                  Общее содержание легкоусвояемы моносахаров в ЗОК 15 - 17.7
                </p>
                <ul>
                  <li>Мальтоза 3.8%</li>
                  <li>Глюкоза 13.0 %</li>
                  <li>Сахароза 0.9%</li>
                  <li>
                    ЗОК вводит в рацион сахара, до 77 %, состоящие из глюкозы
                  </li>
                  <li>
                    Без использования технологии содержание простых сахаров не
                    превышает 1,5%
                  </li>
                </ul>
                <div className="economyGrainCardsUl">
                  <ul>
                    <li>Цена качественной глюкозы в РФ — 80-100 руб./кг</li>
                    <li>
                      Цена балансировки сахаров в рационах на основе
                      свекловичной патоки — 25-28 руб./кг патоки
                    </li>
                    <li>
                      В технологии ЗОК себестоимость производства глюкозы
                      составляет до 9 руб./кг
                    </li>
                  </ul>
                </div>
                <h5>
                  Экономия от применения ЗОК по сравнению с другими способами
                  балансировки рациона животных по сахарам, в среднем,
                  составляет
                  <span>до 25 руб./день на 1 голову</span> в ценах 2021
                </h5>
              </div>
              <div>
                <img src="/img/zok-pic-two.jpg" alt="" />
                <h6>
                  Окупаемость проекта ЗОК достигается в течение 6 — 9 месяцев
                </h6>
              </div>
            </div>
          </div>
          <div className="projectImplementation">
            <h3 className="text_h3">РЕАЛИЗАЦИЯ ПРОЕКТА</h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="projectImplementationCards">
              <img src="/img/zok-pic-tree.jpg" alt="" />
              <div>
                <div className="projectImplementationCard">
                  <p>
                    Предлагаемая линейка недорогого запатентованного
                    оборудования закрывает потребности любых по размеру и
                    продуктивности предприятий животноводства.
                  </p>
                </div>
                <div className="projectImplementationCard">
                  <p>
                    Мы изготавливаем, поставляем и осуществляем запуск
                    технологического комплекса «под ключ»
                  </p>
                </div>
                <div className="projectImplementationCard">
                  <p>
                  Партнёр UZBIOPRODUCT разработал и внедрил комплекс кормовых добавок
                    (энзимов от мирового лидера Новозаймс), который наиболее
                    эффективен для данной технологии
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="engineeringEquipment">
            <h3 className="text_h3">ИНЖИНИРИНГ И ОБОРУДОВАНИЕ</h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="engineeringEquipmentCard">
              <img
                className="engineeringEquipmentCardImg"
                src="/img/EQUIPMENT1.jpg"
                alt=""
              />
              <div>
                <p>
                  Наши специалисты, совместно с ведущими российскими и
                  европейскими инжиниринговыми и технологическими компаниями,
                  проектируют биотехнологические производства, в частности,{" "}
                  <span>для всех описанных в данной презентации проектов,</span>
                  и оснащают их оборудованием ведущих европейских
                  производителей:{" "}
                  <span>
                    CFT Group, ICF & Welko (Италия), GEA Group, Flottweg
                    (Германия), Bertsch-Laska (Австрия) и других
                  </span>
                </p>
                <img src="/img/EQUIPMENT2.jpg" alt="" />
              </div>
            </div>
          </div>
          <div
            style={{ border: "1.5px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="bioTasksPharm">
            <h3 className="texth3">
              ПОЛУЧИТЕ БИОТЕХНОЛОГИЧЕСКИЕ РЕШЕНИЯ ВАШИХ ЗАДАЧ!
            </h3>
            <div
              style={{ border: "1px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="li">
              <p>НАШИ КОМПЕТЕНЦИИ</p>
              <li>
                Разработка и освоение промышленных биотехнологий глубокой
                переработки сырья животного и растительного происхождения и
                производства продуктов для жизни с уникальными биологическими
                свойствами
              </li>
              <li>
                Производство, дистрибуция и применение ферментативного
                гидролизата белка в различных индустриях
              </li>
              <li>
                Проектирование и запуск производств глубокой переработки сырья,
                в том числе лицензионных, обеспечение трансфера технологии,
                особые условия приобретения оборудования и поставки необходимых
                ферментных препаратов
              </li>
            </div>
            <div
              style={{ border: "1px solid#067BC2", background: "#067BC2" }}
            ></div>
            <h4 className="text__h3">
              СВЯЖИТЕСЬ С НАМИ ЛЮБЫМ УДОБНЫМ ДЛЯ ВАС СПОСОБОМ
            </h4>
            <div className="bioTasksButton">
              <Link to="/contact">
                <button>Контакты</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrainFeed;
