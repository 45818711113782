import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Layout from "./Layout/Layout";
import Contact from "./Component/Contact";
import CompanyAbout from "./Component/CompanyAbout";
import CardGrain from "./Component/technology/CardGrain";
import CardProtein from "./Component/technology/CardProtein";
import CardAlcohol from "./Component/technology/CardAlcohol";
import Pharmaceutical from "./Component/technology/CardPharmaceutical";
import ProteinHydroly from "./Component/technology/CardProteinHydroly";
import BiosolutionsFood from "./Component/technology/CardBiosolutionsFood";
import CardHomeAnimalFood from "./Component/technology/CardHomeAnimalFood";
import CardBeverageProduction from "./Component/technology/CardBeverageProduction";
import CardFoodProduction from "./Component/technology/CardFoodProduction";
import CardJuiceProduction from "./Component/technology/CardJuiceProduction";
import CardFermentation from "./Component/technology/CardFermentation";
import CardBioXim from "./Component/technology/CardBioXim";
import News from "./Component/NewsCard/News";
import NewsCards from "./Component/NewsCard/NewsCards";
import GrainCardEnzymes from "./Component/Products/GrainCardEnzymes";
import AlcoholProduction from "./Component/Products/AlcoholProduction";
import GrainMilkProduction from "./Component/Products/GrainMilkProduction";
import JuiceEnzymes from "./Component/Products/JuiceEnzymes";
import ChickenProtein from "./Component/Products/ChickenProtein";
import Engineering from "./Component/Products/Engineering";
import ProteaseEnzyme from "./Component/Products/ProteaseEnzyme";
import FrontiaFiberwash from "./Component/Biosolutions/FrontiaFiberwash";
import FrontiaGLutenEx from "./Component/Biosolutions/FrontiaGLutenEx";
import BenefitsAcid from "./Component/Biosolutions/BenefitsAcid";
import VegetableProtein from "./Component/Biosolutions/VegetableProtein";
import FishProtein from "./Component/Biosolutions/FishProtein";
import Biosolutions from "./Component/Biosolutions/Biosolutions";
import BoneBroth from "./Component/Biosolutions/BoneBroth";
import NaturalExtracts from "./Component/Biosolutions/NaturalExtracts";
import AlternativeProteins from "./Component/Biosolutions/AlternativeProteins";
import AcidsAminoOrganic from "./Component/Biosolutions/AcidsAminoOrganic";
import GrainFeed from "./Component/Biosolutions/GrainFeed";
import GoToTop from "./Component/GoToTop";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="contact" element={<Contact />} />
          <Route path="company" element={<CompanyAbout />} />
          <Route path="cardgrain" element={<CardGrain />} />
          <Route path="cardprotein" element={<CardProtein />} />
          <Route path="cardalcohol" element={<CardAlcohol />} />
          <Route path="pharmaceutical" element={<Pharmaceutical />} />
          <Route path="proteinhydroly" element={<ProteinHydroly />} />
          <Route path="homeanimalfood" element={<CardHomeAnimalFood />} />
          <Route
            path="beverageproduction"
            element={<CardBeverageProduction />}
          />
          <Route path="foodproduction" element={<CardFoodProduction />} />
          <Route path="juiceproduction" element={<CardJuiceProduction />} />
          <Route path="fermentation" element={<CardFermentation />} />
          <Route path="bioxim" element={<CardBioXim />} />
          <Route path="news" element={<News />} />
          <Route path="newscards" element={<NewsCards />} />
          <Route path="graincardenzymes" element={<GrainCardEnzymes />} />
          <Route path="alcoholproduction" element={<AlcoholProduction />} />
          <Route
            path="proizvodstvo-zernovogo-moloka"
            element={<GrainMilkProduction />}
          />
          <Route path="fermenty-dlya-sokov" element={<JuiceEnzymes />} />
          <Route
            path="gidrolizaty-kurinogo-belka"
            element={<ChickenProtein />}
          />
          <Route path="inzhiniring-i-oborudovanie" element={<Engineering />} />
          <Route
            path="fermentnye-preparaty-proteazy"
            element={<ProteaseEnzyme />}
          />
          <Route
            path="frontia-fiberwash-dlya-kukuruzy"
            element={<FrontiaFiberwash />}
          />
          <Route
            path="frontia-glutenex-dlya-psheniczy"
            element={<FrontiaGLutenEx />}
          />
          <Route
            path="preimushhestva-fermentativnogo-gidroliza-vs-kislotnogo"
            element={<BenefitsAcid />}
          />
          <Route
            path="gidrolizaty-rastitelnyh-belkov"
            element={<VegetableProtein />}
          />
          <Route path="gidrolizat-rybnogo-belka" element={<FishProtein />} />
          <Route path="proizvodstvo-kostnyh-bulonov" element={<BoneBroth />} />
          <Route
            path="naturalnye-ekstrakty-i-bulony"
            element={<NaturalExtracts />}
          />
          <Route
            path="alternativnye-belki-i-kultiviruemye-produkty"
            element={<AlternativeProteins />}
          />
          <Route
            path="aminokisloty-organicheskie-kisloty-i-vitaminy"
            element={<AcidsAminoOrganic />}
          />
          <Route path="zernovye-osnovy-kormov" element={<GrainFeed />} />
        </Routes>
        <GoToTop />
      </Layout>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
