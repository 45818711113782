import React from "react";
import { Link } from "react-router-dom";

const NewsCards = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="newsCards">
            <div className="news-Card">
              <Link className="news-CardLink" to="/fermentation">
                <img src="/img/news1.jpg" alt="" />
                <h2>Альтернативные белки и культивируемые продукты</h2>
              </Link>
            </div>
            <div className="news-Card">
              <Link className="news-CardLink" to="/fermentation">
                <img src="/img/news2.jpg" alt="" />
                <h2>О бульоне, который даёт силы</h2>
              </Link>
              <p>
                Гелия Делеринс « О бульоне, который даёт силы», Журнал «Огонёк»
                , 05/18/2015. Говорят, что бульон — это пенициллин и что именно
                его дают для выздоровления. В «Оливере Твисте» бедного бродяжку
                отпаивали бульоном после тифа, капля за каплей вливая в него
                жизнь. Во Франции существует «бульон …
              </p>
            </div>
            <div className="news-Card">
              <Link className="news-CardLink" to="/fermentation">
                <img src="/img/news3.jpg" alt="" />
                <h2>Продукты для долгой и счастливой жизни</h2>
              </Link>
              <p>
                За всю свою долгую историю человечество изобрело массу полезных
                вещей, но так и не научилось правильно питаться. Прогресс
                подложил людям большую свинью, освободив от тяжелых физических
                нагрузок и дав возможность калорийно и разнообразно питаться – в
                итоге блага цивилизации обернулись массовым ожирением населения
                и новыми …
              </p>
            </div>
            <div className="news-Card">
              <Link className="news-CardLink" to="/fermentation">
                <img src="/img/news4.jpg" alt="" />
                <h2>Ферментативные Гидролизаты Белка</h2>
              </Link>
              <p>
                Веками человечество терялось в догадках относительно того,
                почему стареет организм? Отчего одни люди доживают до столетнего
                юбилея, а другие — дряхлеют и угасают уже в шестьдесят лет? Ясно
                одно: старение начинается как бы исподволь — сначала начинается
                снижение различных биохимических реакций, падает активность
                мозга, ухудшается …
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsCards;
