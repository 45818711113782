import React from "react";
import { Link } from "react-router-dom";
import TechnologyCard from "../Component/TechnologuCard/TechnologyCard";
import "../style.scss";
import { FiChevronDown } from "react-icons/fi";
import NewcCard from "../Component/NewsCard/NewcCard";
import ProductsCard from "../Component/Products/ProductsCard";
import Biosolutions from "../Component/Biosolutions/Biosolutions";
import OpenModal from "../Component/OnDrawer/OpenModal";

const Header = () => {
  return (
    <div className="container-header">
      <div className="body-header">
        <header className="header">
          <div className="header-logo">
            <a href="/">
              <div className="novozymesLogo">
                <img src="/img/100.svg" />
              </div>
              <img src="/img/logoheader.png" alt="" />
              <h3>UZBIOPRODUCT</h3>
            </a>
            <nav className="nav-link">
              <div className="technologyCardHeader">
                <Link to="" className="technology">
                  <p>
                    Технологии <FiChevronDown />
                  </p>
                </Link>
                <TechnologyCard />
              </div>
              <div className="technologyCardHeader">
                <Link to="" className="technology">
                  <p>
                    Продукты <FiChevronDown />
                  </p>
                </Link>
                <ProductsCard />
              </div>
              <div className="technologyCardHeader">
                <Link to="" className="technology">
                  <Link to="">
                    <p>
                      Новые биорешения <FiChevronDown />
                    </p>
                  </Link>
                </Link>
                <Biosolutions />
              </div>

              <div className="technologyCardHeader">
                <Link to="/news">
                  <p>
                    Новости <FiChevronDown />
                  </p>
                </Link>
                <NewcCard />
              </div>
              <Link to="/company">
                <p>О компании</p>{" "}
              </Link>
              <Link to="/contact">
                <p>Контакты</p>{" "}
              </Link>
            </nav>
            <OpenModal   />
          </div>
        </header>
      </div>
    </div>
  );
};

export default Header;
