import { Image } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const BoneBroth = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="mainImg">
            <img src="/img/bul-don.jpg" alt="" />
            <h3>
              Использование протеаз в технологиях варки костных бульонов под
              давлением
            </h3>
          </div>
          <div className="boneBroth">
            <h3 className="text__h3">КОСТНЫЙ БУЛЬОН</h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="boneBrothCards">
              <img src="/img/kosti.jpg" alt="" />
              <p>
                Кости являются сопутствующим продуктом мясной промышленности,
                который широко используют в производстве костных бульонов. С
                этой целью используют свиные, говяжьи кости или кости птицы,
                которые варят под давлением для растворения коллагена и
                получения желатина. Бульон можно использовать как пищевой
                ингредиент, например — в супах, приправах, лапше быстрого
                приготовления и закусках, а также в качестве мясного ингредиента
                в других мясных продуктах. <br />
                <br /> Традиционные виды сырья для получения костного бульона:
                говяжьи, свиные, бараньи и птичьи кости.
              </p>
            </div>
          </div>
          <div className="proteasesProducers">
            <h3 className="text__h3">
              ПРОТЕАЗЫ – НОВЫЕ ВОЗМОЖНОСТИ В ТЕХНОЛОГИИ ПРОИЗВОДСТВА КОСТНЫХ
              БУЛЬОНОВ ПОД ДАВЛЕНИЕМ
            </h3>
            <div
              style={{ border: "1.5px solid #48AA43", background: "#48AA43" }}
            ></div>
            <div className="proteasesProducersCard">
              <div>
                <p>
                  Применение протеаз в процессах получения костных бульонов
                  позволяет решить следующие технологические задачи:
                </p>
                <ul>
                  <li>
                    Удаление мяса/крови с костей для получения бульонов светлого
                    цвета.
                  </li>
                  <li>Уменьшение времени варки бульонов.</li>
                  <li>Получение бульонов различной вязкости.</li>
                  <li>Улучшение (оптимизация) вкуса бульонов.</li>
                </ul>
              </div>
              <img src="/img/bulyon.jpg" alt="" />
            </div>
          </div>
          <div className="productionFlowChart">
            <h3 className="text_h3">
              УПРОЩЕННАЯ БЛОК-СХЕМА ПРОИЗВОДСТВА ГИДРОЛИЗАТА <br />
              РАСТИТЕЛЬНОГО БЕЛКА В ЖИДКОЙ ФОРМЕ
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <Image src="/img/shema-gidro.jpg" />
          </div>
          <div
            style={{ border: "1.5px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="bioTasksPharm">
            <h3 className="texth3">
              ПОЛУЧИТЕ БИОТЕХНОЛОГИЧЕСКИЕ РЕШЕНИЯ ВАШИХ ЗАДАЧ!
            </h3>
            <div
              style={{ border: "1px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="li">
              <p>НАШИ КОМПЕТЕНЦИИ</p>
              <li>
                Разработка и освоение промышленных биотехнологий глубокой
                переработки сырья животного и растительного происхождения и
                производства продуктов для жизни с уникальными биологическими
                свойствами
              </li>
              <li>
                Производство, дистрибуция и применение ферментативного
                гидролизата белка в различных индустриях
              </li>
              <li>
                Проектирование и запуск производств глубокой переработки сырья,
                в том числе лицензионных, обеспечение трансфера технологии,
                особые условия приобретения оборудования и поставки необходимых
                ферментных препаратов
              </li>
            </div>
            <div
              style={{ border: "1px solid#067BC2", background: "#067BC2" }}
            ></div>
            <h4 className="text__h3">
              СВЯЖИТЕСЬ С НАМИ ЛЮБЫМ УДОБНЫМ ДЛЯ ВАС СПОСОБОМ
            </h4>
            <div className="bioTasksButton">
              <Link to="/contact">
                <button>Контакты</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoneBroth;
