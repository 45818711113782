import React from "react";
import { Button, Drawer, Radio, Space } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import { FiChevronDown } from "react-icons/fi";
import { CgMenuLeft } from "react-icons/cg";
import { CloseOutlined } from "@ant-design/icons";
import TechnologyCard from "../TechnologuCard/TechnologyCard";

import { Collapse } from "antd";
import FooterOpen from "./FooterOpen";
const { Panel } = Collapse;
const OpenModal = () => {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onChange = (e) => {
    setPlacement(e.target.value);
  };

  return (
    <div className="openModal">
      <Space>
        <img src="/img/100.svg" />
        <Button className="openModal-button" onClick={showDrawer}>
          <CgMenuLeft className="openModal-Icon" />
        </Button>
      </Space>
      <Drawer
        title="UZBIOPRODUCT"
        footer={<FooterOpen />}
        placement={placement}
        closable={false}
        onClose={onClose}
        open={open}
        closeIcon={<CloseOutlined />}
        key={placement}
        extra={
          <Space>
            <Button onClick={onClose}>
              <CloseOutlined />
            </Button>
          </Space>
        }
      >
        <>
          <Collapse accordion>
            <Panel header="Технологии" key="1">
              <Link className="modalLink" to="/cardgrain" onClick={onClose}>
                <button>Глубокая переработка зерна</button>
              </Link>
              <Link className="modalLink" to="/cardprotein" onClick={onClose}>
                <button>Ферментативные гидролизаты белков</button>
              </Link>
              <Link className="modalLink" to="/cardalcohol" onClick={onClose}>
                <button>Производство спирта</button>
              </Link>
              <Link
                className="modalLink"
                to="/pharmaceutical"
                onClick={onClose}
              >
                <button>Производство фармсубстанции</button>
              </Link>
              <Link
                className="modalLink"
                to="/proteinhydroly"
                onClick={onClose}
              >
                <button>Гидролизаты белка для питательных сред</button>
              </Link>
              <Link
                className="modalLink"
                to="/zernovye-osnovy-kormov/"
                onClick={onClose}
              >
                <button>Биорешения для премиксов и кормовых добавок</button>
              </Link>
              <Link
                className="modalLink"
                to="/homeanimalfood"
                onClick={onClose}
              >
                <button>
                  Гидролизаты белка в кормах для домашних питомцев
                </button>
              </Link>
              <Link
                className="modalLink"
                to="/beverageproduction"
                onClick={onClose}
              >
                <button>Производство напитков из зерновых культур</button>
              </Link>
              <Link
                className="modalLink"
                to="/foodproduction"
                onClick={onClose}
              >
                <button>Производство продуктов питания</button>
              </Link>
              <Link
                className="modalLink"
                to="/juiceproduction"
                onClick={onClose}
              >
                <button>Производство сока</button>
              </Link>
              <Link className="modalLink" to="/fermentation" onClick={onClose}>
                <button>Ферментация</button>
              </Link>
              <Link className="modalLink" to="/bioxim" onClick={onClose}>
                <button>Биологизация химии</button>
              </Link>
            </Panel>
            <Panel header="Продукты" key="2">
              <Link
                className="modalLink"
                to="/gidrolizaty-kurinogo-belka"
                onClick={onClose}
              >
                <button>Гидролизаты куриного белка</button>
              </Link>
              <Link
                className="modalLink"
                to="/inzhiniring-i-oborudovanie/"
                onClick={onClose}
              >
                <button>Инжиниринг и оборудование</button>
              </Link>
              <Link
                className="modalLink"
                to="/fermentnye-preparaty-proteazy/"
                onClick={onClose}
              >
                <button>Ферментные препараты Протеазы</button>
              </Link>
              <Link
                className="modalLink"
                to="/graincardenzymes"
                onClick={onClose}
              >
                <button> Глубокая переработка зерна</button>
              </Link>
              <Link
                className="modalLink"
                to="/alcoholproduction"
                onClick={onClose}
              >
                <button> Производство спирта</button>
              </Link>
              <Link
                className="modalLink"
                to="/proizvodstvo-zernovogo-moloka"
                onClick={onClose}
              >
                <button> Производство зернового молока</button>
              </Link>
              <Link
                className="modalLink"
                to="/fermenty-dlya-sokov/"
                onClick={onClose}
              >
                <button>Ферменты для соков</button>
              </Link>
            </Panel>
            <Panel header="Новые биорешения" key="3">
              <Link
                className="modalLink"
                to="/frontia-fiberwash-dlya-kukuruzy/"
                onClick={onClose}
              >
                <button> Frontia Fiberwash для кукурузы </button>
              </Link>
              <Link
                className="modalLink"
                to="/frontia-glutenex-dlya-psheniczy/"
                onClick={onClose}
              >
                <button>Frontia GlutenEx для пшеницы</button>
              </Link>
              <Link
                className="modalLink"
                to="/preimushhestva-fermentativnogo-gidroliza-vs-kislotnogo/"
                onClick={onClose}
              >
                <button>
                  Преимущества ферментативного гидролиза vs кислотного
                </button>
              </Link>
              <Link
                className="modalLink"
                to="/gidrolizaty-rastitelnyh-belkov/"
                onClick={onClose}
              >
                <button>Гидролизаты растительных белков</button>
              </Link>
              <Link
                className="modalLink"
                to="/gidrolizat-rybnogo-belka/"
                onClick={onClose}
              >
                <button>Гидролизат рыбного белка</button>
              </Link>
              <Link
                className="modalLink"
                to="/proizvodstvo-kostnyh-bulonov/"
                onClick={onClose}
              >
                <button>Производство костных бульонов</button>
              </Link>
              <Link
                className="modalLink"
                to="/naturalnye-ekstrakty-i-bulony/"
                onClick={onClose}
              >
                <button> Натуральные экстракты и бульоны</button>
              </Link>
              <Link
                className="modalLink"
                to="/pharmaceutical"
                onClick={onClose}
              >
                <button>Производство фармсубстанций</button>
              </Link>
              <Link
                className="modalLink"
                to="/foodproduction"
                onClick={onClose}
              >
                <button>Производство продуктов питания</button>
              </Link>
              <Link
                className="modalLink"
                to="/zernovye-osnovy-kormov/"
                onClick={onClose}
              >
                <button>Зерновые основы кормов (ЗОК)</button>
              </Link>
              <Link
                className="modalLink"
                to="/alternativnye-belki-i-kultiviruemye-produkty/"
                onClick={onClose}
              >
                <button> Альтернативные белки и культивируемые продукты</button>
              </Link>
              <Link
                className="modalLink"
                to="/aminokisloty-organicheskie-kisloty-i-vitaminy/"
                onClick={onClose}
              >
                <button>Аминокислоты, органические кислоты и витамины</button>
              </Link>
            </Panel>
            <Panel header="Новости" key="4">
              <Link className="modalLink" to="/news" onClick={onClose}>
                <button>Новости</button>
              </Link>
              <Link className="modalLink" to="/newscards" onClick={onClose}>
                <button>Это интересно</button>
              </Link>
            </Panel>
            <Link className="modalLink" to="/company" onClick={onClose}>
              <button>О компании</button>{" "}
            </Link>
            <Link className="modalLink" to="/contact" onClick={onClose}>
              <button>Контакты</button>{" "}
            </Link>
          </Collapse>
        </>
      </Drawer>
    </div>
  );
};

export default OpenModal;
