// import "./App.css";
import Card from "./Component/Card";
import "./style.scss";
import "./style-mobile.scss";

function App() {
  return (
    <div className="container">
      <div className="body1"><Card /></div>
    </div>
  );
}

export default App;
