import { Image } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const FrontiaFiberwash = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="mainImg">
            <img src="/img/fiberwash-ban.jpg" alt="" />
            <h3>
              Frontia Fiberwash – повышение эффективности мокрого процесса
              переработки кукурузы
            </h3>
          </div>
          <div className="elementor-element">
            <h3 className="text__h3">
              Технология Frontia® – повышение эффективности <br />
              мокрого процесса переработки кукурузы
            </h3>
            <div
              style={{ border: "1.5px solid #48AA43", background: "#48AA43" }}
            ></div>
            <p>
              В 2021 году компания «Новозаймс» совместно с партнёрами UZBIOPRODUCT
              осуществили промышленное освоение на крупнейших предприятиях
              отрасли новой,{" "}
              <span>
                эффективной и преобразующей переработку крахмала Технологии
                Frontia®
              </span>
            </p>
            <div className="elementor-section">
              <img src="/img/frontia-pic.jpg" alt="" />
              <div>
                <h5>
                  Данная технология позволяет вести мокрый процесс с
                  дополнительным{" "}
                  <span>
                    увеличением выхода крахмала, снижением объема замочной воды
                    и нагрузки на очистные сооружения, снижением
                    энергопотребления на выпарку
                  </span>
                  и другими улучшениями технико – экономических показателей
                  производства.
                </h5>
                <i>
                  ** Для трансфера технологии Frontia® необходим внутренний
                  аудит для уточнения расчёта экономического эффекта для
                  конкретного предприятия
                </i>
              </div>
            </div>
          </div>
          <div className="elementor-heading">
            <h3 className="text__h3">
              Технология Frontia® – повышение эффективности <br />
              мокрого процесса переработки кукурузы
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="elementor-widget-wrap">
              <img src="/img/frontia-pic-two.jpg" alt="" />
              <ul>
                <li>
                  Увеличение выхода крахмала за счёт ферментативного выделения
                  до 70% связанного крахмала и улучшение вымывания свободного
                  крахмала
                </li>
                <li>
                  Снижение и стабилизация количества свободного крахмала в
                  мезге, подаваемую в сушилку. Решение проблемы с нагаром и
                  налипанием продукта в сушилке и транспортных коммуникациях
                </li>
                <li>
                  Снижение влажности мезги на 6–10%. При номинальной
                  производительности снижается энергопотребление прессов и
                  сушилки, что позволяет увеличить производительность
                  предприятия в период пиковых нагрузок
                </li>
                <li>Сокращение времени замочки зерна на 8–10 часов</li>
                <li>
                  100% утилизация всей замочной воды. Снижение нагрузки на
                  очистные сооружения
                </li>
                <li>Снижение потерь глютена как конечного продукта</li>
                <li>
                  Снижение количества остановок сушилки – повышается время её
                  бесперебойной работы
                </li>
                <li>Стабилизация качества кормов</li>
              </ul>
            </div>
          </div>
          <div className="elementor-widget-heading">
            <h3 className="text__h3">Механизм ферментативного разделения</h3>
            <div
              style={{ border: "1.5px solid #48AA43", background: "#48AA43" }}
            ></div>
            <h3 className="text_h3">
              Эффективность технологии проявляется на фоне <br /> ограниченных
              возможностей механического разделения
            </h3>
            <div className="elementor-widget-wrap">
              <Image src="/img/frontia-pic-tree.jpg" alt="" />
              <p>
                Высвобождение более 60% связанного крахмала <br /> <br /> На 15%
                улучшенное обезвоживание волокон
              </p>
            </div>
          </div>
          <div className="elementor-container">
            <h3 className="text__h3">
              Этапы запуска технологии Frontia® Fiberwash
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="elementor-container-Card">
              <p className="text__h3">
                Средний срок окупаемости <br /> проекта 3 – 9 месяцев!
              </p>
              <ul>
                <li>Технический аудит</li>
                <li>
                  Оценка потенциала. Исследования мезги. Лабораторные тесты
                </li>
                <li>Составление технико-экономического обоснования</li>
                <li>Пробные тесты для настройки оборудования</li>
                <li>Промышленный запуск технологии</li>
              </ul>
            </div>
          </div>
          <div
            style={{ border: "1.5px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="bioTasksPharm">
            <h3 className="text__h3">
              ПОЛУЧИТЕ БИОТЕХНОЛОГИЧЕСКИЕ РЕШЕНИЯ ВАШИХ ЗАДАЧ!
            </h3>
            <div
              style={{ border: "1px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="li">
              <p>НАШИ КОМПЕТЕНЦИИ</p>
              <li>
                Разработка и освоение промышленных биотехнологий глубокой
                переработки сырья животного и растительного происхождения и
                производства продуктов для жизни с уникальными биологическими
                свойствами
              </li>
              <li>
                Производство, дистрибуция и применение ферментативного
                гидролизата белка в различных индустриях
              </li>
              <li>
                Проектирование и запуск производств глубокой переработки сырья,
                в том числе лицензионных, обеспечение трансфера технологии,
                особые условия приобретения оборудования и поставки необходимых
                ферментных препаратов
              </li>
            </div>
            <div
              style={{ border: "1px solid#067BC2", background: "#067BC2" }}
            ></div>
            <h4 className="text__h3">
              СВЯЖИТЕСЬ С НАМИ ЛЮБЫМ УДОБНЫМ ДЛЯ ВАС СПОСОБОМ
            </h4>
            <div className="bioTasksButton">
              <Link to="/contact">
                <button>Контакты</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrontiaFiberwash;
