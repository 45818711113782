import { Image } from "antd";
import React from "react";
import { CgFileDocument } from "react-icons/cg";
import { Link } from "react-router-dom";
const CardBeverageProduction = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="mainImg">
            <img src="/img/crops.jpg" alt="" />
            <h3>
              Производство напитков из зерновых культур Ферментные технологии и
              технологический комплекс
            </h3>
          </div>
          <div className="cardTick">
            <CgFileDocument style={{ fontSize: "26px", color: "red" }} />
            <p>Новые биорешения</p>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <p className="beverageText">
            В 2020 году Партнёр компании UZBIOPRODUCT выводит на рынок новую
            технологию производства гипоаллергенных протеиновых напитков из
            зерновых культур. Технология заключается в использовании комплекса
            протеаз для ферментативного гидролиза растительного белка
            одновременно с процессами разжижения и осахаривания крахмала при
            производстве зерновых напитков . Благодаря такой обработке сырья,
            конечный напиток будет содержать дополнительный гидролизованный
            белок в гипоаллергенной и полностью усвояемой ( и растворимой )
            форме . Одновременно гидролизуется и белок , содержащийся в напитке
            при использовании стандартной технологии , описанной в презентации
            производства зерновых напитков ( см. ниже ) В частности , новая
            технология позволяет производить зерновые напитки из пшеницы и
            других более доступных видов сырья , поскольку решает проблему
            высокой аллергенности и непереносимости глютена . Следует также
            отметить , что в результате данного процесса мы получаем и
            используем гидролизаты растительных белков в жидкой форме ,
            стоимость которых в конечном продукте почти в два раза ниже , чем
            если бы для увеличения белковой составляющей в зерновом напитке
            применялась сухая форма гидролизатов белка . <br />
            Для производства зерновых напитков (аналогов молока) предлагаем Вам
            попробовать новые улучшенные вкусы с кремообразной структурой.
            &nbsp; Базовые рецептуры и образцы для тестирования по запросу
          </p>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="beveragesCereals">
            <h3 className="text-h3">НАПИТКИ ИЗ ЗЕРНОВЫХ КУЛЬТУР</h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="beveragesCerealsCards">
              <div>
                <img src="/img/beveragesCereals.jpg" alt="" />
                <div className="beveragesCerealsCard">
                  <p>
                    В 2018 ГОДУ В РОССИИ ПРОДАНО 2,8 МЛН. ЛИТРОВ. ПРОГНОЗИРУЕМЫЙ
                    РОСТ - 30% В ГОД
                  </p>
                </div>
              </div>
              <div>
                <h5 className="text_h3">
                  Почему потребителям нужны растительные аналоги молочных
                  продуктов?
                </h5>
                <ul>
                  <li>Непереносимость лактозы</li>
                  <li>Аллергия на молочный белок</li>
                  <li>
                    Обеспокоенность из-за наличия антибиотиков и гормонов роста
                    в коровьем молоке
                  </li>
                  <li>
                    Диетологи рекомендуют питание, основанное на продуктах
                    растительного происхождения
                  </li>
                  <li>Веганство как стиль жизни</li>
                  <li>
                    Снижение содержания сахарозы – натуральный сладкий вкус
                  </li>
                  <li>Не содержат лактозы</li>
                  <li>Отличный источник белка</li>
                </ul>
              </div>
            </div>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="enzymesInBeverages">
            <div>
              <h3 className="text_h3">
                Преимущества использования ферментов в напитках на основе
                зерновых культур
              </h3>
              <div
                style={{ border: "1.5px solid #F49F0A", background: "#F49F0A" }}
              ></div>
              <div className="enzymesInBeveragesCard">
                <img src="/img/1.jpg" alt="" />
                <p>Обладает идеальной вязкостью</p>
              </div>
              <div className="enzymesInBeveragesCard">
                <img src="/img/2.jpg" alt="" />
                <p>Желаемый уровень сладости без добавления сахара</p>
              </div>
              <div className="enzymesInBeveragesCard">
                <img src="/img/3.jpg" alt="" />
                <p>
                  Повышенная стабильность напитка в течение длительного времени
                </p>
              </div>
              <div className="enzymesInBeveragesCard">
                <img src="/img/4.jpg" alt="" />
                <p>Повышенный выход продукта при производстве</p>
              </div>
            </div>
            <img
              className="enzymesInBeveragesImg"
              src="/img/enzymesInBeverages.jpg"
              alt=""
            />
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="technologicalOperations">
            <h3 className="text-h3">ДВЕ ОСНОВНЫЕ ТЕХНОЛОГИЧЕСКИЕ ОПЕРАЦИИ</h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="technologicalOperationsImg">
              <Image src="/img/technologicalOperations1.jpg" />
              <Image src="/img/technologicalOperations2.jpg" />
            </div>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="beveragesBasedCereals">
            <h3 className="text-h3">
              ФЕРМЕНТЫ ДЛЯ НАПИТКОВ НА ОСНОВЕ ЗЕРНОВЫХ КУЛЬТУР
            </h3>
            <div
              style={{ border: "1.5px solid #48AA43", background: "#48AA43" }}
            ></div>
            <div className="beveragesBasedCerealsCard">
              <img src="/img/beveragesBasedCereals.jpg" alt="" />
              <table>
                <tr>
                  <th>ПРЕИМУЩЕСТВА </th>
                  <th>ПРОДУКТ </th>
                  <th>КЛАСС ФЕРМЕНТОВ </th>
                  <th>ДОЗИРОВКА*</th>
                </tr>
                <tr>
                  <td>Разжижиение</td>
                  <td>
                    BAN® <br /> Termamyl®
                  </td>
                  <td>
                    α-амилаза <br /> α-амилаза
                  </td>
                  <td>
                    0,1 – 0,8 % <br /> 0,1 – 0,8 %
                  </td>
                </tr>
                <tr>
                  <td>Осахаривание</td>
                  <td>AMG®</td>
                  <td>Глюкоамилаза</td>
                  <td>0,2 – 0,4 %</td>
                </tr>
                <tr>
                  <td>Снижение уровня вязкости</td>
                  <td>
                    BAN® <br /> Ultraflow®
                  </td>
                  <td>
                    α-амилаза <br /> Ксиланаза
                  </td>
                  <td>
                    0,1 – 0,8 % <br /> 0,1 – 0,3 %
                  </td>
                </tr>
                <tr>
                  <td>Гидролиз белков</td>
                  <td>
                    Neutrase® <br /> Flavourzyme®
                  </td>
                  <td>
                    Протеаза <br /> Протеаза
                  </td>
                  <td>
                    0,1 – 0,3 % <br /> 0,1 – 0,4 %
                  </td>
                </tr>
                <p>* Исходя из сухой массы зерновых культур</p>
              </table>
            </div>
          </div>
          <div className="propertiesOfGrain">
            <h3 className="text-h3">СВОЙСТВА ЗЕРНОВЫХ НАПИТКОВ</h3>
            <div
              style={{
                border: "1.5px solid #067BC2",
                background: "#067BC2",
                width: "70%",
              }}
            ></div>
            <div className="propertiesOfGrainCards">
              <div className="propertiesOfGrainCard">
                <img src="/img/propertiesOfGrain1.jpg" alt="" />
                <h6>ОВЕС</h6>
                <ul>
                  <li>Приятный овсяный вкус</li>
                  <li>Кремовая, гладкая насыщенная текстура</li>
                  <li>Идеально сочетается с фруктами</li>
                </ul>
              </div>
              <div className="propertiesOfGrainCard">
                <img src="/img/propertiesOfGrain2.jpg" alt="" />
                <h6>РИС</h6>
                <ul>
                  <li>Лёгкий и освежающий вкус</li>
                  <li>Натуральная мягкая сладость во вкусе</li>
                  <li>Приятная лёгкая консистенция</li>
                  <li>Гипоаллергенный</li>
                </ul>
              </div>
              <div className="propertiesOfGrainCard">
                <img src="/img/propertiesOfGrain3.jpg" alt="" />
                <h6>СОЯ</h6>
                <ul>
                  <li>Тонкий вкус</li>
                  <li>Насыщенная текстура</li>
                  <li>Подходит для приготовления смузи</li>
                  <li>Без глютена</li>
                </ul>
              </div>
              <div className="propertiesOfGrainCard">
                <img src="/img/propertiesOfGrain4.jpg" alt="" />
                <h6>МИНДАЛЬ</h6>
                <ul>
                  <li>Приятный тонкий ореховый вкус</li>
                  <li>Гладкая текстура</li>
                  <li>Идеально вспенивается</li>
                  <li>Без глютена</li>
                </ul>
              </div>
              <div className="propertiesOfGrainCard">
                <img src="/img/propertiesOfGrain5.jpg" alt="" />
                <h6>КОКОС</h6>
                <ul>
                  <li>Мягкий и свежий вкус кокоса</li>
                  <li>Кремовая текстура</li>
                  <li>Отличные вспенивающие свойства</li>
                  <li>Гипоаллергенный</li>
                </ul>
              </div>
            </div>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="baristaWhipped">
            <h3 className="text-h3">БАРИСТА - ВЗБИВАЕТСЯ НА ОТЛИЧНО</h3>
            <div
              style={{ border: "1px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="baristaWhippedCards">
              <img src="/img/baristaWhipped.jpg" alt="" />
              <div>
                <h5>БАРИСТА</h5>
                <div className="baristaWhippedCardsLogo">
                  <div className="baristaWhippedCardLogo">
                    <img src="/img/logored.png" alt="" />
                    <p>БЕЗ ГМО</p>
                  </div>
                  <div className="baristaWhippedCardLogo">
                    <img src="/img/logored.png" alt="" />
                    <p>БЕЗ СОИ</p>
                  </div>
                  <div className="baristaWhippedCardLogo">
                    <img src="/img/logored.png" alt="" />
                    <p>БЕЗ САХАРА</p>
                  </div>
                </div>
                <span>
                  Профессиональная линейка Barista разработана совместно с
                  экспертами и мастерами по приготовлению кофе.
                  <br /> <br />
                  Нам удалось добиться идеальной пены!
                  <br />
                  <br />
                  Теперь бариста смогут создать любой рисунок для вашего
                  капучино. <br />
                  <br />
                  Ну и, конечно, не содержит сахар и сою, а только натуральные
                  растительные ингредиенты!
                </span>
              </div>
            </div>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="processLines">
            <h3 className="text-h3">ТЕХНОЛОГИЧЕСКИЕ ЛИНИИ / Основные секции</h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="processLinesCards">
              <div className="processLinesCard">
                <img src="/img/texnolog1.jpg" alt="" />
                <ul>
                  <li>Приемка сырьевых материалов</li>
                </ul>
              </div>
              <div className="processLinesCard">
                <img src="/img/texnolog2.jpg" alt="" />
                <ul>
                  <li>Регидратация</li>
                  <li>Измельчение</li>
                  <li>Ферментация</li>
                  <li>Нагрев</li>
                </ul>
              </div>
              <div className="processLinesCard">
                <img src="/img/texnolog3.jpg" alt="" />
                <ul>
                  <li>Хранение</li>
                  <li>Блендинг</li>
                </ul>
              </div>
              <div className="processLinesCard">
                <img src="/img/texnolog4.jpg" alt="" />
                <ul>
                  <li>Стерилизация</li>
                  <li>Асептическое хранение</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="placementProcessLine">
            <h3 className="text-h3">
              УСТАНОВКА И РАЗМЕЩЕНИЕ <br /> ТЕХНОЛОГИЧЕСКОЙ ЛИНИИ
            </h3>
            <div
              style={{ border: "1.5px solid #F49F0A", background: "#F49F0A" }}
            ></div>
            <div className="placementProcessLineImg">
              <Image src="/img/placementProcessLine1.jpg" />
              <Image src="/img/placementProcessLine2.jpg" />
            </div>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="novozymesBeveragePage">
            <img src="/img/novozymes.jpg" alt="" />
            <div>
              <h5 className="text-h3">
                Почему производители напитков из зерновых культур предпочитают
                работать с решениями и ферментами компании Новозаймс
              </h5>
              <div
                style={{ border: "1.5px solid #F49F0A", background: "#F49F0A" }}
              ></div>
              <div className="company-leader-text">
                <p>
                  {" "}
                  <img src="/img/checked.png" alt="" /> Великолепное качество
                  продукции, которое соответствует потребностям клиентов
                </p>
                <p>
                  {" "}
                  <img src="/img/checked.png" alt="" /> Мы предлагаем больше чем
                  просто лучшие ферменты: комплексный подход и решения,
                  включающие в себя техническую поддержку, испытания в
                  лаборатории и услуги
                </p>
                <p>
                  {" "}
                  <img src="/img/checked.png" alt="" /> Прекрасно развитая
                  дистрибьюторская сеть в большинстве стран
                </p>
                <p>
                  {" "}
                  <img src="/img/checked.png" alt="" /> Запуск новых продуктов
                  только после успешных лабораторных и промышленных испытаний
                </p>
                <p>
                  {" "}
                  <img src="/img/checked.png" alt="" /> Улучшенное качество
                  продуктов и экономическую эффективность решений легко
                  проверить
                </p>
                <p>
                  {" "}
                  <img src="/img/checked.png" alt="" /> Комплексные и прозрачные
                  документы и сертификаты по продуктам (PDS, MSDS, Non-GMO,
                  Food-grade, Kosher etc.)
                </p>
              </div>
            </div>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="liquidFoods">
            <img className="liquidFoodsImg" src="/img/liquidFoods.jpg" alt="" />
            <div className="liquidFoodsCards">
              <h3 className="text-h3">ЖИДКИЕ ПИЩЕВЫЕ ПРОДУКТЫ</h3>
              <div
                style={{ border: "1.5px solid #F49F0A", background: "#F49F0A" }}
              ></div>
              <div className="liquidFoodsLeaderCard">
                <img src="/img/leaderslft.jpg" alt="" />
                <p>
                  CFT Group расширила технологический портфель, включив в него
                  инновационные индивидуальные решения для молочной
                  промышленности, используя богатый опыт и ноу-хау в
                  проектировании и создании высококачественных машин и готовых
                  заводов «под ключ».
                </p>
              </div>
              <div className="liquidFoodsCard">
                <div>
                  <h6>MILK</h6>
                  <img src="/img/liquidFoods1.jpg" alt="" />
                </div>
                <div>
                  <h6>BABY FOOD</h6>
                  <img src="/img/liquidFoods2.jpg" alt="" />
                </div>
                <div>
                  <h6>FORMULATED PRODUCTS</h6>
                  <img src="/img/liquidFoods3.jpg" alt="" />
                </div>
                <div>
                  <h6>YOGURT PREPARATION</h6>
                  <img src="/img/liquidFoods4.jpg" alt="" />
                </div>
                <div>
                  <h6>WHEY AND MILK PRODUCTS</h6>
                  <img src="/img/liquidFoods5.jpg" alt="" />
                </div>
                <div>
                  <h6>PLANT-BASED DRINKS</h6>
                  <img src="/img/liquidFoods6.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="chickenProteinInformation">
            <div className="chickenProteinInformationLeft">
              <img src="/img/company2.jpg" alt="" />
              <p>
                Биотехнологии и технологические линии , поставляемые партнёрами
                UZBIOPRODUCT , универсальны и используются для индустриального
                производства широкой гаммы гидролизатов белка с заданными
                свойствами из различных видов сырья
              </p>
            </div>
            <div className="chickenProteinInformationRight">
              <h3 className="text-h3">
                 Партнёр ООО UZBIOPRODUCT В ТЕЧЕНИЕ 25 ЛЕТ ОДИН ИЗ ЛИДЕРОВ В ОБЛАСТЯХ:
              </h3>
              <div
                style={{
                  border: "1.5px solid #EE3A43",
                  background: "#EE3A43",
                  width: "100%",
                }}
              ></div>
              <div>
                <div className="chickenProteinInformationCard">
                  <img src="/img/logoGolichka.png" alt="" />
                  <p>
                    Создания и освоения промышленных биотехнологий переработки
                    животных и растительных, в том числе, малоценных ресурсов
                  </p>
                </div>
                <div className="chickenProteinInformationCard">
                  <img src="/img/logoGolichka.png" alt="" />
                  <p>
                    {" "}
                    Производства ферментированных гидролизатов белков и их
                    применений
                  </p>
                </div>
                <div className="chickenProteinInformationCard">
                  <img src="/img/logoGolichka.png" alt="" />
                  <p>
                    Инжиниринга и обеспечения технологическим оборудованием от
                    лучших европейских производителей
                  </p>
                </div>
                <div className="chickenProteinInformationCard">
                  <img src="/img/logoGolichka.png" alt="" />
                  <p>
                    Продвижения и освоения эффективных ферментных препаратов и
                    технологий компании «Новозаймс А/С (Дания)» на спиртовых,
                    крахмало-паточных, птице-, мясо-, зерноперерабатывающих и
                    других предприятиях
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="bioTasks">
            <h3 className="texth3">
              ПОЛУЧИТЕ БИОТЕХНОЛОГИЧЕСКИЕ РЕШЕНИЯ ВАШИХ ЗАДАЧ!
            </h3>
            <div
              style={{ border: "1px solid #48AA43", background: "#48AA43" }}
            ></div>
            <div className="li">
              <li>Технологический аудит</li>
              <li>Трансфер готовых биотехнологических решений</li>
              <li>
                Поставка ферментов и других биотехнологических инструментов
              </li>
              <li>
                Инжиниринг и особые условия поставки технологического
                оборудования
              </li>
              <li>Технологический сервис</li>
            </div>
            <div
              style={{ border: "1px solid#067BC2", background: "#067BC2" }}
            ></div>
            <h4 className="texth3">
              СВЯЖИТЕСЬ С НАМИ ЛЮБЫМ УДОБНЫМ ДЛЯ ВАС СПОСОБОМ
            </h4>
            <div className="bioTasksButton">
              <Link to="/contact">
                <button>Контакты</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardBeverageProduction;
