import { Image } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const FishProtein = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="mainImg">
            <img src="/img/fishban.jpg" alt="" />
            <h3>Ферментативный гидролизат (экстракт) рыбного белка</h3>
          </div>
          <div className="aminoAcids">
            <h3 className="text__h3">
              ОСНОВНЫЕ ПРЕИМУЩЕСТВА ПРОЦЕССА ФЕРМЕНТАТИВНОГО ГИДРОЛИЗА <br />{" "}
              БЕЛКА ОТНОСИТЕЛЬНО ДРУГИХ СПОСОБОВ ГИДРОЛИЗА
            </h3>
            <div
              style={{ border: "1.5px solid #005AAB", background: "#005AAB" }}
            ></div>
            <div className="aminoAcidsCard">
              <div>
                <img src="/img/gidroliz1.jpg" alt="" />
                <p>
                  Белоксодержащее сырье животного и растительного происхождения
                </p>
              </div>
              <div>
                <p>ФЕРМЕНТАТИВНЫЙ ГИДРОЛИЗ</p>
                <img src="/img/gidroliz2.jpg" alt="" />
              </div>
              <div>
                <img src="/img/gidroliz3.jpg" alt="" />
                <p>Пептиды</p>
              </div>
              <div>
                <img src="/img/gidroliz4.jpg" alt="" />
                <p>Аминокислоты</p>
              </div>
              <div>
                <li>Продукты питания</li>
                <li>Корма для животных. Аквакультуры</li>
                <li>
                  Питательные среды для микроорганизмов, обладающие высокими
                  биологическими свойствами
                </li>
              </div>
            </div>
            <div
              style={{ border: "1.5px solid #48AA43", background: "#48AA43" }}
            ></div>
            <ul className="vigetableUl">
              <li>
                Процесс гидролиза белка проводится в мягких условиях: при
                температуре 45-55 °С и pH 7-8
              </li>
              <li>Не происходит разрушения аминокислот</li>
              <li>Не происходит рацемизации аминокислот</li>
              <li>
                Процесс гидролиза является гибким и позволяет получать
                гидролизаты с разными функциональными и биологическими
                свойствами
              </li>
              <li>Низкие энергозатраты</li>
              <li>Высокая экологическая безопасность</li>
              <li>
                Не требуется дополнительная очистка гидролизатов от солей и
                примесей
              </li>
            </ul>
          </div>
          <div className="naturalProteinExtracts">
            <h3 className="text__h3">НАТУРАЛЬНЫЕ ЭКСТРАКТЫ БЕЛКОВ</h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <p>
              Натуральные экстракты животных и рыбных белков представляют собой{" "}
              <span>натуральный амино-пептидный комплекс </span>и является
              продуктом глубокой переработки пищевого сырья{" "}
              <span>на основе ферментативного гидролиза белка.</span>
            </p>
            <div className="proteinExtractsCardsOne">
              <img src="/img/extract-1.jpg" alt="" />
              <img src="/img/extract-2.jpg" alt="" />
              <ul>
                <h5>КЛЮЧЕВЫЕ СВОЙСТВА:</h5>
                <li>
                  Высокое содержание свободных аминокислот, коротких и средних
                  пептидов
                </li>
                <li>Полноценный аминокислотный состав</li>
                <li>Гипоаллергенность</li>
                <li>
                  Высокая биологическая ценность и биодоступность (высокая
                  степень усвоения)
                </li>
                <li>Низкое содержание жира</li>
                <li>Водорастворимость</li>
              </ul>
            </div>
            <div className="proteinExtractsCardsTwo">
              <h5>
                Эта Биотехнология - наиболее эффективный способ переработки
                малоценного сырья в продукты с высокой биологической ценностью.
              </h5>
              <h6>
                Для получения гидролизатов (экстрактов) рыбного белка непищевого
                стандарта могут использоваться и другие виды сырья (продукты
                потрошения рыбы)
              </h6>
            </div>
          </div>
          <div className="fishHb">
            <h3 className="text__h3">
              Пример получения и использования пищевого HP на
              рыбоперерабатывающем предприятии
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <Image src="/img/shema-ryba.jpg" />
          </div>
          <div className="fishProcessingIndustry">
            <h3 className="text__h3">
              НЕКОТОРЫЕ ПРИМЕНЕНИЯ ФЕРМЕНТАТИВНЫХ <br /> ГИДРОЛИЗАТОВ РЫБНОГО
              БЕЛКА (HP) В ПИЩЕВОЙ ИНДУСТРИИ
            </h3>
            <div
              style={{ border: "1.5px solid #48AA43", background: "#48AA43" }}
            ></div>
            <h5>Рыбоперерабатывающая индустрия</h5>
            <div className="fishProcessingIndustryCards">
              <div className="industryCardsOne">
                <img src="/img/fish-pic.png" alt="" />
                <p>Варианты использования:</p>
                <ul>
                  <li>
                    Обработка филе рыбы перед замораживанием или копчением.
                  </li>
                  <li>Использование в составе маринадов</li>
                  <li>Использование в составе соусов.</li>
                </ul>
              </div>
              <div className="industryCardsTwo">
                <p>
                  Преимущества использования рыбного гидролизата (экстракта):
                </p>
                <ul>
                  <li>
                    При добавлении HP улучшается аромат, вкус и цвет продуктов;
                  </li>
                  <li>Увеличивается выход продукции;</li>
                  <li>
                    Снижается потеря веса обработанных продуктов после
                    замораживания и приготовления;
                  </li>
                  <li>Повышается сочность и улучшается текстура продуктов;</li>
                  <li>Низкое содержание жира</li>
                  <li>
                    Повышается пищевая и биологическая ценность продуктов;
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="elementorPlant">
            <h3 className="text__h3">
              ДРУГИЕ ПРИМЕНЕНИЯ ФЕРМЕНТАТИВНЫХ ГИДРОЛИЗАТОВ РАСТИТЕЛЬНОГО БЕЛКА
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="elementorPlantCards">
              <div className="elementorPlantCard">
                <img src="/img/pr2.jpg" alt="" />
                <h6>
                  <span>HP </span>используется в качестве белковых обогатителей
                  и вкусо-ароматических комплексов в рецептурах{" "}
                  <span>
                    сухих и влажных кормов и лакомств для домашних питомцев
                  </span>
                </h6>
              </div>
              <div className="elementorPlantCard">
                <img src="/img/pr1.jpg" alt="" />
                <h6>
                  <span>HP</span>
                  используются в рецептурах стартовых кормов для аквакультуры,
                  для производства
                  <span>
                    сухих и жидких престартерных и стартерных кормов и кормовых
                    добавок для с\х животных и птицы. HP
                  </span>
                  значительно улучшают кормовую и биологическую ценность готовых
                  кормов
                </h6>
              </div>
              <div className="elementorPlantCard">
                <img src="/img/hcp-fish.jpg" alt="" />
                <h6>
                  <span>HP </span>используются в качестве источника аминного
                  азота (ростового фактора) в составе{" "}
                  <span>различных питательных сред,</span>в том числе и для
                  культивирования микроорганизмов, требова-тельных к составу ПС
                </h6>
              </div>
              <div className="elementorPlantCard">
                <img src="/img/lecheb2.jpg" alt="" />
                <h6>
                  <span>HP </span>необходимы для производства{" "}
                  <span>
                    функционального, спортивного, диетического и лечебно —
                    профилактического питания,
                  </span>{" "}
                  а также используются для улучшения потребительских свойств и
                  повышения биологической ценности привычных продуктов. Активно
                  используются в составе бульонов, соусов и маринадов как для
                  частного потребителя, так и в системе HORECA
                </h6>
              </div>
              <div className="elementorPlantCard">
                <img src="/img/lecheb.jpg" alt="" />
                <h6>
                  <span>HP </span>эффективны для
                  <span>восстанавливающей и лечебной косметики,</span>кремов для
                  рук, лица и тела, шампуней и др.
                </h6>
              </div>
            </div>
          </div>
          <div className="universalTechno">
            <h3 className="text__h3">
              УНИВЕРСАЛЬНЫЕ СЕКЦИИ ТЕХНОЛОГИЧЕСКИХ ЛИНИЙ
            </h3>
            <div
              style={{ border: "1.5px solid #48AA43", background: "#48AA43" }}
            ></div>
            <p>
              Технологии и Технологические линии, поставляемые UZBIOPRODUCT
              вместе с лучшими европейскими компаниями, универсальны и позволяют
              производить экстракты белков из различных видов сырья
              растительного и животного происхождения
            </p>
            <Image src="/img/sekciya1.jpg" />
            <h5 className="text__h3">
              Универсальные секции технологических линий
            </h5>
            <Image src="/img/sekciya2.jpg" />
          </div>
          <div
            style={{ border: "1.5px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="bioTasksPharm">
            <h3 className="text__h3">
              ПОЛУЧИТЕ БИОТЕХНОЛОГИЧЕСКИЕ РЕШЕНИЯ ВАШИХ ЗАДАЧ!
            </h3>
            <div
              style={{ border: "1px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="li">
              <p>НАШИ КОМПЕТЕНЦИИ</p>
              <li>
                Разработка и освоение промышленных биотехнологий глубокой
                переработки сырья животного и растительного происхождения и
                производства продуктов для жизни с уникальными биологическими
                свойствами
              </li>
              <li>
                Производство, дистрибуция и применение ферментативного
                гидролизата белка в различных индустриях
              </li>
              <li>
                Проектирование и запуск производств глубокой переработки сырья,
                в том числе лицензионных, обеспечение трансфера технологии,
                особые условия приобретения оборудования и поставки необходимых
                ферментных препаратов
              </li>
            </div>
            <div
              style={{ border: "1px solid#067BC2", background: "#067BC2" }}
            ></div>
            <h4 className="text__h3">
              СВЯЖИТЕСЬ С НАМИ ЛЮБЫМ УДОБНЫМ ДЛЯ ВАС СПОСОБОМ
            </h4>
            <div className="bioTasksButton">
              <Link to="/contact">
                <button>Контакты</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FishProtein;
