import { Image } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const CardAlcohol = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="mainImg">
            <img src="/img/alcohol.jpg" alt="" />
            <h3>Производство спирта / ферменты и оборудование</h3>
          </div>
          <div className="spirt">
            <div className="spirtText">
              <h3 className="texth3">СПИРТ</h3>
              <div
                style={{
                  border: "1px solid #EE3A43",
                  background: "#EE3A43",
                  width: "100%",
                }}
              ></div>
              <h3 className="text__h3">Производство</h3>
            </div>
            <div className="spirtImg">
              <img src="/img/spirt1.jpg" alt="" />
              <p>
                Производство спирта – это сотни лет успеха, тысячи работающих
                заводов, эталонные биотехнологические решения, а также огромный
                опыт инжиниринговых компаний и производителей оборудования.
                <br />
                <br /> На базе этой индустрии ежегодно разрабатываются и
                осваиваются новые биотехнологии, успешно применяемые в других
                индустриях
              </p>
            </div>
            <div className="processDiagram">
              <h3 className="texth3">СХЕМА ПРОЦЕССА И ПОЛУЧАЕМЫЕ ПРОДУКТЫ</h3>
              <div
                style={{ border: "1px solid #48A843", background: "#48A843" }}
              ></div>
              <h4 className="text__h3">
                Производство спирта - классический эталонный биотехнологический
                процесс
              </h4>
              <div className="processDiagramCards">
                <div className="processDiagramCard">
                  <p>
                    Основные процессы получения спирта — это конверсия крахмала
                    в сбраживаемые сахара под действием ферментных препаратов и
                    дальнейшее превращение сахаров в этанол под действием
                    дрожжевой биомассы
                  </p>
                </div>
                <Image src="/img/processDiagram.jpg" />
              </div>
            </div>
            <div className="spirtProduction">
              <div className="spirtText">
                <h3 className="text__h3">ПРОИЗВОДСТВО</h3>
                <div
                  style={{
                    border: "1px solid #EE3A43",
                    background: "#EE3A43",
                    width: "100%",
                  }}
                ></div>
                <h3 className="text__h3">
                  Стандартный пример (6000 дал / сутки).
                </h3>
              </div>
              <Image src="/img/productionSpirt.jpg" />
              <p>
                Объем инвестиций. Объект “Под Ключ” без учёта НДС -
                <span> 15.000.000 </span>евро
              </p>
              <div
                style={{ border: "1px solid #48A843", background: "#48A843" }}
              ></div>
              <p>
                Ориентировочный срок окупаемости –<span> 5 лет</span>
              </p>
            </div>
            <div
              style={{ border: "1.5px solid #E0E0E0", background: "#E0E0E0" }}
            ></div>
            <div className="alcoholPreparation">
              <h3 className="text__h3">
                СОВРЕМЕННЫЕ ПОДХОДЫ К ПРОИЗВОДСТВУ СПИРТА
              </h3>
              <div
                style={{ border: "1px solid #EE3A43", background: "#EE3A43" }}
              ></div>
              <div className="alcoholPreparationImg">
                <Image src="/img/alcoholPreparation.jpg" />
                <Image src="/img/alcoholPreparation2.jpg" />
              </div>
              <p>
                Современная технология производства спирта заключается в
                переработке сусла высоких концентраций. Применение предлагаемой
                технологии позволяет достичь следующих технико-экономических
                преимуществ производства:
              </p>
              <div className="incraseReduction">
                <div>
                  <h5>УВЕЛИЧЕНИЕ</h5>
                  <h6>
                    Производительности предприятия на существующем оборудовании
                    до 50%
                  </h6>
                </div>
                <div>
                  <h5 style={{ color: "#F79E14" }}>СОКРАЩЕНИЕ</h5>
                  <ul>
                    <li>воды на технологический процесс до 30%</li>
                    <li>
                      расхода пара на стадии нагрева водно-зерновой массы до 30%
                    </li>
                    <li>расхода электроэнергии до 35%</li>
                    <li>расхода охлаждающей, промывной воды</li>
                    <li>количества сточных вод до 40%</li>
                    <li>расхода пара на брагоректификацию до 35%</li>
                    <li>расхода пара на сушку барды и выпарку сиропа до 40%</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="enzymePreparations">
              <h3 className="text__h3">
                Ферментные препараты в основных процессах и вспомогательные
                материалы
              </h3>
              <div
                style={{ border: "1px solid #48A843", background: "#48A843" }}
              ></div>
              <div className="enzymeTable">
                <table>
                  <tr>
                    <th>СНИЖЕНИЕ ВЯЗКОСТИ </th>
                    <th>РАЗЖИЖЕНИЕ </th>
                    <th>ОСАХАРИВАНИЕ </th>
                    <th>СПЕЦИАЛИЗИРОВАННЫЕ ПРЕПАРАТЫ</th>
                  </tr>
                  <tr>
                    <td>Viscoferm</td>
                    <td>LpHera</td>
                    <td>Saczyme Plus 2X</td>
                    <td>Sanferm Yield</td>
                  </tr>
                  <tr>
                    <td>Novozyme 28255</td>
                    <td>Ban 480 LS</td>
                    <td>Saczyme Yield</td>
                    <td>Olexa FG</td>
                  </tr>
                  <tr>
                    <td>Viscozyme FG</td>
                    <td>Liquoflow Yield</td>
                    <td>San Super 360 L</td>
                    <td>Fitoflow</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Liquoflow Go 2X</td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>
              </div>
            </div> */}
            <div className="alcoholFermentation">
              <h3 className="text__h3">
                Ферментация - основа индустрии альтернативных белков и
                культивируемых продуктов
              </h3>
              <div
                style={{
                  border: "1.5px solid #EE3A43",
                  background: "#EE3A43",
                }}
              ></div>
              <div className="alcoholFermentationCard">
                <img src="/img/company2.jpg" alt="" />
                <div>
                  <h4>СПИРТОВЫЕ ЗАВОДЫ СМОГУТ ПРОИЗВОДИТЬ ЛЮБУЮ ЕДУ</h4>
                  <p>
                    Ферментация позволяет использовать микроорганизмы как
                    биофабрики для производства практически любых сложных
                    органических молекул, в частности, любых белков (мясных,
                    растительных, молочных), жиров и сахаров с высокой
                    скоростью, в больших объемах и по низким ценам{" "}
                    <span>
                      (подробнее о <a href="/fermentation">Ферментации</a>
                      ).{" "}
                    </span>
                  </p>
                  <h5>
                    В последние годы быстро развиваются такие применения, как
                    культивирование мясных и молочных продуктов. Согласно
                    прогнозам, цена культивированного мяса к 2030 году будет в 5
                    раз, а в 2035 году — в 10 раз меньше цены обычного мяса.
                  </h5>
                  <p>
                    Уже сегодня Ферментация широко используется в производстве
                    спирта, пива, вина, кормовых добавок для животных
                    (аминокислоты, кормовые дрожжи и др.), пищевых ингредиентов
                    и продуктов питания, витаминов, антибиотиков и многих других
                    продуктов.
                  </p>
                </div>
              </div>
            </div>
            <div className="engineeringEquipment">
              <h3 className="text__h3">ИНЖИНИРИНГ И ОБОРУДОВАНИЕ</h3>
              <div
                style={{
                  border: "1.5px solid #EE3A43",
                  background: "#EE3A43",
                }}
              ></div>
              <div className="engineeringEquipmentCard">
                <img
                  className="engineeringEquipmentCardImg"
                  src="/img/EQUIPMENT1.jpg"
                  alt=""
                />
                <div>
                  <p>
                    Наши специалисты, совместно с ведущими российскими и
                    европейскими инжиниринговыми и технологическими компаниями,
                    проектируют биотехнологические производства, в частности,{" "}
                    <span>
                      для всех описанных в данной презентации проектов,
                    </span>
                    и оснащают их оборудованием ведущих европейских
                    производителей:{" "}
                    <span>
                      CFT Group, ICF & Welko (Италия), GEA Group, Flottweg
                      (Германия), Bertsch-Laska (Австрия) и других
                    </span>
                  </p>
                  <img src="/img/EQUIPMENT2.jpg" alt="" />
                </div>
              </div>
            </div>
            <div
              style={{ border: "1.5px solid #E0E0E0", background: "#E0E0E0" }}
            ></div>
            <div className="bioTasks">
              <h3 className="texth3">
                ПОЛУЧИТЕ БИОТЕХНОЛОГИЧЕСКИЕ РЕШЕНИЯ ВАШИХ ЗАДАЧ!
              </h3>
              <div
                style={{ border: "1px solid #48AA43", background: "#48AA43" }}
              ></div>
              <div className="li">
                <li>Технологический аудит</li>
                <li>Трансфер готовых биотехнологических решений</li>
                <li>
                  Поставка ферментов и других биотехнологических инструментов
                </li>
                <li>
                  Инжиниринг и особые условия поставки технологического
                  оборудования
                </li>
                <li>Технологический сервис</li>
              </div>
              <div
                style={{ border: "1px solid#067BC2", background: "#067BC2" }}
              ></div>
              <h4 className="text__h3">
                СВЯЖИТЕСЬ С НАМИ ЛЮБЫМ УДОБНЫМ ДЛЯ ВАС СПОСОБОМ
              </h4>
              <div className="bioTasksButton">
                <Link to="/contact">
                  <button>Контакты</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardAlcohol;
