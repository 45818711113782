import { Image } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const CardHomeAnimalFood = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="mainImg">
            <img src="/img/pet.jpg" alt="" />
            <h3>
              Гидролизаты и экстракты куриного белка для влажных кормов, снэков
              и лакомств для домашних питомцев
            </h3>
          </div>
          <div className="chickenProteinInformation">
            <div className="chickenProteinInformationLeft">
              <img src="/img/company2.jpg" alt="" />
              <p>
                Биотехнологии и технологические линии , поставляемые партнёрами
                UZBIOPRODUCT , универсальны и используются для индустриального
                производства широкой гаммы гидролизатов белка с заданными
                свойствами из различных видов сырья
              </p>
            </div>
            <div className="chickenProteinInformationRight">
              <h3 className="text_h3">
                ГИДРОЛИЗАТЫ И ЭКСТРАКТЫ КУРИНОГО БЕЛКА. ОБЩАЯ ИНФОРМАЦИЯ
              </h3>
              <div
                style={{
                  border: "1.5px solid #EE3A43",
                  background: "#EE3A43",
                  width: "100%",
                }}
              ></div>
              <div>
                <div className="chickenProteinInformationCard">
                  <img src="/img/logoGolichka.png" alt="" />
                  <p>
                    Гидролизат куриного белка «HCP Premium 150» производится из
                    свежего куриного сырья пищевого стандарта с высоким
                    содержанием мяса, поступающего с птицеперерабатывающего
                    комплекса.
                  </p>
                </div>
                <div className="chickenProteinInformationCard">
                  <img src="/img/logoGolichka.png" alt="" />
                  <p>
                    Технология производства продукта «HCP Premium 150» включает
                    в себя процессы направленного ферментативного гидролиза
                    куриного белка и его последующей ультрафильтрации на
                    мембранной установке.
                  </p>
                </div>
                <div className="chickenProteinInformationCard">
                  <img src="/img/logoGolichka.png" alt="" />
                  <p>
                    Гидролизат куриного белка «HCP Premium 150» представляет
                    собой аминно-пептидный комплекс с высоким содержанием
                    коротких и среднихпептидов, а также свободных аминокислот.
                  </p>
                </div>
              </div>
              <img src="/img/chickenUn.jpg" alt="" />
            </div>
          </div>
          <div className="applications">
            <h3 className="text_h3">ПРИМЕНЕНИЯ</h3>
            <div
              style={{ border: "1.5px solid #005AAB", background: "#005AAB" }}
            ></div>
            <p>
              Гидролизаты и экстракты куриного белка «HCP Premium 150» и
              «HCP-B4», которые мы предлагаем на рынке, используются в
              рецептурах кормов для кошек и собак в качестве источника
              полноценного и гипоаллергенного животного белка с высокой степенью
              биодоступности, а также натуральной мясной вкусоароматической
              добавки.
            </p>
            <div className="usedFor">
              <div>
                <h4 className="text_h3">Где используется</h4>
                <div style={{ border: "1px solid #F49F0A" }}></div>
                <ul>
                  <li>Влажные корма</li>
                  <li>Снэки</li>
                  <li>Лакомства</li>
                  <li>Гипоаллергенные диеты для животных</li>
                  <li>Лечебно-профилактические диеты</li>
                  <li>Кормовые биологически активные добавки</li>
                </ul>
              </div>
              <div>
                <h4 className="text_h3">Для чего используются</h4>
                <div style={{ border: "1px solid #F49F0A" }}></div>
                <ul>
                  <li>Улучшение ПОЕДАЕМОСТИ конечных продуктов</li>
                  <li>Повышение биологической ценности</li>
                  <li>
                    Нормализация рецептуры корма по содержанию белка без
                    увеличения количества золы и жира
                  </li>
                  <li>Повышение переваримости белка в конечном продукте</li>
                </ul>
              </div>
            </div>
            <div className="generalInformation">
              <h3 className="text_h3">ОБЩАЯ ИНФОРМАЦИЯ</h3>
              <p>
                Ключевые биологические и функционально-технологические свойства:
              </p>
              <div>
                <div className="generalInformationBio">
                  <img src="/img/golichkaPng.png" alt="" />
                  <p>Высокое содержание животного белка (≥ 86%)</p>
                </div>
                <div className="generalInformationBio">
                  <img src="/img/golichkaPng.png" alt="" />
                  <p>Полноценный аминокислотный состав</p>
                </div>
                <div className="generalInformationBio">
                  <img src="/img/golichkaPng.png" alt="" />
                  <p>Высокое содержание незаменимых аминокислот</p>
                </div>
                <div className="generalInformationBio">
                  <img src="/img/golichkaPng.png" alt="" />
                  <p>Высокая степень переваримости белка (≥95%)</p>
                </div>
                <div className="generalInformationBio">
                  <img src="/img/golichkaPng.png" alt="" />
                  <p>Низкое содержание жира и золы</p>
                </div>
                <div className="generalInformationBio">
                  <img src="/img/golichkaPng.png" alt="" />
                  <p>Гипоаллергенность</p>
                </div>
                <div className="generalInformationBio">
                  <img src="/img/golichkaPng.png" alt="" />
                  <p>
                    Высокое содержание глутаминовой кислоты в свободном
                    состоянии
                  </p>
                </div>
                <div className="generalInformationBio">
                  <img src="/img/golichkaPng.png" alt="" />
                  <p>Высокая растворимость</p>
                </div>
                <div className="generalInformationBio">
                  <img src="/img/golichkaPng.png" alt="" />
                  <p>Нежный куриный вкус и аромат</p>
                </div>
              </div>
              <h4>
                ! Для большинства пептидов, идентифицированных в гидролизатах
                куриного белка, предшественниками являются миофибриллярные белки
                мышечной ткани, а также и белки соединительной ткани. Таким
                образом, предлагаемые нами ингредиенты могут выступать в
                качестве натуральных стимуляторов роста мышечной массы у
                соответствующих пород собак.
              </h4>
            </div>
          </div>
          <div
            style={{ border: "1.5px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="productsCompositions">
            <div className="productsCompositionsLine">
              <h3 className="text__h3">ПРОДУКТЫ И ИХ СОСТАВЫ</h3>
              <div
                style={{
                  border: "1.5px solid #EE3A43",
                  background: "#EE3A43",
                  width: "20%",
                }}
              ></div>
            </div>
            <div className="productsCompositionsTable">
              <table>
                <tr>
                  <th>ОСНОВНЫЕ ХАРАКТЕРИСТИКИ </th>
                  <th>HCP PREMIUM 150 </th>
                  <th>HCP - B4</th>
                </tr>
                <tr>
                  <td>Влажность</td>
                  <td>4% (±2%)</td>
                  <td>4% (±2%)</td>
                </tr>
                <tr>
                  <td>Протеин</td>
                  <td>88% (±2%)</td>
                  <td>85% (±2%)</td>
                </tr>
                <tr>
                  <td>Жир</td>
                  <td>0% (±0,5%)</td>
                  <td> 5%</td>
                </tr>
                <tr>
                  <td>Зола</td>
                  <td>7% (±1%)</td>
                  <td>7% (±1%)</td>
                </tr>
                <tr>
                  <td>Углеводы</td>
                  <td>0% (± 0,5%)</td>
                  <td>0% (± 0,5%)</td>
                </tr>
                <tr>
                  <td>Соль</td>
                  <td>2,5% (±0,5%)</td>
                  <td>2,5% (±0,5%)</td>
                </tr>
              </table>
            </div>
          </div>
          <div className="productsAndProperties">
            <div className="productsAndPropertiesLine">
              <h3 className="text__h3">
                ПРОДУКТЫ И ИХ КЛЮЧЕВЫЕ БИОЛОГИЧЕСКИЕ СВОЙСТВА
              </h3>
              <div
                style={{
                  border: "1.5px solid #48AA43",
                  background: "#48AA43",
                  width: "10%",
                }}
              ></div>
            </div>
            <div className="productsAndPropertiesImg">
              <Image src="/img/biologicalProperties1.jpg" />
              <Image src="/img/productsAndProperties.jpg" />
            </div>
          </div>
          <div className="productsAndOrganoleptic">
            <div className="productsAndOrganolepticLine">
              <h3 className="text__h3">
                ПРОДУКТЫ И ИХ ОРГАНОЛЕПТИЧЕСКИЕ СВОЙСТВА
              </h3>
              <div
                style={{
                  border: "1.5px solid #067BC2",
                  background: "#067BC2",
                  width: "10%",
                }}
              ></div>
            </div>
            <div className="productsAndOrganolepticImg">
              <div>
                <Image src="/img/productsAndOrganoleptic1.jpg" />
                <h5>Профиль запахов для продуктов</h5>
                <p>
                  <a>HCP — B4</a> и <span>HCP Premium 150</span>
                </p>
              </div>
              <div>
                <Image src="/img/productsAndOrganoleptic2.jpg" />
                <h5>Вкусовой профиль для продуктов</h5>
                <p>
                  <a>HCP — B4</a> и <span>HCP Premium 150</span>
                </p>
              </div>
            </div>
          </div>
          <div
            style={{ border: "1.5px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="chickenProteinExtracts">
            <h3 className="text_h3">
              ПРИМЕРЫ ИСПОЛЬЗОВАНИЯ ГИДРОЛИЗАТОВ И ЭКСТРАКТОВ КУРИНОГО БЕЛКА
            </h3>
            <div
              style={{
                border: "1.5px solid #48AA43",
                background: "#48AA43",
                width: "20%",
              }}
            ></div>
            <p>
              Наши партнеры, ведущие российские производители стандартного и
              функционального питания для домашних животных, разработали и
              производят широкий ассортимент влажных кормов, снэков, лакомств и
              кормовых добавок, используя в рецептурах своих продуктов наши
              уникальные ингредиенты.
            </p>
            <div className="chickenProteinExtracts__Cards">
              <div className="chickenProteinExtracts__Card">
                <img src="/img/chickenProteinExtracts__Cards1.jpg" alt="" />
                <div className="chickenProteinExtractsLine">
                  <div
                    style={{
                      border: "1.5px solid #F49F0A",
                      background: "#F49F0A",
                      width: "50%",
                    }}
                  ></div>
                </div>
                <h6>Снэки</h6>
              </div>
              <div className="chickenProteinExtracts__Card">
                <img src="/img/chickenProteinExtracts__Card2.jpg" alt="" />
                <div className="chickenProteinExtractsLine">
                  <div
                    style={{
                      border: "1.5px solid #F49F0A",
                      background: "#F49F0A",
                      width: "50%",
                    }}
                  ></div>
                </div>
                <h6>Влажные корма и консервы</h6>
              </div>
              <div className="chickenProteinExtracts__Card">
                <img src="/img/chickenProteinExtracts__Cards3.jpg" alt="" />
                <div className="chickenProteinExtractsLine">
                  <div
                    style={{
                      border: "1.5px solid #F49F0A",
                      background: "#F49F0A",
                      width: "50%",
                    }}
                  ></div>
                </div>
                <h6>Функциональные и обычные угощения</h6>
              </div>
              <div className="chickenProteinExtracts__Card">
                <img src="/img/chickenProteinExtracts__Cards4.jpg" alt="" />
                <div className="chickenProteinExtractsLine">
                  <div
                    style={{
                      border: "1.5px solid #F49F0A",
                      background: "#F49F0A",
                      width: "50%",
                    }}
                  ></div>
                </div>
                <h6>Колбаса и колбасные изделия</h6>
              </div>
              <div className="chickenProteinExtracts__Card">
                <img src="/img/chickenProteinExtracts__Cards5.jpg" alt="" />
                <div className="chickenProteinExtractsLine">
                  <div
                    style={{
                      border: "1.5px solid #F49F0A",
                      background: "#F49F0A",
                      width: "50%",
                    }}
                  ></div>
                </div>
                <h6>Мясные палочки</h6>
              </div>
            </div>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="bioTasks">
            <h3 className="text_h3">
              ПОЛУЧИТЕ БИОТЕХНОЛОГИЧЕСКИЕ РЕШЕНИЯ ВАШИХ ЗАДАЧ!
            </h3>
            <div
              style={{ border: "1px solid #48AA43", background: "#48AA43" }}
            ></div>
            <p>Наши услуги и компетенции:</p>

            <div className="li">
              <li>Общая информация о предлагаемом компоненте</li>
              <li>Общие рекомендации по условиям применения</li>
              <li>
                Предоставление бесплатных образцов для анализа и тестирования
              </li>
              <li>
                Коммерческие поставки гидролизата куриного белка «HCP Premium
                150»
              </li>
              <li>Технологический сервис</li>
            </div>
            <div
              style={{ border: "1px solid#067BC2", background: "#067BC2" }}
            ></div>
            <h4 className="texth3">
              СВЯЖИТЕСЬ С НАМИ ЛЮБЫМ УДОБНЫМ ДЛЯ ВАС СПОСОБОМ
            </h4>
            <div className="bioTasksButton">
              <Link to="/contact">
                <button>Контакты</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardHomeAnimalFood;
