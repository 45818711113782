import { Image } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const AcidsAminoOrganic = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="mainImg">
            <img src="/img/cardProtein.jpg" alt="" />
            <h3>
              ФЕРМЕНТАЦИЯ <br />
              Производство альтернативных белков и культивируемых продуктов
            </h3>
          </div>
          <div className="fermentationAminoacids">
            <h3 className="text__h3">
              ФЕРМЕНТАЦИЯ. ПРОИЗВОДСТВО АМИНОКИСЛОТ И ОРГАНИЧЕСКИХ КИСЛОТ
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="fermentationAminoacidsImg">
              <Image src="/img/fermenty-pic1.jpg" />
              <Image src="/img/fermenty-pic2.jpg" />
            </div>
            <div className="fermentationAminoacidsText">
              <p>
                Ферментация (брожение) играет ключевую роль в развитии проекта
                глубокой переработки зерна и значительно повышает его
                экономическую эффективность.
              </p>
              <h6>
                Глюкоза, мальтоза, фруктоза и др., а также Ферментативные
                Гидролизаты глютена являются основой питательных сред для
                различных микроорганизмов / бактерий, которые и «производят»
                аминокислоты, органические кислоты и другие полезные и ликвидные
                продукты
              </h6>
            </div>
          </div>
          <div className="lysineProduction">
            <h3 className="text_h3">ПРОИЗВОДСТВО ЛИЗИНА</h3>
            <div
              style={{ border: "1.5px solid #48AA43", background: "#48AA43" }}
            ></div>
            <div className="lysineProductionCard">
              <div>
                <img src="/img/lizin.png" alt="" />
                <p>
                  В основном, L-лизин используется в качестве кормовой добавки,
                  что связано с низким количеством этой аминокислоты в
                  растительных кормах
                </p>
              </div>
              <div>
                <h4>
                  Лизин – это незаменимая аминокислота, входящая в состав
                  практически любых белков, – необходима для роста,
                  восстановления тканей, производства антител, гормонов,
                  ферментов, альбуминов
                </h4>
              </div>
              <Image
                className="lysineProductionCardImg"
                src="/img/tech-shema.jpg"
              />
            </div>
          </div>
          <div className="productionOfLactic">
            <h3 className="texth3">ПРОИЗВОДСТВО МОЛОЧНОЙ КИСЛОТЫ</h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="productionOfLacticCards">
              <div>
                <img src="/img/mol-kislota.png" alt="" />
                <p>Молочная кислота</p>
                <img src="/img/polilaktid.png" alt="" />
                <p>Полилактид</p>
              </div>
              <div className="productionOfLacticTwo">
                <h4>
                  Пищевая молочная кислота представляет собой водный раствор
                  молочной кислоты, который получают ферментацией
                  углеводсодержащего сырья
                </h4>
                <p>
                  Выход молочной кислоты в процессе ферментации составляет
                  85–95%. <br />
                  <br /> Молочная кислота широко востребована в пищевой
                  промышленности, ветеринарии, медицине и в производстве
                  Биоразлагаемых пластиков (например, PLA)
                </p>
                <h5>
                  90% потребности РФ <span>в молочной кислоте покрывается</span>
                  за счёт импорта.{" "}
                  <span>
                    Ситуация в РФ с другими органическими кислотами аналогичная
                  </span>
                </h5>
                <h6>
                  Производство{" "}
                  <span>биоразлагаемого полиэфира полилактида</span> (PLA)
                  становится крупнейшим потребителем молочной кислоты
                </h6>
              </div>
              <Image
                className="productionOfLacticCardsImg"
                src="/img/tech-shema-two.jpg"
              />
            </div>
          </div>
          <div className="productionOfVitamins">
            <h3 className="text_h3">ПРОИЗВОДСТВО ВИТАМИНОВ</h3>
            <div
              style={{ border: "1.5px solid #48AA43", background: "#48AA43" }}
            ></div>
            <div className="productionOfVitaminsCard">
              <div>
                <img src="/img/vitamin.png" alt="" />
                <h6>Витамин B2 — Рибофлавин</h6>
              </div>
              <div>
                <p>
                  <span>Витамины </span> — это низкомолекулярные органические
                  вещества, способные в очень низких концентрациях оказывать
                  сильное и разнообразное воздействие на живые организмы. Они
                  принимают активное участие в метаболизме человека и высших
                  животных, оказывая влияние на различные физиологические
                  процессы
                </p>
                <h4>
                  Объем производства витаминов
                  <span>
                    {" "}
                    для животноводства в России в 2019 г. составляет
                  </span>{" "}
                  не более 15% от общей потребности
                </h4>
              </div>
              <div>
                <h2>
                  Микробиологическим путем получают витамин В12, витамин В2 –
                  рибофлавин, а также эргостерин и каротин, и многие другие
                </h2>
                <h6>
                  Согласно расчетам аналитиков DISCOVERY Research Group, объем
                  рынка витаминов для животноводства в России в 2019 г. составил
                  11 000 тонн. В структуре рынка в 2019 г. в России наибольшую
                  долю витаминов для животных занимала категория для с/х
                  животных и птиц
                </h6>
              </div>
            </div>
          </div>
          <div className="fermentationProteinsFood">
            <h3 className="text_h3">
              ФЕРМЕНТАЦИЯ – ОСНОВА ИНДУСТРИИ <br /> АЛЬТЕРНАТИВНЫХ БЕЛКОВ И
              КУЛЬТИВИРУЕМЫХ ПРОДУКТОВ
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="fermentationProteinsFoodText">
              <div className="proteinsFoodTextLeft">
                <img src="/img/fermentationProteinsFoodText.jpg" alt="" />
                <p>
                  Спиртовые и пивные заводы, а также предприятия глубокой
                  переработки зерна смогут производить любую еду
                </p>
              </div>
              <div>
                <h6>
                  В ноябре 2020 года Всемирный экономический форум назвал
                  ферментацию ключевой областью инноваций в мире
                </h6>
                <h6>
                  Ферментация{" "}
                  <span>
                    позволяет использовать микроорганизмы как биофабрики для
                  </span>
                  производства практически любых сложных органических молекул,{" "}
                  <span>
                    в частности, любых белков (мясных, молочных, …), жиров и
                    сахаров с высокой скоростью, в больших объемах и по низким
                    ценам (подробнее о Ферментации см. в разделе …)
                  </span>
                </h6>
                <div className="proteinsFoodTextRightCard">
                  <h6>
                    <span>
                      В последние годы быстро развиваются такие применения, как
                      культивирование мясных и молочных продуктов. Согласно
                      прогнозам{" "}
                    </span>
                    цена культивированного мяса к 2030 году будет в 5 раз, а в
                    2035 году – в 10 раз меньше цены обычного мяса.
                  </h6>
                </div>
                <h6>
                  <span>Уже сегодня ферментация широко используется в </span>
                  производстве спирта, пива, кормовых добавок для животных
                  (аминокислоты, дрожжи, …), пищевых ингредиентов и продуктов
                  питания, витаминов, антибиотиков
                  <span> и многих других продуктов</span>
                </h6>
              </div>
            </div>
          </div>
          <div
            style={{ border: "1.5px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="bioTasksPharm">
            <h3 className="texth3">
              ПОЛУЧИТЕ БИОТЕХНОЛОГИЧЕСКИЕ РЕШЕНИЯ ВАШИХ ЗАДАЧ!
            </h3>
            <div
              style={{ border: "1px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="li">
              <p>НАШИ КОМПЕТЕНЦИИ</p>
              <li>
                Разработка и освоение промышленных биотехнологий глубокой
                переработки сырья животного и растительного происхождения и
                производства продуктов для жизни с уникальными биологическими
                свойствами
              </li>
              <li>
                Производство, дистрибуция и применение ферментативного
                гидролизата белка в различных индустриях
              </li>
              <li>
                Проектирование и запуск производств глубокой переработки сырья,
                в том числе лицензионных, обеспечение трансфера технологии,
                особые условия приобретения оборудования и поставки необходимых
                ферментных препаратов
              </li>
            </div>
            <div
              style={{ border: "1px solid#067BC2", background: "#067BC2" }}
            ></div>
            <h4 className="texth3">
              СВЯЖИТЕСЬ С НАМИ ЛЮБЫМ УДОБНЫМ ДЛЯ ВАС СПОСОБОМ
            </h4>
            <div className="bioTasksButton">
              <Link to="/contact">
                <button>Контакты</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcidsAminoOrganic;
