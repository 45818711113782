import React from "react";
import { Collapse, Image } from "antd";
const { Panel } = Collapse;
const News = () => {
  return (
    <div>
      <div className="relative">
        <div className="body">
          <div className="card">
            <Collapse defaultActiveKey={["1"]} accordion className="collapse">
              <Panel
                className="collapsePanel"
                header="Новые ферментативные решения для спиртовой отрасли"
                key="1"
              >
                <p>
                  В 2021 году компания UZBIOPRODUCT совместно с «Новозаймс»
                  выводит на рынок новые продукты для спиртовой отрасли:
                </p>
                <ul>
                  <li>
                    препарат «Лецитаза Ультра» является ферментативным решением
                    для предотвращения пенообразования на стадиях
                    дрожжегенерации и брожения. Препарат препятствует
                    образованию пены путем гидролиза
                    глицерофосфолипидов;препарат «Лецитаза Ультра» является
                    ферментативным решением для предотвращения пенообразования
                    на стадиях дрожжегенерации и брожения. Препарат препятствует
                    образованию пены путем гидролиза глицерофосфолипидов;
                  </li>
                  <li>
                    препарат «Файтафло ФГ» является ферментативным решением для
                    сокращения остаточного количества отложений: на
                    теплообменниках, брагоректификационных колоннах и выпарном
                    оборудовании, что даёт возможность 2-3х кратно увеличить
                    работу оборудования без остановки на мойку.
                  </li>
                </ul>
                <p>
                  Для получения более подробной информации по продуктам,
                  свяжитесь с нашими специалистами по адресу электронной почты:
                  <a href="uzbioproduct@gmail.com" target="_blank">
                    uzbioproduct@gmail.com
                  </a>
                </p>
              </Panel>
              <Panel
                className="collapsePanel"
                header="Технология «Frontia Fiberwash»"
                key="2"
              >
                <p>
                  Наша компания совместно с «Новозаймс» вывела на рынок новую
                  технологию «Frontia Fiberwash», которая успешно показала себя
                  на ряде предприятий по переработки зерна кукурузы. Данная
                  технология позволяет вести мокрый процесс с дополнительным
                  увеличением выхода крахмала, снижением объёма замочной воды и
                  нагрузки на очистные сооружения, снижением энергопотребления
                  на выпарку и другим улучшениям технико-экономических
                  показателей. <br /> <br /> Для получения более подробной
                  информации по продуктам, свяжитесь с нашими специалистами по
                  адресу электронной почты:{" "}
                  <a href="uzbioproduct@gmail.com" target="_blank">
                    uzbioproduct@gmail.com
                  </a>
                </p>
              </Panel>
              <Panel
                className="collapsePanel"
                header="Инновационное решение для предприятий, перерабатывающих пшеницу"
                key="3"
              >
                <p>
                  В 2021 году компания «UZBIOPRODUCT» совместно с «Новозаймс»
                  разработала инновационное решение для предприятий глубокой
                  переработки пшеницы. Технология «Frontia GlutenEx» позволяет
                  увеличить выход целевого продукта – глютена и другим
                  улучшениям технико-экономических показателей. <br />
                  <br /> Для получения более подробной информации по продуктам,
                  свяжитесь с нашими специалистами по адресу электронной почты:
                  <a href="uzbioproduct@gmail.com" target="_blank">
                    uzbioproduct@gmail.com
                  </a>
                </p>
              </Panel>
              <Panel
                className="collapsePanel"
                header="«ПроКрахмал: тенденции рынка глубокой переработки зерна»"
                key="4"
              >
                <h2>
                  На V-й международной конференции «ПроКрахмал: тенденции рынка
                  глубокой переработки зерна», Ольга Самсонова, директор по
                  продвижению ООО «UZBIOPRODUCT», представила технологию Frontia
                  для оптимизации мокрого процесса глубокой переработки пшеницы
                  и кукурузы.
                </h2>
                <p>
                  Frontia — это уникальная ферментативная технология, которая
                  обеспечивает увеличение выхода крахмала и глютена, снижение
                  вязкости, снижение расхода сырья при достижении тех же
                  показателей, увеличение мощностей на существующем
                  оборудовании. Также спикер представила технологию производства
                  напитков из зерновых культур: “По оценке Euromonitor к 2025
                  году рынок растительных альтернатив достигнет 22 миллиардов
                  долларов. У зерновых напитков много преимуществ, поэтому это
                  направление является крайне востребованным и перспективным”
                </p>
                <Image src="/img/souzkrahmal.jpg" />
              </Panel>
              <Panel
                className="collapsePanel"
                header="Компания UZBIOPRODUCT приступила к продвижению новых готовых решений:"
                key="5"
              >
                <ul>
                  <li>
                    Производство напитков из зерновых культур/ растительного
                    молока:
                  </li>
                  <a href="">подробнее в презентации</a>
                  <li>Новые биорешения для премиксов и кормовых добавок:</li>
                  <a href="">подробнее в презентации</a>
                </ul>
                <h2>
                  Многие производители уже успешно используют эти решения!
                </h2>
              </Panel>
              <Panel
                className="collapsePanel"
                header="Компания UZBIOPRODUCT гарантирует бесперебойные поставки ферментов всем своим Партнерам"
                key="6"
              >
                <p>
                  Несмотря на то, что во всем мире останавливаются производства,
                  большие проблемы с логистикой, что влечет за собой перебои с
                  поставками, мы получили гарантию поставок от производителя и
                  сами сделаем все возможное, чтобы все наши клиенты были
                  обеспечены всеми необходимыми ферментами.
                </p>
                <h2>
                  Отметим, что понимание ситуации требуется и от Партнеров,
                  поэтому мы просим точнее планировать графики и объемы заказов.
                </h2>
              </Panel>
              <Panel
                className="collapsePanel"
                header="URGENT! Производители концентрированных соков из фруктов и ягод"
                key="7"
              >
                <p>
                  На улице уже весна и необходимо срочно позаботится о запасах
                  ферментов. Напоминаем, что предлагаемые нами ферменты
                  эффективны при производстве концентратов из клубники,
                  дикорастущих ягод, яблок, груш и других фруктов
                </p>
              </Panel>
              <Panel
                className="collapsePanel"
                header="Ферментативные гидролизаты белков"
                key="8"
              >
                <p>
                  В 2019 году значительно вырос интерес ( и продолжает быстро
                  расти ) предприятий пищевой и кормовой индустрий к разработке
                  и внедрению биотехнологических процессов трансформации
                  белоксодержащего сырья животного и растительного
                  происхождения, и производства ферментативных гидролизатов
                  белка с использованием протеаз компании Новозаймс (Дания).
                  Более 15 компаний получили бесплатные образцы протеаз в
                  ассортименте и проводят лабораторные тесты по подбору
                  оптимальных режимов ферментолиза:
                </p>
                <a href="">подробнее в презентации</a>
              </Panel>
            </Collapse>
            <div
              style={{ border: "1.5px solid #E0E0E0", background: "#E0E0E0" }}
            ></div>
            <div className="collopseCard">
              <h3>НОВОСТНЫЕ САЙТЫ, СВЯЗАННЫЕ С ПРОЕКТАМИ UZBIOPRODUCT</h3>
              <div
                style={{ border: "1.5px solid #47AA42", background: "#47AA42" }}
              ></div>
              <ul>
                <li>
                  <a href="http://www.foodnavigator.ru/" target="_blank">
                    http://www.foodnavigator.ru/
                  </a>
                </li>
                <li>
                  <a href="https://www.petfoodprocessing.net/" target="_blank">
                    https://www.petfoodprocessing.net/
                  </a>
                </li>
                <li>
                  Агроновости:
                  <a href="https://agrovesti.net/" target="_blank">
                    https://agrovesti.net/
                  </a>
                </li>
                <li>
                  Новости пищевой промышленности:
                  <a href="www.foodnewsweek.ru" target="_blank">
                    www.foodnewsweek.ru
                  </a>
                </li>
                <li>
                  Новости спиртового рынка:
                  <a href="https://spirt-express.ru/" target="_blank">
                    https://spirt-express.ru/
                  </a>
                </li>
                <li>
                  Новости молочного рынка каждый день:
                  <a href="https://www.dairynews.ru/news/" target="_blank">
                    https://www.dairynews.ru/news/
                  </a>
                </li>

                <li>
                  Новости рынка товаров для домашних животных:{" "}
                  <a href="https://zooinform.ru/business/news/" target="_blank">
                    https://zooinform.ru/business/news/
                  </a>
                </li>
                <li>
                  Агропромышленный портал:
                  <a
                    href="https://www.agroxxi.ru/novosti-selskogo-hozjaistva.html"
                    target="_blank"
                  >
                    https://www.agroxxi.ru/novosti-selskogo-hozjaistva.html
                  </a>
                </li>
                <li>
                  Сельскохозяйственные биотехнологии в сфере
                  сельскохозяйственных культур, лесном хозяйстве,
                  животноводстве, рыбном хозяйстве и агропромышленном комплексе
                  ФАО:
                  <a
                    href="http://www.fao.org/biotech/biotech-news/ru/"
                    target="_blank"
                  >
                    http://www.fao.org/biotech/biotech-news/ru/
                  </a>
                </li>
                <li>
                  Исследовательская компания Abercade, отраслевые
                  новости/Биотехнологии
                </li>
                <li>
                  Новости МИКРОБИОЛОГИИ и о ПИТАТЕЛЬНЫХ СРЕДАХ:{" "}
                  <a
                    href="http://sci-dig.ru/tag/mikrobiologiya/"
                    target="_blank"
                  >
                    http://sci-dig.ru/tag/mikrobiologiya/
                  </a>
                </li>
                <li>
                  Международное микробиологическое сообщество:
                  <a href=" http://microbiosociety.ru" target="_blank">
                    {" "}
                    http://microbiosociety.ru
                  </a>
                </li>
                <li>
                  Форум и выставка «ПротеинТек». Тренды и технологии в
                  производстве и использовании растительных и микробных
                  протеинов. Глубокая переработка высокобелковых культур:{" "}
                  <a href="http://www.proteintek.org/novosti/" target="_blank">
                    http://www.proteintek.org/novosti/
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
