import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { FaPhone } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";

const Contact = () => {
  const form = useRef();

  const [done, setDone] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_qyx5hz8",
        "template_kzc1g8s",
        form.current,
        "f3F_6xmOdk6WuEinG"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message done");
          setDone(true);
        },
        (error) => {
          console.log(error.text);
          console.log("message errr");
        }
      );
    e.target.reset();
  };

  return (
    <>
      <div className="relative">
        <div className="body">
          <div className="card">
            <div className="contact-page">
              <h1>КОНТАКТЫ</h1>
              <div style={{ border: "1px solid red" }}></div>
              <div className="contact">
                <div className="contact-address">
                  <div style={{ marginBottom: "50px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          border: "2px solid #F6A11A",
                          height: "30px",
                          width: "5px",
                          background: "#F6A11A",
                        }}
                      ></div>
                      <h3>АДРЕС ОФИСА:</h3>
                    </div>
                    <div
                      style={{ display: "flex", gap: "10px", margin: "20px 0" }}
                    >
                      <FaPhone style={{ fontSize: "18px" }} />
                      <p>
                        Тел./Факс:{" "}
                        <a href="tel:+998977256538">+99897 725 65 38</a> <br />{" "}
                        <a href="tel:+998911910005">+99891 191 00 05,</a> <br />{" "}
                        <a href="tel:+998935555666">+99893 555 56 66</a>
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginBottom: "20px",
                      }}
                    >
                      <MdLocationPin style={{ fontSize: "22px" }} />
                      <p>
                        г.Ташкент, Шайхонтохурский р-н, ул. Абдулла Кадирий,
                        д.1A
                      </p>
                    </div>
                  </div>
                  {/* <div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <div
                    style={{
                      border: "2px solid #48AA43",
                      height: "30px",
                      width: "5px",
                      background: "#48AA43",
                    }}
                  ></div>
                  <h3>АДРЕС СКЛАДА:</h3>
                </div>
                <div style={{ display: "flex", gap: "10px", margin: "20px 0" }}>
                  <FaPhone style={{ fontSize: "18px" }} />
                  <p>
                    Тел./Факс: <a href="">+7 (495) 648-64-62</a>
                  </p>
                </div>
                <div
                  style={{ display: "flex", gap: "10px", marginBottom: "20px" }}
                >
                  <MdLocationPin style={{ fontSize: "22px" }} />
                  <p>
                    {" "}
                    140030, Московская обл., Люберецкий р-н, пос. Малаховка,
                    Касимовское ш., дом.3Д/1
                  </p>
                </div>
              </div> */}
                </div>
                <div className="contact-input">
                  <form ref={form} onSubmit={sendEmail}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          border: "2px solid #005AAB",
                          height: "30px",
                          width: "5px",
                          background: "#005AAB",
                        }}
                      ></div>
                      <h3>НАПИШИТЕ НАМ:</h3>
                    </div>
                    <div>
                      <div className="contact-inputFlex">
                        <input
                          className="contact-inputFlexInput"
                          type="text"
                          name="user_name"
                          placeholder="Имя"
                          required
                        />
                        <input
                          className="contact-inputFlexInput"
                          type="tel"
                          name="user_phone"
                          required
                          placeholder="Номер телефона"
                        />
                      </div>
                      <div style={{ marginBottom: "20px" }}>
                        <input
                          name="user_email"
                          type="email"
                          placeholder="Email"
                        />
                      </div>
                      <textarea
                        className="inputTextarea"
                        name="message"
                        type="text"
                        placeholder="Сообщение"
                        required
                      />
                      <div className="button-contact">
                        <button
                          className="button"
                          type="submit"
                        >
                          Send
                        </button>
                        <span>{done && "Спасибо, что связались со мной"}</span>
                      </div>
                      <p>
                        Нажимая на кнопку "Отправить", вы даете согласие на{" "}
                        <a href="">обработку своих персональных данных</a>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
              <div>
                <iframe
                  style={{ width: "100%" }}
                  src="https://yandex.ru/map-widget/v1/?um=constructor%3A9f2db7db51364e77fdc752418637bf3672f0d625805f3532c3a69a105501a06b&amp;source=constructor"
                  width="600"
                  height="450"
                  frameborder="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
