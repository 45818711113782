import React from "react";
import { Link } from "react-router-dom";

const BenefitsAcid = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="mainImg">
            <img src="/img/kukuruza-ban.jpg" alt="" />
            <h3>
              Преимущества Ферментативного гидролиза по сравнению с кислотным
              для глубокой переработки кукурузы
            </h3>
          </div>
          <div className="absolute-industry">
            <h3 className="text__h3">
              Ферментативный гидролиз. Абсолютный приоритет в отрасли
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="absolute-industry-card">
              <div>
                <p>
                  В настоящий момент 90% предприятий отрасли используют
                  ферментативный гидролиз для глубокой переработки кукурузы и
                  только 10% _ кислотный гидролиз Лидеры рынка — Амилко,
                  Астон-АДМ, Гулькевичский КЗ, НьюБио и другие — используют
                  ферментативные технологии, являются нашими партнёрами и быстро
                  растут.
                </p>
                <div
                  style={{
                    border: "1.5px solid #48AA43",
                    background: "#48AA43",
                  }}
                ></div>
                <h4 className="text-h3">Ферментативный гидролиз позволяет:</h4>
                <ul>
                  <li>Значительно улучшить экономику предприятия</li>
                  <li>Диверсифицировать производство</li>
                  <li>
                    Снизить класс опасности при ужесточении экологического
                    законодательства;
                  </li>
                  <li>
                    Производить востребованные рынком продукты (мальтодекстрин,
                    «жидкий солод» и др.)
                  </li>
                  <li>Улучшить качество выпускаемой продукции</li>
                </ul>
              </div>
              <img src="/img/gidro-diag.jpg" alt="" />
            </div>
          </div>
          <div className="elementor-heading">
            <h3 className="text__h3">
              Чего можно добиться, применяя ферментные технологии
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="elementor-widget-wrap">
              <img src="/img/gidro-diag-two.jpg" alt="" />
              <ul>
                <li>
                  Увеличение выхода крахмала за счёт ферментативного выделения
                  до 70% связанного крахмала и улучшение вымывания свободного
                  крахмала
                </li>
                <li>Сокращение времени замочки до 8-10 часов</li>
                <li>
                  Возможность производить различные виды сиропов и расширить
                  рынок сбыта;
                </li>
                <li>
                  Исключение применения агрессивной химии (снижение класса
                  опасности производства);
                </li>
                <li>Снижение затрат на дополнительные химические реагенты;</li>
                <li>
                  Снижение затрат на фильтрацию и нагрузки на очистные
                  сооружения;
                </li>
                <li>
                  Снижение и стабилизация количества свободного крахмала в
                  мезге, подаваемую на сушилку. Решение проблемы с нагаром и
                  налипанием продукта в сушилке и транспортных коммуникациях;
                </li>
                <li>
                  Снижение влажности мезги на 6-10%. Таким образом при текущей
                  загрузке снижается энерго-нагрузка на пресса и сушилку, а при
                  полной загрузке повышается их производительность.
                </li>
                <li>
                  Возможное снижение потерь глютена, как конечного продукта.
                </li>
              </ul>
            </div>
          </div>
          <div className="elementor-heading">
            <h3 className="text__h3">Тренды</h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="elementor-widget-wrap">
              <ul>
                <li>
                  Крупные потребители сиропов покупают продукцию, произведенные
                  по их спецификации с определенным спектром сахаров;
                </li>
                <li>
                  Наиболее успешны те производства, которые могут мобильно
                  перестраивать технологию и увеличивать спектр выпускаемой
                  продукции;
                </li>
                <li>Ужесточение экологических требований государства;</li>
                <li>Снижение класса опасности;</li>
                <li>
                  Увеличение спроса и стоимости сиропов с улучшенными
                  качественными характеристиками (цветность, зольность).
                </li>
              </ul>
              <img src="/img/gidro-diag-tree.jpg" alt="" />
            </div>
          </div>
          <div className="elementor-heading">
            <h3 className="text__h3">
              Проблемы предприятий, использующих кислотный гидролиз
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="elementor-widget-wrap">
              <img src="/img/gidro-diag-for.jpg" alt="" />
              <ul>
                <li>
                  Ограниченные потребительские свойства и ассортимент
                  выпускаемых продуктов
                </li>
                <li>Ограниченный круг потребителей</li>
                <li>
                  Высокий класс опасности производства и риски , связанные с
                  ужесточением экологического законодательства
                </li>
                <li>Затраты на кислоту и её дальнейшую нейтрализацию;</li>
                <li>
                  Образование солей в процессе нейтрализации кислоты и
                  дополнительные затраты на очистку от них сиропа.
                </li>
              </ul>
            </div>
          </div>
          <div
            style={{ border: "1.5px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="bioTasksPharm">
            <h3 className="text__h3">
              ПОЛУЧИТЕ БИОТЕХНОЛОГИЧЕСКИЕ РЕШЕНИЯ ВАШИХ ЗАДАЧ!
            </h3>
            <div
              style={{ border: "1px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="li">
              <p>НАШИ КОМПЕТЕНЦИИ</p>
              <li>
                Разработка и освоение промышленных биотехнологий глубокой
                переработки сырья животного и растительного происхождения и
                производства продуктов для жизни с уникальными биологическими
                свойствами
              </li>
              <li>
                Производство, дистрибуция и применение ферментативного
                гидролизата белка в различных индустриях
              </li>
              <li>
                Проектирование и запуск производств глубокой переработки сырья,
                в том числе лицензионных, обеспечение трансфера технологии,
                особые условия приобретения оборудования и поставки необходимых
                ферментных препаратов
              </li>
            </div>
            <div
              style={{ border: "1px solid#067BC2", background: "#067BC2" }}
            ></div>
            <h4 className="text__h3">
              СВЯЖИТЕСЬ С НАМИ ЛЮБЫМ УДОБНЫМ ДЛЯ ВАС СПОСОБОМ
            </h4>
            <div className="bioTasksButton">
              <Link to="/contact">
                <button>Контакты</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenefitsAcid;
