import { Image } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const CardProtein = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="mainImg">
            <img src="/img/cardProtein.jpg" alt="" />
            <h3>
              Ферментативные гидролизаты белков универсальные биотехнологии
              производства
            </h3>
          </div>
          <div className="cardBiosolutions">
            <div className="cardTick">
              <img src="/img/golichka.png" alt="" />
              <p>Новые биорешения</p>
            </div>
            <div className="cardTitle">
              <p>
                Чем масштабнее кризис в Мире, тем быстрее и лучше у всех нас
                получается создавать и осваивать прорывное идеи, технологии и
                продукты
              </p>
            </div>
            <div className="cardsGrain">
              <div className="cardsCard">
                <img src="/img/ProteinCard1.jpg" alt="" />
                <div style={{ width: "220px" }}>
                  <p>
                    <h4>
                      Преобразующая переработку крахмала технологии Frontia,
                    </h4>
                    которая <h4>увеличивает выход крахмала,</h4>снижает
                    энергетические затраты предприятия , значительно улучшает
                    экономику предприятия
                  </p>
                  <h5>Frontia Fiberwash для кукурузы</h5>
                  <span>Frontia GlutenEx для пшеницы</span>
                </div>
              </div>
              <div className="cardsCard">
                <img src="/img/ProteinCard2.jpg" alt="" />
                <p>
                  Технология производства биодоступного и гипоаллерегенного
                  <h4>ферментативного гидролизата глютена,</h4>
                  необходимого для{" "}
                  <h4>
                    повышения эффективности процесса Ферментации и многих других
                    целей
                  </h4>
                </p>
              </div>
              <div className="cardsCard">
                <img src="/img/ProteinCard3.jpg" alt="" />
                <p>
                  Преимущества Ферментативного гидролиза по сравнению с
                  кислотным для глубокой переработке кукурузы
                </p>
              </div>
              <div className="cardsCard">
                <img src="/img/ProteinCard4.jpg" alt="" />
                <p>
                  <h4>Ферментация</h4>
                  Производство{" "}
                  <h4>аминокислот, органических кислот и витаминов -</h4>
                  Основное развитие проекта глубокой переработки зерна
                </p>
              </div>
            </div>
          </div>
          <div className="healthPage">
            <div className="healthFlexPage">
              <div>
                <p style={{ margin: "0 0 20px 0" }}>
                  Биотехнологии UZBIOPRODUCT и технологические линии для
                  производства ферментативных гидролизатов белка универсальны и
                  позволяют производить широкую гамму гидролизатов с заданными
                  свойствами из различных видов сырья, в частности:
                </p>
                <div
                  style={{
                    border: "1.5px solid #48AA43",
                    background: "#48AA43",
                  }}
                ></div>
                <ul>
                  <li>Гидролизаты пшеничного глютена</li>
                  <li>
                    Гидролизаты куриного, индюшиного и мясного белков, а также
                    коллагена
                  </li>
                  <li>Гидролизаты соевого белка</li>
                  <li>Гидролизаты молочных и сывороточных белков</li>
                  <li>Гидролизаты рыбного белка</li>
                  <li>Гидролизаты гороха</li>
                  <li>Печёночные гидролизаты</li>
                </ul>
                <div className="healthPageCard" style={{ maxWidth: "550px" }}>
                  <p>
                    Биологические свойства гидролизатов белка по важнейшим
                    показателям превосходят свойства белков в нативной форме и,
                    более того, во многих применениях
                    <span> ГИДРОЛИЗАТЫ НЕЗАМЕНИМЫ</span>
                  </p>
                </div>
              </div>
              <div>
                <img src="/img/health.jpg" alt="" />
                <h4 className="text-h3" style={{ margin: "0 0 11px 0" }}>
                  КЛЮЧЕВЫЕ СВОЙСТВА
                </h4>
                <div
                  style={{
                    border: "1.5px solid #48AA43",
                    background: "#48AA43",
                  }}
                ></div>

                <ul>
                  <li>100% натуральные белки</li>
                  <li>Гипоаллергенны</li>
                  <li>
                    Обладают высокой биологической ценностью и биодоступностью
                  </li>
                  <li>
                    Характеризуются высоким содержанием незаменимых аминокислот,
                    пептидов и важнейших макроэлементов, а также низким
                    содержанием жира
                  </li>
                  <li>
                    Используются для улучшения потребительских свойств
                    (вкусоароматика, структура, и др.) и биологической ценности
                    конечных продуктов
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="proteinTechnological">
            <img src="/img/company2.jpg" alt="" />
            <p>
              Биотехнологии и технологические линии , поставляемые UZBIOPRODUCT
              , универсальны и используются для индустриального производства
              широкой гаммы гидролизатов белка с заданными свойствами из
              различных видов сырья
            </p>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="aminoAcids">
            <h3 className="text-h3">
              ОСНОВНЫЕ ПРЕИМУЩЕСТВА ПРОЦЕССА ФЕРМЕНТАТИВНОГО ГИДРОЛИЗА БЕЛКА
              ОТНОСИТЕЛЬНО ДРУГИХ СПОСОБОВ ГИДРОЛИЗА
            </h3>
            <div
              style={{ border: "1.5px solid #005AAB", background: "#005AAB" }}
            ></div>
            <div className="aminoAcidsCard">
              <div>
                <img src="/img/gidroliz1.jpg" alt="" />
                <p>
                  Белоксодержащее сырье животного и растительного происхождения
                </p>
              </div>
              <div>
                <p>ФЕРМЕНТАТИВНЫЙ ГИДРОЛИЗ</p>
                <img src="/img/gidroliz2.jpg" alt="" />
              </div>
              <div>
                <img src="/img/gidroliz3.jpg" alt="" />
                <p>Пептиды</p>
              </div>
              <div>
                <img src="/img/gidroliz4.jpg" alt="" />
                <p>Аминокислоты</p>
              </div>
              <div>
                <li>Продукты питания</li>
                <li>Корма для животных. Аквакультуры</li>
                <li>
                  Питательные среды для микроорганизмов, обладающие высокими
                  биологическими свойствами
                </li>
              </div>
            </div>
          </div>
          <div
            style={{ border: "1px solid #48AA43", background: "#48AA43" }}
          ></div>
          <div className="ul">
            <li>
              Процесс гидролиза белка проводится в мягких условиях: при
              температуре 45-55 °С и pH 7-8
            </li>
            <li>Не происходит разрушения аминокислот</li>
            <li>Не происходит рацемизации аминокислот</li>
            <li>
              Процесс гидролиза является гибким и позволяет получать гидролизаты
              с разными функциональными и биологическими свойствами
            </li>
            <li>Низкие энергозатраты</li>
            <li>Высокая экологическая безопасность</li>
            <li>
              Не требуется дополнительная очистка гидролизатов от солей и
              примесей
            </li>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="biologicalPropertiesPage">
            <h3 className="text-h3">
              СОСТАВ И БИОЛОГИЧЕСКИЕ СВОЙСТВА ФЕРМЕНТАТИВНЫХ ГИДРОЛИЗАТОВ БЕЛКА
              (HP)
            </h3>
            <div
              style={{ border: "2px solid #F49F0A", background: "#F49F0A" }}
            ></div>

            <div className="bioPropertiesFlex">
              <div className="firstProperties">
                <p>Физико-химические параметры ферментированных гидролизатов</p>
                <ul>
                  <li>СВ ≥ 96%</li>
                  <li>Белок ≥ 85%</li>
                  <li>Жир ≤ 5%</li>
                  <li>Зола ≤ 7%</li>
                </ul>
              </div>
              <Image src="/img/jadval.jpg" />
            </div>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="industrialPage">
            <h3 className="texth3">
              ИНДУСТРИАЛЬНЫЕ ПРИМЕНЕНИЯ ФЕРМЕНТАТИВНЫХ ГИДРОЛИЗАТОВ БЕЛКА (HP)
            </h3>
            <div
              style={{ border: "1px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="industrialCard">
              <p>
                Основные виды гидролизатов белка (HP), которые присутствуют на
                глобальном рынке
              </p>
            </div>
            {/* <div className="fermentTable">
              <table>
                <thead>
                  <tr>
                    <th>МОЛОЧНЫЕ</th>
                    <th>РАСТИТЕЛЬНЫЕ</th>
                    <th>МЯСНЫЕ/РЫБНЫЕ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Гидролизат казеина</td>
                    <td>Гидролизат глютена</td>
                    <td>Гидролизат куриного белка</td>
                  </tr>
                  <tr>
                    <td>Гидролизат сыворотки</td>
                    <td>Гидролизат соевого белка</td>
                    <td>Гидролизат коллагена</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Гидролизат горохового белка</td>
                    <td>Рыбный гидролизат</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>Печеночный гидролизат</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>Гидролизат кератина</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>Говяжий гидролизат</td>
                  </tr>
                </tbody>
              </table>
            </div> */}
            <p>
              Наибольшие распространение и применения пока что получили
              Гидролизаты животных белков — молочных и мясных/рыбных. Однако в
              последние годы и Гидролизаты белков растительного происхождения
              находят все большее своё применение в различных индустриях в связи
              с развитием рынка вегетарианских и веганских продуктов и напитков,
              а также из-за расширения ограничений, связанными с этическими
              нормами применения животных белков для производства отдельных
              видов продукции.
            </p>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="naturalProteinExtracts">
            <h3 className="texth3">НАТУРАЛЬНЫЕ ЭКСТРАКТЫ БЕЛКОВ</h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <p>
              Натуральные экстракты животных и рыбных белков представляют собой{" "}
              <span>натуральный амино-пептидный комплекс </span>и является
              продуктом глубокой переработки пищевого сырья{" "}
              <span>на основе ферментативного гидролиза белка.</span>
            </p>
            <div className="proteinExtractsCardsOne">
              <img src="/img/extract-1.jpg" alt="" />
              <img src="/img/extract-2.jpg" alt="" />
              <ul>
                <h5>КЛЮЧЕВЫЕ СВОЙСТВА:</h5>
                <li>
                  Высокое содержание свободных аминокислот, коротких и средних
                  пептидов
                </li>
                <li>Полноценный аминокислотный состав</li>
                <li>Гипоаллергенность</li>
                <li>
                  Высокая биологическая ценность и биодоступность (высокая
                  степень усвоения)
                </li>
                <li>Низкое содержание жира</li>
                <li>Водорастворимость</li>
              </ul>
            </div>
            <div className="proteinExtractsCardsTwo">
              <h5>
                Эта Биотехнология - наиболее эффективный способ переработки
                малоценного сырья в продукты с высокой биологической ценностью.
              </h5>
              <h6>
                Для получения гидролизатов (экстрактов) рыбного белка непищевого
                стандарта могут использоваться и другие виды сырья (продукты
                потрошения рыбы)
              </h6>
            </div>
          </div>
          <div className="universalProcessLines">
            <h3 className="texth3">
              УНИВЕРСАЛЬНЫЕ ТЕХНОЛОГИЧЕСКИЕ ЛИНИИ / ОСНОВНЫЕ СЕКЦИИ
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <p>
              Наши специалисты, совместно с ведущими российскими и европейскими
              инжиниринговыми и технологическими компаниями, принимают участие в
              проектировании биотехнологических производств и оснащают их
              оборудованием{" "}
              <span>
                ведущих европейских производителей: CFT Group (Италия), ICF &
                Welko (Италия), GEA Group, Flottweg (Германия), Bertsch-Laska
                (Австрия) и других.
              </span>
            </p>
            <div className="universalProcessLinesCards">
              <div>
                <img
                  style={{ width: "100%", height: "303px" }}
                  src="/img/fergd10.jpg"
                  alt=""
                />
                <li>Отделение подготовки и измельчениях сырья</li>
              </div>
              <div>
                <img
                  style={{ width: "100%", height: "303px" }}
                  src="/img/fergd11.jpg"
                  alt=""
                />
                <li>Секция подготовки ферментов</li>
              </div>
              <div>
                <img
                  style={{ width: "100%", height: "303px" }}
                  src="/img/fergd12.jpg"
                  alt=""
                />
                <li>Секция ферментативного гидролиза</li>
              </div>

              <div>
                <img
                  style={{ width: "100%", height: "303px" }}
                  src="/img/fergd-14.jpg"
                  alt=""
                />
                <li>Секция микро-, ультра- нанофильтрации</li>
              </div>
              <div>
                <img
                  style={{ width: "100%", height: "303px" }}
                  src="/img/fergd15.jpg"
                  alt=""
                />
                <li>Секция концентрирования выпариванием</li>
              </div>
              <div>
                <img
                  style={{ width: "100%", height: "303px" }}
                  src="/img/fergd16.jpg"
                  alt=""
                />
                <li>Секция сушки</li>
              </div>
              <div>
                <img
                  style={{ width: "100%", height: "303px" }}
                  src="/img/fergd17.jpg"
                  alt=""
                />
                <li>Секция упаковки для любых жидких и сухих форм продуктов</li>
              </div>
              <div>
                <img
                  style={{ width: "100%", height: "303px" }}
                  src="/img/fergd31.jpg"
                  alt=""
                />
                <li>Секция очистки продукта</li>
              </div>
            </div>
          </div>
          <div className="proteasePprtfolio">
            <h3 className="text-h3">
              ПОРТФЕЛЬ ПРОТЕАЗ КОМПАНИИ НОВОЗАЙМС (ДАНИЯ)
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <h3 className="text-h3">рекомендуемые области применения</h3>
            <h5>
              Эндопротеазы:{" "}
              <b>
                Alcalase, Neutrase, Protamex, Novo-Pro D <br />
              </b>
              Комплекс эндопротеазы и экзопептидазы: <b> Flavourzyme</b>
            </h5>
            <Image
              className="proteasePprtfolioImg"
              src="/img/flavourzyme.jpg"
            />
          </div>
          <div
            style={{ border: "1.5px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="bioTasksPharm">
            <h3 className="texth3">
              ПОЛУЧИТЕ БИОТЕХНОЛОГИЧЕСКИЕ РЕШЕНИЯ ВАШИХ ЗАДАЧ!
            </h3>
            <div
              style={{ border: "1px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="li">
              <p>НАШИ КОМПЕТЕНЦИИ</p>
              <li>
                Разработка и освоение промышленных биотехнологий глубокой
                переработки сырья животного и растительного происхождения и
                производства продуктов для жизни с уникальными биологическими
                свойствами
              </li>
              <li>
                Производство, дистрибуция и применение ферментативного
                гидролизата белка в различных индустриях
              </li>
              <li>
                Проектирование и запуск производств глубокой переработки сырья,
                в том числе лицензионных, обеспечение трансфера технологии,
                особые условия приобретения оборудования и поставки необходимых
                ферментных препаратов
              </li>
            </div>
            <div
              style={{ border: "1px solid#067BC2", background: "#067BC2" }}
            ></div>
            <h4 className="texth3">
              СВЯЖИТЕСЬ С НАМИ ЛЮБЫМ УДОБНЫМ ДЛЯ ВАС СПОСОБОМ
            </h4>
            <div className="bioTasksButton">
              <Link to="/contact">
                <button>Контакты</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardProtein;
