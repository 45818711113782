import { Image } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const CardBioXim = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="mainImg">
            <img src="/img/bioXimiya.jpg" alt="" />
            <h3>БИОЛОГИЗАЦИЯ ХИМИИ</h3>
          </div>
          <div className="biotechnologChemical">
            <h3 className="texth3">
              БИОТЕХНОЛОГИИ ДЛЯ ХИМИЧЕСКОЙ ПРОМЫШЛЕННОСТИ
            </h3>
            <div
              style={{ border: "1.5px solid #48AA43", background: "#48AA43" }}
            ></div>
            <div className="biotechnologChemicalCard">
              <div>
                <img src="/img/bio-picc.jpg" alt="" />
                <p>
                  Стремительное развитие биотехнологий для химической
                  промышленности. , то есть процессов получения из
                  возобновляемого сырья известных или новых химических продуктов
                  в соответствии с принципами, свойственными живой природе,
                  стало одним ключевых трендов мировой химической индустрии
                </p>
              </div>
              <div>
                <h5>
                  К продуктам Биотехнологий промышленного назначения относятся
                  такие крупнотоннажные базовые химикаты, как органические
                  кислоты и их производные, спирты, алкены, гликоли,
                  биокатализаторы и промышленные ферменты, реагенты для
                  производства целлюлозно- бумажной продукции и многие другие
                </h5>
                <h6>
                  В России производства
                  <span>
                    {" "}
                    химических веществ из возобновляемых источников сырья,
                    основанные на современных передовых технологиях,
                  </span>{" "}
                  в настоящее время не существуют
                </h6>
                <h5>
                  Мировой объем производства химических веществ из
                  возобновляемых источников сырья превысил в 2020 году 86 млрд
                  долларов США. Эксперты прогнозируют, что к 2025 году объем
                  производства химических веществ из возобновляемых источников
                  сырья займет 15 – 20 % от мирового производства
                </h5>
              </div>
            </div>
          </div>
          <div className="bioChemicalIndustry">
            <h3 className="text__h3">
              Биотехнологическая переработка растительных ресурсов для
              химической промышленности
            </h3>
            <p>
              Три основных вида растительности, образующие ежегодно около 2000
              млрд. тонн сухого органического вещества, – это водоросли, леса и
              сельскохозяйственные культуры. Добыча минеральных органических
              веществ приближается к 2 млрд. тонн в год, что составляет около 1
              % от ежегодно синтезируемой биомассы.
            </p>
            <Image src="/img/shema-him.jpg" />
          </div>
          <div className="complexMoleculesIndustry">
            <h3 className="texth3">
              ФЕРМЕНТАЦИЯ – ОСНОВА ИНДУСТРИИ СЛОЖНЫХ МОЛЕКУЛ
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <h4>
              В ноябре 2020 года всемирный экономический форум назвал
              ферментацию ключевой областью инноваций в мире
            </h4>
            <div className="complexMoleculesIndustryCards">
              <img src="/img/ferment-pic.jpg" alt="" />
              <div>
                <h5>
                  <span>Ферментация уже сегодня </span>
                  позволяет использовать микроорганизмы как биофабрики для
                  производства с высокой скоростью, в больших объемах и по
                  низким ценам практически любых сложных органических молекул, в
                  частности:
                </h5>
                <ul>
                  <li>белков, жиров и сахаров</li>
                  <li>аминокислот и органических кислот</li>
                  <li>ферментов</li>
                  <li>витаминов, вакцин и антибиотиков</li>
                  <li>биопластиков</li>
                  <li>микробиологических удобрений</li>
                  <li>
                    биопродуктов для очистки сточных вод и многих других
                    продуктов
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="fermentationAminoacids">
            <h3 className="texth3">
              ФЕРМЕНТАЦИЯ. ПРОИЗВОДСТВО АМИНОКИСЛОТ И ОРГАНИЧЕСКИХ КИСЛОТ
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="fermentationAminoacidsImg">
              <Image src="/img/fermenty-pic1.jpg" />
              <Image src="/img/fermenty-pic2.jpg" />
            </div>
            <div className="fermentationAminoacidsText">
              <p>
                Ферментация (брожение) играет ключевую роль в развитии проекта
                глубокой переработки зерна и значительно повышает его
                экономическую эффективность.
              </p>
              <h6>
                Глюкоза, мальтоза, фруктоза и др., а также Ферментативные
                Гидролизаты глютена являются основой питательных сред для
                различных микроорганизмов / бактерий, которые и «производят»
                аминокислоты, органические кислоты и другие полезные и ликвидные
                продукты
              </h6>
            </div>
          </div>
          <div className="biotechnologicalPage">
            <h3 className="texth3">
              Эффективность биотехнологических решений для химической
              промышленности. Производство молочной и лимонной кислот
            </h3>
            <div
              style={{ border: "1px solid #48AA43", background: "#48AA43" }}
            ></div>
            <Image src="/img/rosteffek.jpg" alt="" />
          </div>
          <div className="productionOfVitamins">
            <h3 className="text-h3">ПРОИЗВОДСТВО ВИТАМИНОВ</h3>
            <div
              style={{ border: "1.5px solid #48AA43", background: "#48AA43" }}
            ></div>
            <div className="productionOfVitaminsCard">
              <div>
                <img src="/img/vitamin.png" alt="" />
                <h6>Витамин B2 — Рибофлавин</h6>
              </div>
              <div>
                <p>
                  <span>Витамины </span> — это низкомолекулярные органические
                  вещества, способные в очень низких концентрациях оказывать
                  сильное и разнообразное воздействие на живые организмы. Они
                  принимают активное участие в метаболизме человека и высших
                  животных, оказывая влияние на различные физиологические
                  процессы
                </p>
                <h4>
                  Объем производства витаминов
                  <span>
                    {" "}
                    для животноводства в России в 2019 г. составляет
                  </span>{" "}
                  не более 15% от общей потребности
                </h4>
              </div>
              <div>
                <h2>
                  Микробиологическим путем получают витамин В12, витамин В2 –
                  рибофлавин, а также эргостерин и каротин, и многие другие
                </h2>
                <h6>
                  Согласно расчетам аналитиков DISCOVERY Research Group, объем
                  рынка витаминов для животноводства в России в 2019 г. составил
                  11 000 тонн. В структуре рынка в 2019 г. в России наибольшую
                  долю витаминов для животных занимала категория для с/х
                  животных и птиц
                </h6>
              </div>
            </div>
          </div>
          <div className="biodegradablePolymers">
            <h3 className="text-h3">БИОРАЗЛАГАЕМЫЕ ПОЛИМЕРЫ</h3>
            <div
              style={{ border: "1.5px solid #48AA43", background: "#48AA43" }}
            ></div>
            <div className="biodegradablePolymersCard">
              <Image
                className="biodegradablePolymersImg"
                src="/img/ferment-pishema.jpg"
              />
              <div>
                <p>
                  Сегодня доля биополимеров в мировом рынке пластика составляет
                  примерно 1 %, однако, согласно оценкам экспертов производство
                  биополимеров из растительной биомассы к 2030 году должно
                  достигнуть 20% и превысить 30 млн. тонн в год.
                </p>
                <h5>
                  Промышленных масштабов достигли производства биополимеров на
                  основе крахмала, полимолочной кислоты, алкидных смол и
                  целлюлозы со 100 %-м содержанием биокомпонентов.
                </h5>
                <h6>
                  Основные усилия в данном направлении сосредоточены в сфере
                  придания свойств биоразлагаемости промышленным полимерам:
                  полиэтилену, полипропилену , полистиролу,
                  полиэтилентерефталату и др
                </h6>
              </div>
            </div>
          </div>
          <div className="fermentationIndustry">
            <h3 className="text-h3">
              ФЕРМЕНТАЦИЯ – ОСНОВА ИНДУСТРИИ <br /> АЛЬТЕРНАТИВНЫХ БЕЛКОВ И
              КУЛЬТИВИРУЕМЫХ ПРОДУКТОВ
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="fermentationIndustryCard">
              <div>
                <img src="/img/fermentationIndustry.jpg" alt="" />
                <p>
                  Ферментация позволит производить любую еду без участия
                  животных и рыб
                </p>
              </div>
              <div>
                <h4 className="text_h3">
                  Ферментация превратилась в мощную технологическую платформу
                  для внедрения альтернативных белковых и культивируемых
                  продуктов на рынок, сочетая в себе:
                </h4>
                <ul>
                  <li>опыт традиционной ферментации пищевых продуктов,</li>
                  <li>уроки масштабирования , извлеченные из биотоплива,</li>
                  <li>
                    точность, впервые примененную в биофармацевтических
                    препаратах,
                  </li>
                  <li>
                    и большой успех животных продуктов на растительной основе,
                  </li>
                </ul>
                <h5>
                  В последние годы быстро развиваются такие применения, как
                  культивирование мясных и молочных продуктов.
                </h5>
                <h4 className="text_h3">
                  Согласно прогнозам цена культивированного мяса к 2030 году
                  будет в 5 раз, а в 2035 году – в 10 раз меньше цены обычного
                  мяса.
                </h4>
              </div>
            </div>
          </div>
          <div className="nutreitMedia">
            <h3 className="text-h3">ПИТАТЕЛЬНЫЕ СРЕДЫ</h3>
            <div
              style={{ border: "1.5px solid #F49F0A", background: "#F49F0A" }}
            ></div>
            <p>
              Эффективность ферментации в большой степени зависит от применяемых
              питательных сред
            </p>
            <div className="nutreitMediaCard">
              <img src="/img/hcp-g7-1.jpg" alt="" />
              <div>
                <h5>
                  Питательные среды должны содержать{" "}
                  <span>в легко усваиваемом виде все вещества,</span>в первую
                  очередь,<span>источники азота и углерода,</span>необходимые
                  для удовлетворения пищевых и энергетических потребностей
                  микроорганизмов. При культивировании многих микроорганизмов в
                  среды вносят факторы роста — витамины, некоторые аминокислоты,
                  которые клетка не может синтезировать
                </h5>
                <h6>
                  Продукты глубокой переработки зерна и других растительных и
                  животных ресурсов, прежде всего,{" "}
                  <span>
                    глюкоза, мальтоза, фруктоза и др моносахара, а также,
                    аминокислоты и/ или гидролизаты растительных и животных
                    белков,
                  </span>
                  являются <span>ключевыми ингредиентами питательных сред</span>
                  для выращивании микроорганизмов — производителей полезных
                  продуктов.
                </h6>
              </div>
            </div>
          </div>
          <div className="grainProcessingPage">
            <h3 className="text-h3">ГЛУБОКАЯ ПЕРЕРАБОТКА ЗЕРНА</h3>
            <div
              style={{ border: "1.5px solid #067BC2", background: "#067BC2" }}
            ></div>
            <div className="grainProcessingPageCards">
              <div>
                <div className="grainProcessingPageCard">
                  <p>
                    Глубокая переработка зерна ( ГПЗ ) и других растительные
                    ресурсов, обеспечивающая производство основных компонентов
                    питательных сред, основа успешного развития производств
                    продуктов ферментации.
                  </p>
                  <p>
                    В 2021 году действующие и готовящиеся к запуску в РФ
                    мощности ГПЗ и переработчиков крахмала, производящих
                    основные компоненты питательных сред достаточны для
                    обеспечения производств продуктов Ферментации
                  </p>
                </div>
                <h5>
                  Основные предприятия ГПЗ и крахмалопаточной индустрии являются
                  партнерами UZBIOPRODUCT,
                </h5>
                <h6>
                  осуществляющего трансфер биотехнологических решений,
                  обеспечение ферментными комплексами и непрерывный
                  технологический аудит
                </h6>
              </div>
              <img src="/img/tablegz.jpg" alt="" />
            </div>
          </div>
          <div className="enzymativeProtein">
            <h3 className="text-h3">ФЕРМЕНТАТИВНЫЕ ГИДРОЛИЗАТЫ БЕЛКОВ</h3>
            <div
              style={{ border: "1.5px solid #F49F0A", background: "#F49F0A" }}
            ></div>
            <div className="enzymativeProteinCard">
              <div>
                <p>
                  Ферментативные гидролизаты растительных и животных белков
                  является продуктами глубокого расщепления белка с
                  использованием протеаз
                </p>
                <h4 className="text-h3">
                  Основные Гидролизаты Белков на рынке:
                </h4>
                <ul>
                  <li>
                    Гидролизаты куриного, индюшиного и мясного белков, а также
                    коллагена
                  </li>
                  <li>Гидролизаты соевого белка</li>
                  <li>Гидролизаты молочных и сывороточных белков</li>
                  <li>Гидролизаты рыбного белка</li>
                  <li>Гидролизаты гороха</li>
                  <li>Гидролизаты глютена</li>
                  <li>Печёночные гидролизаты</li>
                </ul>
                <h6>
                  Во многих применениях , включая процессы Ферментации ,
                  Гидролизаты Белков незаменимы!
                </h6>
              </div>
              <div>
                <img src="/img/kolbabelok.jpg" alt="" />
                <h4 className="text-h3">КЛЮЧЕВЫЕ СВОЙСТВА</h4>
                <div
                  style={{
                    border: "1.5px solid #48AA43",
                    background: "#48AA43",
                  }}
                ></div>
                <ul>
                  <li>100% натуральные белки</li>
                  <li>Гипоаллергенны</li>
                  <li>
                    Обладают высокой биологической ценностью и биодоступностью
                  </li>
                  <li>
                    Характеризуются высоким содержанием незаменимых свободных
                    аминокислот и коротких пептидов и важнейших макроэлементов,
                    а также низким содержанием жира
                  </li>
                  <li>
                    Используются для улучшения потребительских свойств
                    (вкусоароматика, структура, и др.) и биологической ценности
                    конечных продуктов
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="engineeringEquipment">
            <h3 className="texth3">ИНЖИНИРИНГ И ОБОРУДОВАНИЕ</h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="engineeringEquipmentCard">
              <img
                className="engineeringEquipmentCardImg"
                src="/img/EQUIPMENT1.jpg"
                alt=""
              />
              <div>
                <p>
                  Наши специалисты, совместно с ведущими российскими и
                  европейскими инжиниринговыми и технологическими компаниями,
                  проектируют биотехнологические производства, в частности,{" "}
                  <span>для всех описанных в данной презентации проектов,</span>
                  и оснащают их оборудованием ведущих европейских
                  производителей:{" "}
                  <span>
                    CFT Group, ICF & Welko (Италия), GEA Group, Flottweg
                    (Германия), Bertsch-Laska (Австрия) и других
                  </span>
                </p>
                <img src="/img/EQUIPMENT2.jpg" alt="" />
              </div>
            </div>
          </div>
          <div
            style={{ border: "1.5px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="bioTasksPharm">
            <h3 className="texth3">
              ПОЛУЧИТЕ БИОТЕХНОЛОГИЧЕСКИЕ РЕШЕНИЯ ВАШИХ ЗАДАЧ!
            </h3>
            <div
              style={{ border: "1px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="li">
              <p>НАШИ КОМПЕТЕНЦИИ</p>
              <li>
                Разработка и освоение промышленных биотехнологий глубокой
                переработки сырья животного и растительного происхождения и
                производства продуктов для жизни с уникальными биологическими
                свойствами
              </li>
              <li>
                Производство, дистрибуция и применение ферментативного
                гидролизата белка в различных индустриях
              </li>
              <li>
                Проектирование и запуск производств глубокой переработки сырья,
                в том числе лицензионных, обеспечение трансфера технологии,
                особые условия приобретения оборудования и поставки необходимых
                ферментных препаратов
              </li>
            </div>
            <div
              style={{ border: "1px solid#067BC2", background: "#067BC2" }}
            ></div>
            <h4 className="text__h3">
              СВЯЖИТЕСЬ С НАМИ ЛЮБЫМ УДОБНЫМ ДЛЯ ВАС СПОСОБОМ
            </h4>
            <div className="bioTasksButton">
              <Link to="/contact">
                <button>Контакты</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardBioXim;
