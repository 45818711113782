import React from "react";
import { Link } from "react-router-dom";

const Biosolutions = () => {
  return (
    <div className="technologyCard">
      <Link to="/frontia-fiberwash-dlya-kukuruzy/">
        <button> Frontia Fiberwash для кукурузы </button>
      </Link>
      <Link to="/frontia-glutenex-dlya-psheniczy/">
        <button>Frontia GlutenEx для пшеницы</button>
      </Link>
      <Link to="/preimushhestva-fermentativnogo-gidroliza-vs-kislotnogo/">
        <button>Преимущества ферментативного гидролиза vs кислотного</button>
      </Link>
      <Link to="/gidrolizaty-rastitelnyh-belkov/">
        <button>Гидролизаты растительных белков</button>
      </Link>
      <Link to="/gidrolizat-rybnogo-belka/">
        <button>Гидролизат рыбного белка</button>
      </Link>
      <Link to="/proizvodstvo-kostnyh-bulonov/">
        <button>Производство костных бульонов</button>
      </Link>
      <Link to="/naturalnye-ekstrakty-i-bulony/">
        <button> Натуральные экстракты и бульоны</button>
      </Link>
      <Link to="/pharmaceutical">
        <button>Производство фармсубстанций</button>
      </Link>
      <Link to="/foodproduction">
        <button>Производство продуктов питания</button>
      </Link>
      <Link to="/zernovye-osnovy-kormov/">
        <button>Зерновые основы кормов (ЗОК)</button>
      </Link>
      <Link to="/alternativnye-belki-i-kultiviruemye-produkty/">
        <button> Альтернативные белки и культивируемые продукты</button>
      </Link>
      <Link to="/aminokisloty-organicheskie-kisloty-i-vitaminy/">
        <button>Аминокислоты, органические кислоты и витамины</button>
      </Link>
    </div>
  );
};

export default Biosolutions;
