import { Image } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const CardGrain = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="mainImg">
            <img src="/img/grainCard.jpg" alt="" />
            <h3>Биотехнологии глубокой переработки зерна</h3>
          </div>
          <div className="cardBiosolutions">
            <div className="cardTick">
              <img src="/img/golichka.png" alt="" />
              <p>Новые биорешения</p>
            </div>
            <div className="cardTitle">
              <p>
              Чем масштабнее кризис в Мире, тем быстрее и лучше у всех нас
                получается создавать и осваивать прорывное идеи, технологии и
                продукты
              </p>
            </div>
            <div className="cardsGrain">
              <div className="cardsCard">
                <img src="/img/cards1.jpg" alt="" />

                <p>
                  <h4>
                    Преобразующая переработку крахмала технологии Frontia,
                  </h4>
                  которая <h4>увеличивает выход крахмала,</h4>снижает
                  энергетические затраты предприятия , значительно улучшает
                  экономику предприятия
                </p>
                <h5>Frontia Fiberwash для кукурузы</h5>
                <span>Frontia GlutenEx для пшеницы</span>
              </div>
              <div className="cardsCard">
                <img src="/img/cards2.jpg" alt="" />
                <p>
                  Технология производства биодоступного и гипоаллерегенного
                  <h4>ферментативного гидролизата глютена,</h4>
                  необходимого для{" "}
                  <h4>
                    повышения эффективности процесса Ферментации и многих других
                    целей
                  </h4>
                </p>
              </div>
              <div className="cardsCard">
                <img src="/img/cards3.jpg" alt="" />
                <p>
                  Преимущества Ферментативного гидролиза по сравнению с
                  кислотным для глубокой переработке кукурузы
                </p>
              </div>
              <div className="cardsCard">
                <img src="/img/cards4.jpg" alt="" />
                <p>
                  <h4>Ферментация</h4>
                  Производство{" "}
                  <h4>аминокислот, органических кислот и витаминов -</h4>
                  Основное развитие проекта глубокой переработки зерна
                </p>
              </div>
              <div className="cardsCard">
                <img src="/img/cards5.jpg" alt="" />
                <p>
                  Новая технология производства
                  <h4>
                    функциональных гипоаллергенных протеиновых напитков из
                    зерновых культур
                  </h4>
                </p>
              </div>
            </div>
            <div className="grainProcessing">
              <div className="grainCapability">
                <h3 className="text-h3">ГЛУБОКАЯ ПЕРЕРАБОТКА ЗЕРНА</h3>
                <div className="processinLine"></div>
                <div>
                  <h4>ВОЗМОЖНОСТИ</h4>
                  <p>
                    Глубокая переработка зерна — один из лучших, наиболее
                    рентабельных и многократно реализованных проектов в
                    агропромышленном комплексе
                  </p>
                  <div className="capabilityCard">
                    <p>
                      Проект сочетает в себе передовые биотехнологические
                      решения, достижения международных инжиниринговых компаний
                      и производителей оборудования, а также сильную рыночную
                      позицию всех выпускаемых продуктов
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <Image src="/img/zerna.jpg" />
              </div>
            </div>
            <h3 className="text-h3">
              ОСНОВНЫЕ ПРОДУКТЫ <br /> ГЛУБОКОЙ ПЕРЕРАБОТКИ ЗЕРНА
            </h3>
            <div className="tableLine"></div>
            <div className="mainGrainProducts">
              <div className="grainProductsImg">
                <div>
                  <img src="/img/zerna1.jpg" alt="" />
                </div>
                <div>
                  <img src="/img/zerna2.jpg" alt="" />
                </div>
                <div>
                  <img src="/img/zerna3.jpg" alt="" />
                </div>
              </div>
              <div>
                <table>
                  <tr>
                    <th>ПРОДУКТ</th>
                    <th>ОТРАСЛЬ ПРИМЕНЕНИЯ</th>
                  </tr>
                  <tr>
                    <td>Глютен (Клейковина)</td>
                    <td>Пищевая</td>
                  </tr>
                  <tr>
                    <td>Крахмал А</td>
                    <td>
                      Пищевая, текстильная, целлюлозно-бумажная,
                      фармацевтическая
                    </td>
                  </tr>
                  <tr>
                    <td>Лизин</td>
                    <td>Кормовая</td>
                  </tr>
                  <tr>
                    <td>DDGS</td>
                    <td>Кормовая</td>
                  </tr>
                  <tr>
                    <td>Лимонная кислота</td>
                    <td>Пищевая, фармацевтическая, косметическая</td>
                  </tr>
                  <tr>
                    <td>Сорбитол</td>
                    <td>Пищевая, косметическая</td>
                  </tr>
                  <tr>
                    <td>Моногидрат глюкозы</td>
                    <td>Пищевая, ветеринарная, фармацевтическая</td>
                  </tr>
                  <tr>
                    <td>Биопластики из молочной кислоты</td>
                    <td>Упаковка</td>
                  </tr>
                  <tr>
                    <td>Биоэтанол</td>
                    <td>Топливная</td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="tableHoney">
              <table>
                <tr>
                  <th>ПРОДУКТ (СИРОПЫ)</th>
                  <th>ПРИМЕНЕНИЕ</th>
                </tr>
                <tr>
                  <td>Мальтодекстрины: DE ниже 25</td>
                  <td>
                    Широкое использование в разных спектрах пищевой
                    промышленности включая пивоваренную, кондитерскую, мясную и
                    другие отрасли
                  </td>
                </tr>
                <tr>
                  <td>DE 28 - DE 35 Глюкозные сиропы</td>
                </tr>
                <tr>
                  <td>DE 42 Глюкозный сироп</td>
                </tr>
                <tr>
                  <td>DE 63 Глюкозный сироп</td>
                </tr>
                <tr>
                  <td>95+ DE Глюкозный сироп</td>
                </tr>
                <tr>
                  <td>Мальтозные и высокомальтозные сиропы</td>
                </tr>
                <tr>
                  <td>Гфс и фруктозные сиропы</td>
                </tr>
              </table>
              <img src="/img/asal.jpg" alt="" />
            </div>
            <div
              style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
            ></div>
            <div className="recyclingPage">
              <h3 className="texth3">
                ТИПОВОЙ МАТЕРИАЛЬНО – СЫРЬЕВОЙ БАЛАНС ЗАВОДА ПО ПЕРЕРАБОТКЕ
                ПШЕНИЦЫ
              </h3>
              <div
                style={{ border: "1px solid #48AA43", background: "#48AA43" }}
              ></div>
              <div className="recylingImage">
                <img src="/img/jpeg.jpg" alt="" />
                <Image src="/img/jpegright.jpg" />
              </div>
              <div className="recylingText">
                <div className="recylingTextRed">
                  <h4>ОБЪЕМ ИНВЕСТИЦИЙ</h4>
                  <p>Объект «ПОД КЛЮЧ» без учёта НДС — 100-150 млн. €</p>
                </div>
                <div className="recylingTextGreen">
                  <p>ОРИЕНТИРОВОЧНЫЙ СРОК</p>
                  <p>ОКУПАЕМОСТИ - 4 ГОДА</p>
                </div>
              </div>
            </div>
            <div
              style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
            ></div>
            <div className="balanceCorn">
              <h3 className="texth3">
                ТИПОВОЙ МАТЕРИАЛЬНО – СЫРЬЕВОЙ БАЛАНС ЗАВОДА ПО ПЕРЕРАБОТКЕ
                КУКУРУЗЫ
              </h3>
              <div
                style={{ border: "1px solid #48AA43", background: "#48AA43" }}
              ></div>
              <div className="recylingImage">
                <img src="/img/makka.jpg" alt="" />
                <Image src="/img/makkaright.jpg" />
              </div>
            </div>
          </div>
          <div className="sorbitolProduction">
            <h3 className="text-h3">ПРОИЗВОДСТВО СОРБИТОЛА</h3>
            <div style={{ border: "1px solid red", background: "red" }}></div>
            <div className="sorbitolCard">
              <h3>
                Сырьем для производства сорбитола технического, пищевого или
                фармацевтического класса в концентрированной форме служит
                глюкоза с установок мокрого помола. Внутри реактора раствор
                глюкозы гидролизуется С использованием ионообмена сырой раствор
                сорбитола затем очищается и выпаривается до конечной
                концентрации
              </h3>
            </div>
            <Image src="/img/sorbitol.jpg" alt="" />
          </div>
          <div className="biotechnologicalPage">
            <h3 className="texth3">
              Рост эффективности Производства в соотвествии с углублением
              биотехнологической переработки зерна
            </h3>
            <div
              style={{ border: "1px solid #48AA43", background: "#48AA43" }}
            ></div>
            <div className="biotechnologicalCard">
              <h3>
                Ориентировочная оценка роста добавленной стоимости при
                переработке 1 тн зерна (использован средний за последние пять
                лет уровень цен на внутреннем рынке)
              </h3>
            </div>
            <Image src="/img/rosteffek.jpg" alt="" />
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          {/* <div className="fermentPage">
            <h3 className="text-h3">
              ФЕРМЕНТНЫЕ ПРЕПАРАТЫ В ОСНОВНЫХ ПРОЦЕССАХ
            </h3>
            <div
              style={{ border: "1.5px solid #F49F0A", background: "#F49F0A" }}
            ></div>
            <h4>
              Специалисты нашей компании совместно с мировым лидером в области
              биотехнологий – компанией «Новозаймс» разрабатывают и внедряют
              передовые, экономически эффективные биотехнологические решения для
              глубокой переработки зерна
            </h4>
            <div className="fermentTable">
              <table>
                <tr>
                  <th>МОКРЫЙ ПРОЦЕСС</th>
                  <th>РАЗЖИЖЕНИЕ</th>
                  <th>ОСАХАРИВАНИЕ</th>
                  <th>ПРОИЗВОДСТВО МАЛЬТОЗЫ</th>
                </tr>
                <tr>
                  <td>Frontia Fiberwash</td>
                  <td>Liquozyme X 2X</td>
                  <td>Dextrozyme Go</td>
                  <td>Fungamyl 800 L</td>
                </tr>
                <tr>
                  <td>Frontia GlutenEX</td>
                  <td>LpHera</td>
                  <td>Extenda</td>
                  <td>Secura</td>
                </tr>
                <tr>
                  <td>Shearzyme Plus 2X</td>
                  <td>Termamyl 2X</td>
                </tr>
              </table>
              <table>
                <tr>
                  <th>СПЕЦИАЛЬНЫЕ ПРОДУКТЫ</th>
                  <th>ФИЛЬТРАЦИЯ</th>
                  <th>ИЗОМЕРИЗАЦИЯ</th>
                </tr>
                <tr>
                  <td>BAN 480 LS</td>
                  <td>Finizyme W 5X</td>
                  <td>Sweetzyme IT Extra</td>
                </tr>
                <tr>
                  <td>Promozyme D6</td>
                </tr>
              </table>
            </div>
          </div> */}
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="healthPage">
            <div className="healthFlexPage">
              <div>
                <h3 className="text-h3"> ФЕРМЕНТАТИВНЫЕ ГИДРОЛИЗАТЫ ГЛЮТЕНА</h3>
                <div
                  style={{
                    border: "1px solid #F49F0A",
                    background: "#F49F0A",
                    width: "77%",
                  }}
                ></div>
                <p>
                  Биотехнологии партнёров UZBIOPRODUCT и технологические линии для
                  производства ферментативных гидролизатов белка универсальны и
                  позволяют производить широкую гамму гидролизатов с заданными
                  свойствами из различных видов сырья, в частности:
                </p>

                <ul>
                  <li>Гидролизаты пшеничного глютена</li>
                  <li>
                    Гидролизаты куриного, индюшиного и мясного белков, а также
                    коллагена
                  </li>
                  <li>Гидролизаты соевого белка</li>
                  <li>Гидролизаты молочных и сывороточных белков</li>
                  <li>Гидролизаты рыбного белка</li>
                  <li>Гидролизаты гороха</li>
                  <li>Печёночные гидролизаты</li>
                </ul>
                <div className="healthUrlCard">
                  <p>
                    о гидролизатах белка, их производстве, свойствах и
                    применениях см. также на нашем сайте:
                  </p>
                  <a href="https://symbiotech.ru/fermentativnye-gidrolizaty-belkov/">
                    https://symbiotech.ru/fermentativnye-gidrolizaty-belkov/s
                  </a>
                </div>
              </div>
              <div>
                <img src="/img/health.jpg" alt="" />
                <div
                  style={{
                    border: "1.5px solid #48AA43",
                    background: "#48AA43",
                  }}
                ></div>
                <h4 className="text-h3">КЛЮЧЕВЫЕ СВОЙСТВА</h4>
                <ul>
                  <li>100% натуральные белки</li>
                  <li>Гипоаллергенны</li>
                  <li>
                    Обладают высокой биологической ценностью и биодоступностью
                  </li>
                  <li>
                    Характеризуются высоким содержанием незаменимых аминокислот,
                    пептидов и важнейших макроэлементов, а также низким
                    содержанием жира
                  </li>
                  <li>
                    Используются для улучшения потребительских свойств
                    (вкусоароматика, структура, и др.) и биологической ценности
                    конечных продуктов
                  </li>
                </ul>
              </div>
            </div>
            <div className="healthPageCard">
              <p>
                Свойства гидролизатов значительно превосходят свойства
                стандартных технологию ферментативного гидролиза глютена с целью
                получения гидролизатов, которые обладают уникальными
                биологическими и функциональными свойствами (подробнее см.) ниже
                в презентации ингредиентов и более того, во многих применениях
                <span> ГИДРОЛИЗАТЫ НЕЗАМЕНИМЫ</span>
              </p>
            </div>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="biologicalPropertiesPage">
            <h3 className="text-h3">
              СОСТАВ И БИОЛОГИЧЕСКИЕ СВОЙСТВА ФЕРМЕНТАТИВНЫХ ГИДРОЛИЗАТОВ
              ГЛЮТЕНА
            </h3>
            <div
              style={{ border: "2px solid #F49F0A", background: "#F49F0A" }}
            ></div>
            <p>
              Аминокислотный состав HWP сбалансирован в соответствии с
              рекомендациями ФАО / ВОЗ.
            </p>
            <h5>Подробнее см. в презентации на нашем сайте :</h5>
            <a href="https://uzbioproduct.uz/fermentation" target="_blank">
              https://uzbioproduct.uz/fermentation
            </a>
            <div className="bioPropertiesFlex">
              <div className="firstProperties">
                <p>Физико-химические параметры ферментированных гидролизатов</p>
                <ul>
                  <li>СВ ≥ 96%</li>
                  <li>Белок ≥ 85%</li>
                  <li>Жир ≤ 5%</li>
                  <li>Зола ≤ 7%</li>
                </ul>
              </div>
              <Image src="/img/jadval.jpg" />
            </div>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="aminoAcids">
            <h3 className="text-h3">
              ОСНОВНЫЕ ПРЕИМУЩЕСТВА ПРОЦЕССА ФЕРМЕНТАТИВНОГО ГИДРОЛИЗА БЕЛКА
              ОТНОСИТЕЛЬНО ДРУГИХ СПОСОБОВ ГИДРОЛИЗА
            </h3>
            <div
              style={{ border: "1.5px solid #005AAB", background: "#005AAB" }}
            ></div>
            <div className="aminoAcidsCard">
              <div>
                <img src="/img/gidroliz1.jpg" alt="" />
                <p>
                  Белоксодержащее сырье животного и растительного происхождения
                </p>
              </div>
              <div>
                <p>ФЕРМЕНТАТИВНЫЙ ГИДРОЛИЗ</p>
                <img src="/img/gidroliz2.jpg" alt="" />
              </div>
              <div>
                <img src="/img/gidroliz3.jpg" alt="" />
                <p>Пептиды</p>
              </div>
              <div>
                <img src="/img/gidroliz4.jpg" alt="" />
                <p>Аминокислоты</p>
              </div>
              <div>
                <li>Продукты питания</li>
                <li>Корма для животных. Аквакультуры</li>
                <li>
                  Питательные среды для микроорганизмов, обладающие высокими
                  биологическими свойствами
                </li>
              </div>
            </div>
          </div>
          <div
            style={{ border: "1px solid #48AA43", background: "#48AA43" }}
          ></div>
          <div className="ul">
            <li>
              Процесс гидролиза белка проводится в мягких условиях: при
              температуре 45-55 °С и pH 7-8
            </li>
            <li>Не происходит разрушения аминокислот</li>
            <li>Не происходит рацемизации аминокислот</li>
            <li>
              Процесс гидролиза является гибким и позволяет получать гидролизаты
              с разными функциональными и биологическими свойствами
            </li>
            <li>Низкие энергозатраты</li>
            <li>Высокая экологическая безопасность</li>
            <li>
              Не требуется дополнительная очистка гидролизатов от солей и
              примесей
            </li>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="glutenPage">
            <h3 className="text-h3">
              ПРИМЕНЕНИЯ ГИДРОЛИЗАТОВ ГЛЮТЕНА ( HWP) КЛЮЧЕВЫЕ РЫНКИ СБЫТА
            </h3>
            <div
              style={{ border: "1px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="glutenPageCard">
              <img src="/img/gluten1.jpg" alt="" />
              <p>
                Кормовые добавки, премиксы для с/х животных и птицы, в состав
                которых входит HWP,{" "}
                <span>
                  используются для производства престартерных и стартерных
                  кормов , а также жидких кормовых добавок. HWP значительно
                  улучшает кормовую и биологическую ценность готовых кормов
                </span>
              </p>
            </div>
            <div className="glutenPageCard">
              <img src="/img/glutrn2.jpg" alt="" />
              <p>
                Функциональные и специальные продукты питания HWP
                <span>
                  является белковым компонентом продуктов здорового,
                  функционального, спортивного, диетического и специального
                  питания. Полностью и легко усваивается организмом человека,
                  гипоаллергенен, Обладает высокой питательной и биологической
                  ценностью.
                </span>
              </p>
            </div>
            <div className="glutenPageCard">
              <img src="/img/gluten3.jpg" alt="" />
              <p>
                HWP
                <span>
                  используется в качестве белковых обогатителей и
                  вкусо-ароматических комплексов в рецептурах
                </span>
                <p>сухих и влажных кормов и лакомств для домашних питомцев.</p>
              </p>
            </div>
            <div className="glutenPageCard">
              <img src="/img/gluten4.jpg" alt="" />
              <p>
                Промышленные биотехнологии и микробиологи, HWP
                <span>
                  является компонентом промышленных питательных сред для
                  выращивании микроорганизмов, необходимых для производства
                  Пищевых и Кормовых пробиотиков, Вакцин, Микробиологических
                  удобрений, Деструкторов нефти, Диагностических сред, ферментов
                  и аминокислот. Обеспечивает замещение более дорогих импортных
                  пептонов.
                </span>
              </p>
            </div>
            <div className="glutenPageCard">
              <img src="/img/gluten5.jpg" alt="" />
              <p>
                Гидролизованный протеин пшеницы
                <span>
                  используют в следующих косметических продуктах для регулярного
                  ухода: в увлажняющих кремах, лосьонах, омолаживающих кремах
                  средствах по уходу за волосами (шампуни, бальзамы, маски), в
                  декоративной косметике (тональных кремах, пудре, губной
                  помаде), детской косметике, средствах для ухода за ногтями
                </span>
              </p>
            </div>
            <div className="floatRight">
              <h4>Развивающиеся высокомаржинальные рынки сбыта:</h4>
              <h5>Фармацевтика, БАДы и многие другие</h5>
            </div>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="bioTasks">
            <h3 className="text-h3">
              ПОЛУЧИТЕ БИОТЕХНОЛОГИЧЕСКИЕ РЕШЕНИЯ ВАШИХ ЗАДАЧ!
            </h3>
            <div
              style={{ border: "1px solid #48AA43", background: "#48AA43" }}
            ></div>
            <div className="li">
              <li>Технологический аудит</li>
              <li>Трансфер готовых биотехнологических решений</li>
              <li>
                Поставка ферментов и других биотехнологических инструментов
              </li>
              <li>
                Инжиниринг и особые условия поставки технологического
                оборудования
              </li>
              <li>Технологический сервис</li>
            </div>
            <div
              style={{ border: "1px solid#067BC2", background: "#067BC2" }}
            ></div>
            <h4 className="texth3">
              СВЯЖИТЕСЬ С НАМИ ЛЮБЫМ УДОБНЫМ ДЛЯ ВАС СПОСОБОМ
            </h4>
            <div className="bioTasksButton">
              <Link to="/contact">
                <button>Контакты</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardGrain;
