import React from "react";

const Footer = () => {
  return (
    <div>
        <div className="footer-line"></div>
        <div className="footer">PSS | Copyright © 2016 - 2022 UZBIOPRODUCT</div>
    </div>
  )
};

export default Footer;
