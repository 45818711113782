import React from "react";

const FooterOpen = () => {
  return (
    <div className="footerOpen">
      <a href="/">
        <div className="">
          <img src="/img/100.svg" />
        </div>
        <h3>UZBIOPRODUCT</h3>

        <img src="/img/logoheader.png" alt="" />
      </a>
    </div>
  );
};

export default FooterOpen;
