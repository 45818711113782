import React from "react";
import { Link } from "react-router-dom";

const AlternativeProteins = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="mainImg">
            <img src="/img/ferment-belki-ban.jpg" alt="" />
            <h3>
              ФЕРМЕНТАЦИЯ <br />
              Производство альтернативных белков и культивируемых продуктов
            </h3>
          </div>
          <div className="cultivatedFoodProteins">
            <h3 className="text_h3">
              ФЕРМЕНТАЦИЯ – ОСНОВА ИНДУСТРИИ <br />
              АЛЬТЕРНАТИВНЫХ БЕЛКОВ И КУЛЬТИВИРУЕМЫХ ПРОДУКТОВ
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="cultivatedFoodProteinsCards">
              <div>
                <img src="/img/cards4.jpg" alt="" />
                <h6>
                  Ферментация позволит производить любую еду без участия
                  животных и рыб
                </h6>
              </div>
              <div>
                <p>
                  Ферментация позволит производить любую еду без участия
                  животных и рыб Сочетая в себе опыт традиционной ферментации
                  пищевых продуктов, уроки масштабирования, извлеченные из
                  биотоплива, точность, впервые примененную в
                  биофармацевтических препаратах, и большой успех животных
                  продуктов на растительной основе,{" "}
                  <span>
                    ферментация превратилась в мощную технологическую платформу
                    для внедрения альтернативных белковых и культивируемых
                    продуктов на рынок.
                  </span>
                </p>
                <h5>
                  В последние годы быстро развиваются такие применения, как
                  культивирование мясных и молочных продуктов. Согласно
                  прогнозам цена культивированного мяса к 2030 году будет в 5
                  раз, а в 2035 году – в 10 раз меньше цены обычного мяса.
                </h5>
              </div>
            </div>
          </div>
          <div className="nutreitMedia">
            <h3 className="text-h3">ПИТАТЕЛЬНЫЕ СРЕДЫ</h3>
            <div
              style={{ border: "1.5px solid #F49F0A", background: "#F49F0A" }}
            ></div>
            <p>
              Эффективность ферментации в большой степени зависит от применяемых
              питательных сред
            </p>
            <div className="nutreitMediaCard">
              <div>
                <img src="/img/hcp-g7-1.jpg" alt="" />
                <h4>Партнёр UZBIOPRODUCT осуществляет:</h4>
                <ul>
                  <li>
                    трансфер биотехнологических решений предприятиям —
                    производителям глюкозы , мальтозы и др
                  </li>
                  <li>обеспечение их ферментными комплексами</li>
                  <li>
                    производство и поставки ферментативных гидролизатов белка
                  </li>
                  <li>непрерывный технологический аудит</li>
                </ul>
              </div>
              <div>
                <h5>
                  Питательные среды должны содержать{" "}
                  <span>в легко усваиваемом виде все вещества,</span>в первую
                  очередь,<span>источники азота и углерода,</span>необходимые
                  для удовлетворения пищевых и энергетических потребностей
                  микроорганизмов. При культивировании многих микроорганизмов в
                  среды вносят факторы роста — витамины, некоторые аминокислоты,
                  которые клетка не может синтезировать
                </h5>
                <h6>
                  Продукты глубокой переработки зерна и других растительных и
                  животных ресурсов, прежде всего,{" "}
                  <span>
                    глюкоза, мальтоза, фруктоза и др моносахара, а также,
                    аминокислоты и/ или гидролизаты растительных и животных
                    белков,
                  </span>
                  являются <span>ключевыми ингредиентами питательных сред</span>
                  для выращивании микроорганизмов — производителей полезных
                  продуктов.
                </h6>
              </div>
            </div>
          </div>
          <div className="grainProcessingPage">
            <h3 className="text-h3">ГЛУБОКАЯ ПЕРЕРАБОТКА ЗЕРНА</h3>
            <div
              style={{ border: "1.5px solid #067BC2", background: "#067BC2" }}
            ></div>
            <div className="grainProcessingPageCards">
              <div>
                <div className="grainProcessingPageCard">
                  <p>
                    Глубокая переработка зерна ( ГПЗ ) и других растительные
                    ресурсов, обеспечивающая производство основных компонентов
                    питательных сред, основа успешного развития производств
                    продуктов ферментации.
                  </p>
                  <p>
                    В 2021 году действующие и готовящиеся к запуску в РФ
                    мощности ГПЗ и переработчиков крахмала, производящих
                    основные компоненты питательных сред достаточны для
                    обеспечения производств продуктов Ферментации
                  </p>
                </div>
                <h5>
                  Основные предприятия ГПЗ и крахмалопаточной индустрии являются
                  партнерами UZBIOPRODUCT,
                </h5>
                <h6>
                  осуществляющего трансфер биотехнологических решений,
                  обеспечение ферментными комплексами и непрерывный
                  технологический аудит
                </h6>
              </div>
              <img src="/img/tablegz.jpg" alt="" />
            </div>
          </div>
          <div className="enzymativeProtein">
            <h3 className="text_h3">ФЕРМЕНТАТИВНЫЕ ГИДРОЛИЗАТЫ БЕЛКОВ</h3>
            <div
              style={{ border: "1.5px solid #F49F0A", background: "#F49F0A" }}
            ></div>
            <div className="enzymativeProteinCard">
              <div>
                <p>
                  Ферментативные гидролизаты растительных и животных белков
                  является продуктами глубокого расщепления белка с
                  использованием протеаз
                </p>
                <h4 className="text-h3">
                  Основные Гидролизаты Белков на рынке:
                </h4>
                <ul>
                  <li>
                    Гидролизаты куриного, индюшиного и мясного белков, а также
                    коллагена
                  </li>
                  <li>Гидролизаты соевого белка</li>
                  <li>Гидролизаты молочных и сывороточных белков</li>
                  <li>Гидролизаты рыбного белка</li>
                  <li>Гидролизаты гороха</li>
                  <li>Гидролизаты глютена</li>
                  <li>Печёночные гидролизаты</li>
                </ul>
                <h6>
                  Во многих применениях , включая процессы Ферментации ,
                  Гидролизаты Белков незаменимы!
                </h6>
              </div>
              <div>
                <img src="/img/kolbabelok.jpg" alt="" />
                <h4 className="text_h3">КЛЮЧЕВЫЕ СВОЙСТВА</h4>
                <div
                  style={{
                    border: "1.5px solid #48AA43",
                    background: "#48AA43",
                  }}
                ></div>
                <ul>
                  <li>100% натуральные белки</li>
                  <li>Гипоаллергенны</li>
                  <li>
                    Обладают высокой биологической ценностью и биодоступностью
                  </li>
                  <li>
                    Характеризуются высоким содержанием незаменимых свободных
                    аминокислот и коротких пептидов и важнейших макроэлементов,
                    а также низким содержанием жира
                  </li>
                  <li>
                    Используются для улучшения потребительских свойств
                    (вкусоароматика, структура, и др.) и биологической ценности
                    конечных продуктов
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="meatProduction">
            <div className="meatProductionCard">
              <img src="/img/myaso.jpg" alt="" />
              <div>
                <h3 className="text__h3">ПРОИЗВОДСТВО МЯСА</h3>
                <div
                  style={{
                    border: "1.5px solid #EE3A43",
                    background: "#EE3A43",
                  }}
                ></div>
                <p>
                  Производство культивируемого и растительного мяса - это
                  огромная рыночная возможность, поскольку филе, нарезки на
                  кости и ветчина являются наиболее прибыльным и наиболее
                  желательным сегментом мирового рынка мяса стоимостью 1,7
                  триллиона долларов.
                </p>
              </div>
            </div>
            <h5>В 2020 году:</h5>
            <ul>
              <li>
                Компания Meati Foods из Колорадо запустила в одном из ресторанов
                линию стейков и куриных продуктов, приготовленных методом
                глубокой ферментации.
              </li>
              <li>
                В конце 2020 года Prime Roots запустила в своем
                интернет-магазине бекон, курицу, свинину и говядину
              </li>
              <li>
                Planterra, мясная дочерняя компания JBS, крупнейшего в мире
                производителя мяса, представила свою первую линейку продуктов
                под брендом OZO, в состав которой входят гороховый и рисовый
                белок, ферментированный shiitake mycelia от MycoTechnology
              </li>
              <li>
                Impossible Foods, которая использует прецизионное брожение для
                производства своего фирменного ингредиента leghemoglobin, быстро
                расширила свою дистрибуцию в США, Сингапуре и Гонконге.
              </li>
              <li>
                Компания Quorn, пионер ферментации, представила курицу,
                содержащую микопротеины, в американской сети ресторанов Hooters
              </li>
            </ul>
          </div>
          <div className="dairyProduction">
            <h3 className="text__h3">ПРОИЗВОДСТВО МОЛОЧНЫХ ПРОДУКТОВ</h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <h4>
              Ферментация может полностью изменить производство молочных
              продуктов
            </h4>
            <div className="dairyProductionCard">
              <div>
                <p>
                  В 2020 году произошли заметные события в области производства
                  молочных продуктов, основанного на ферментации:
                </p>
                <ul>
                  <li>
                    Компания Moocho, запустила линейку безмолочных сырных кусков
                    на основе ферментированных культур и протеина бобов.
                  </li>
                  <li>
                    Perfect Day осуществила коммерческое производство и
                    внедрение в нескольких розничных сетях мороженого с
                    использованием рекомбинантного казеина и сывороточного
                    протеина
                  </li>
                </ul>
              </div>
              <img src="/img/grb-picС.jpg" alt="" />
            </div>
            <div className="dairyProductionCard">
              <img src="/img/yaoco.jpg" alt="" />
              <div>
                <p>ПРОИЗВОДСТВО ЗАМЕНИТЕЛЕЙ ЯИЦ</p>
                <div
                  style={{
                    border: "1.5px solid #EE3A43",
                    background: "#EE3A43",
                  }}
                ></div>
                <ul>
                  <li>
                    Компания Moocho, запустила линейку безмолочных сырных кусков
                    на основе ферментированных культур и протеина бобов.
                  </li>
                  <li>
                    Perfect Day осуществила коммерческое производство и
                    внедрение в нескольких розничных сетях мороженого с
                    использованием рекомбинантного казеина и сывороточного
                    протеина
                  </li>
                </ul>
              </div>
            </div>
            <div className="dairyProductionCard">
              <div>
                <p>ПРОИЗВОДСТВО ЖЕЛАТИНА</p>
                <div
                  style={{
                    border: "1.5px solid #EE3A43",
                    background: "#EE3A43",
                  }}
                ></div>
                <h6>
                  Желатин - это частично разрушенный белок коллагена, который
                  традиционно используется во многих пищевых и косметических
                  целях <br />
                  <br /> В 2020 году:
                </h6>
                <ul>
                  <li>
                    Компания Jellatech была создана для коммерциализации
                    ферментационного коллагена медуз для пищевых и косметических
                    целей
                  </li>
                  <li>
                    Компания Geltor разработала полностью биосовместимый
                    человеческий эластин на растительной основе для рынка
                    средств по уходу за кожей
                  </li>
                </ul>
              </div>
              <img src="/img/pirog.jpg" alt="" />
            </div>
          </div>
          <div className="seafoodProduction">
            <h3 className="text__h3">ПРОИЗВОДСТВО МОРЕПРОДУКТОВ</h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <h4>
              Ключом к открытию растительных и культивируемых морепродуктов
              является ферментация, в особенности с использованием
              микроводорослей.
            </h4>
            <div className="seafoodProductionImg">
              <img src="/img/moreproduct.jpg" alt="" />
            </div>
            <ul>
              <li>
                Odontella производит структурированный аналог лосося с
                использованием морских водорослей и микроводорослей
              </li>
              <li>
                Мити опубликовала в Instagram тизер по случаю Всемирного дня
                океанов: слоеная рыба на основе мицелия в корзине с рыбой и
                жареным картофелем
              </li>
              <li>
                3F Bio анонсировала прототип стейка из тунца с микопротеином,
                произведенного на 3D-принтере Natural Machines
              </li>
            </ul>
          </div>
          <div className="fatsAndOil">
            <h3 className="text__h3">ЖИРЫ И МАСЛА</h3>
            <div
              style={{ border: "1.5px solid #067BC2", background: "#067BC2" }}
            ></div>
            <div className="fatsAndOilCards">
              <img src="/img/masls.jpg" alt="" />
              <div>
                <p>
                  Жиры вносят ключевой вклад в сенсорные ощущения от
                  употребления мяса, яиц и молочных продуктов
                </p>
                <h6>
                  Используя микробную ферментацию, можно производить широкий
                  спектр жиров, включая жиры, которые имеют решающее значение
                  для питания, сенсорных характеристик или функциональности
                  продуктов животного происхождения. При этом жиры почти
                  невозможно получить из растений
                </h6>
                <h4>
                  Algarithm, DSM, iWi и Corbion, производят омега-3 из
                  водорослей и/или грибов.
                </h4>
              </div>
            </div>
          </div>
          <div className="hybridProductsProtein">
            <h3 className="text__h3">ГИБРИДНЫЕ ПРОДУКТЫ</h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="hybridProductsCards">
              <div>
                <p>
                  Гибридные продукты, содержащие белки, полученные путем
                  ферментации, а также компоненты растительных и животных
                  клеток, будут играть все более важную роль в выпуске
                  растительных , культивируемых и других альтернативных
                  продуктов
                </p>
                <h4>
                  Объединение всех альтернативных категорий белков, открывает
                  новую волну меняющих парадигму мясных, яичных и молочных
                  продуктов и ингредиентов, которые соответствуют ожиданиям
                  потребителей в отношении вкуса, цены, функциональности,
                  универсальности и разнообразия.
                </h4>
              </div>
              <img src="/img/hybridProducts.jpg" alt="" />
            </div>
          </div>
          <div className="goodFoodCards">
            <img src="/img/good-food.jpg" alt="" />
            <div>
              <h3 className="text__h3">
                В данной Презентации использованы материалы обзора :
              </h3>
              <div
                style={{ border: "1.5px solid #067BC2", background: "#067BC2" }}
              ></div>
              <p>
                Микробная ферментация в течение многих лет использовалась в
                пищевой промышленности для производства ингредиентов, таких как
                ферменты, и в биофармацевтической промышленности для
                производства вакцин и лекарств. Эта же технология сейчас
                применяется в индустрии альтернативных белков, чтобы создать
                новое поколение ингредиентов и продуктов с улучшенными
                сенсорными и функциональными характеристиками. Я считаю, что
                ферментация будет иметь ключевое значение для будущего
                альтернативных белков, и значительный рост отрасли в течение
                следующих 10 лет будет результатом инноваций в этом сегменте.{" "}
                <br />
                <br />
                Лидеры отрасли, похоже, тоже в это верят, поскольку такие
                компании, как AB InBev, DuPont и Danone, делают заметные
                инвестиции в эту сферу. <br />
                <br /> Caroline Bushnell, директор по корпоративным связям в The
                Good Food Institute
              </p>
            </div>
          </div>
          <div
            style={{ border: "1.5px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="bioTasksPharm">
            <h3 className="texth3">
              ПОЛУЧИТЕ БИОТЕХНОЛОГИЧЕСКИЕ РЕШЕНИЯ ВАШИХ ЗАДАЧ!
            </h3>
            <div
              style={{ border: "1px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="li">
              <p>НАШИ КОМПЕТЕНЦИИ</p>
              <li>
                Разработка и освоение промышленных биотехнологий глубокой
                переработки сырья животного и растительного происхождения и
                производства продуктов для жизни с уникальными биологическими
                свойствами
              </li>
              <li>
                Производство, дистрибуция и применение ферментативного
                гидролизата белка в различных индустриях
              </li>
              <li>
                Проектирование и запуск производств глубокой переработки сырья,
                в том числе лицензионных, обеспечение трансфера технологии,
                особые условия приобретения оборудования и поставки необходимых
                ферментных препаратов
              </li>
            </div>
            <div
              style={{ border: "1px solid#067BC2", background: "#067BC2" }}
            ></div>
            <h4 className="text__h3">
              СВЯЖИТЕСЬ С НАМИ ЛЮБЫМ УДОБНЫМ ДЛЯ ВАС СПОСОБОМ
            </h4>
            <div className="bioTasksButton">
              <Link to="/contact">
                <button>Контакты</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlternativeProteins;
