import React from "react";
import { Link } from "react-router-dom";

const Card = () => {
  return (
    <div className="relative1">
      <div className="card">
        <div className="card-text">
          <h2>Биотехнологические решения ваших задач</h2>
        </div>
        <div className="cards-card">
          <div className="cards-card__One">
            <img src="/img/card1.jpg" alt="" />
            <div className="card-bottom">
              <h4>Глубокая переработка зерна</h4>
              <Link to="/cardgrain">
                <button>Подробнее</button>
              </Link>
            </div>
          </div>
          <div className="cards-card__One">
            <img src="/img/card2.jpg" alt="" />
            <div className="card-bottom-one">
              <h4>Ферментативные гидролизаты белков</h4>
              <Link to="/cardprotein">
                <button>Подробнее</button>
              </Link>
            </div>
          </div>
          <div className="cards-card__One">
            <img src="/img/card3.jpg" alt="" />
            <div className="card-bottom">
              <h4>Производство спирта</h4>
              <Link to="/cardalcohol">
                <button>Подробнее</button>
              </Link>
            </div>
          </div>
          <div className="cards-card__One">
            <img src="/img/card4.jpg" alt="" />
            <div className="card-bottom-one">
              <h4>Производство фармсубстанции</h4>
              <Link to="/pharmaceutical ">
                <button>Подробнее</button>
              </Link>
            </div>
          </div>
          <div className="cards-card__One">
            <img src="/img/card5.jpg" alt="" />
            <div className="card-bottom">
              <h4>Гидролизаты белка для питательных сред</h4>
              <Link to="/proteinhydroly ">
                <button>Подробнее</button>
              </Link>
            </div>
          </div>
          <div className="cards-card__One">
            <img src="/img/card6.jpg" alt="" />
            <div className="card-bottom-one">
              <h4>Биорешения для премиксов и кормовых добавок</h4>
              <Link to="/zernovye-osnovy-kormov/ ">
                <button>Подробнее</button>
              </Link>
            </div>
          </div>
          <div className="cards-card__One">
            <img src="/img/card7.jpg" alt="" />
            <div className="card-bottom">
              <h4>Гидролизаты белка в кормах для домашних питомцев</h4>
              <Link to="/homeanimalfood ">
                <button>Подробнее</button>
              </Link>
            </div>
          </div>
          <div className="cards-card__One">
            <img src="/img/card8.jpg" alt="" />
            <div className="card-bottom-one">
              <h4>Производство напитков из зерновых культур</h4>
              <Link to="/beverageproduction ">
                <button>Подробнее</button>
              </Link>
            </div>
          </div>
          <div className="cards-card__One">
            <img src="/img/card9.jpg" alt="" />
            <div className="card-bottom">
              <h4>Производство продуктов питания</h4>
              <Link to="/foodproduction ">
                <button>Подробнее</button>
              </Link>
            </div>
          </div>
          <div className="cards-card__One">
            <img src="/img/card10.jpg" alt="" />
            <div className="card-bottom-one">
              <h4>Производство сока</h4>
              <Link to="/juiceproduction ">
                <button>Подробнее</button>
              </Link>
            </div>
          </div>
          <div className="cards-card__One">
            <img src="/img/card11.jpg" alt="" />
            <div className="card-bottom">
              <h4>Ферментация</h4>
              <Link to="/fermentation">
                <button>Подробнее</button>
              </Link>
            </div>
          </div>
          <div className="cards-card__One">
            <img src="/img/card5.jpg" alt="" />
            <div className="card-bottom-one">
              <h4>Биологизация химии</h4>
              <Link to="/bioxim">
                <button>Подробнее</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
