import React from "react";
import CardBioXim from "../../Component/technology/CardBioXim";

const GrainCardEnzymes = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="grainCardTable">
            <div className="grainBioXim">
              <CardBioXim />
            </div>
            <table>
              <tr>
                <td>Наименование ферментного препарата</td>
                <td>Применение</td>
                <td>Дозировка</td>
                <td>Фасовка</td>
              </tr>
              <tr className="tableCenter">
                <th></th>
                <th>
                  <center>МОКРЫЙ ПРОЦЕСС</center>
                </th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th>
                  <p> Shearzyme Plus 2X/Шеарзим Плюс 2Х</p>
                  <i>
                    Ксиланазная, целлюлолитическая и β-глюконазная активности
                  </i>
                </th>
                <th>
                  Эффективный препарат для разделения крахмала, глютена и
                  оболочки.
                </th>
                <th>0,05-0,10 кг/т муки</th>
                <th> 25 кг</th>
              </tr>
              <tr className="tableCenter">
                <th></th>
                <th>
                  <center>РАЗЖИЖЕНИЕ</center>
                </th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th>
                  <p>LpHera\Лфера</p> <i>Термостабильная α-амилаза</i>
                </th>
                <th>
                  Препарат нового поколения, позволяющий работать в более
                  широком диапазоне pH и температур. Позволяет увеличить DX при
                  одновременном снижения показателя DP3. Применения препарата
                  LpHera позволяет снизить затраты на химикаты, поддерживающие
                  уровень pH на 50%, а также ионообменные смолы.
                </th>
                <th>0,15-0,30 кг/т СВ крахмала </th>
                <th>30 кг</th>
              </tr>
              <tr>
                <th>
                  <p>BAN 480 LS/БАН 480ЛС</p>
                  <i>Мезофильная α-амилаза </i>
                </th>
                <th>
                  Применяемая в процессе гидролиза крахмала при производстве
                  мальтодекстринов, а так же для снижения вязкости при
                  фильтрации.{" "}
                </th>
                <th>0,50-1,0 кг/т СВ крахмала </th>
                <th>30 кг</th>
              </tr>
              <tr>
                <th>
                  <p>Termamyl 2X/Термамил 2Х</p>
                  <i>Термостабильная α-амилаза </i>
                </th>
                <th>
                  Специально разработанный фермент для наиболее эффективной
                  декстринизации крахмала при производстве мальтодекстринов.{" "}
                </th>
                <th>0,20-0,40 кг/т СВ крахмала </th>
                <th>30 кг</th>
              </tr>
              <tr>
                <th>
                  <p>Liquozyme X/Ликвазим Х</p>
                  <i>Термостабильная α-амилаза </i>
                </th>
                <th>
                  Эффективное образование DE на этапе разжижения, высокий
                  уровень декстрозы, быстрое снижение вязкости.{" "}
                </th>
                <th>0,30-0,50 кг/т СВ крахмала </th>
                <th>30 кг</th>
              </tr>
              <tr className="tableCenter">
                <th></th>
                <th>
                  <center>ОСАХАРИВАНИЕ</center>
                </th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th>
                  <p>Dextrozyme GO/Декстрозим Гоу</p>
                  <i>
                    Пуллуланазная, глюкоамилазная и лизофосфолипазная активности
                  </i>
                </th>
                <th>
                  Является блендом глюкоамилазы, пуллуланазы и лизофосфолипазы
                  созданный для получения максимального DX в процессе
                  осахаривания. Данный ферментный препарат обеспечивает
                  улучшенную фильтрацию и обладает улучшенной pH стабильностью.{" "}
                </th>
                <th>0,4-0,6 кг/т СВ крахмала </th>
                <th>30 кг</th>
              </tr>
              <tr>
                <th>
                  <p>Extenda®/Экстенда</p>
                  <i>Мультиэнзимный комплекс </i>
                </th>
                <th>
                  Является уникальным мультиэнзимным комплексом для процесса
                  осахаривания, который позволяет достигать непревзойдённого
                  действия. Созданный для достижения максимально возможного
                  уровня глюкозы и минимального уровня DP2 сахаров.{" "}
                </th>
                <th>0,55-0,75 кг/т СВ крахмала </th>
                <th>30 кг</th>
              </tr>
              <tr>
                <th>
                  <p>Secura/Секьюра</p>
                  <i>β-амилаза </i>
                </th>
                <th>
                  Уникальная, термостабильная, устойчивая в широком диапазоне pH
                  бета-амилаза для производства мальтозного сиропа.{" "}
                </th>
                <th>0,04-0,2 кг/т СВ крахмала </th>
                <th>30 кг</th>
              </tr>
              <tr>
                <th>
                  <p>Fungamyl 800L/Фунгамил 800Л</p>
                  <i>Грибная α-амилаза </i>
                </th>
                <th>Увеличение количества мальтозы в сиропе. </th>
                <th>0,1-0,2 кг/т СВ крахмала </th>
                <th>30 кг</th>
              </tr>
              <tr>
                <th>
                  <p>Promozyme D6/Промозим Д6</p>
                  <i>Пуллуланазная активность </i>
                </th>
                <th>
                  Используется для расщепления амилопектина после разжижения и
                  уменьшения содержания олигосахаридов в глюкозном сиропе.{" "}
                </th>
                <th>0,5-0,7 кг/т СВ крахмала </th>
                <th>30 кг</th>
              </tr>
              <tr className="tableCenter">
                <th></th>
                <th>
                  <center>ФИЛЬТРАЦИЯ</center>
                </th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th>
                  <p>Finizyme W 5X/Финизим W5X</p>
                  <i>Лизофосфолипазная активность </i>
                </th>
                <th>
                  Лизофосфолипаза, которая применяется для улучшения качества
                  фильтрации сиропов с высоким содержанием лизофосфолипидов.
                  Минимальные дозировки для эффективной фильтрации.{" "}
                </th>
                <th>0,02 кг/т СВ крахмала </th>
                <th>25 кг</th>
              </tr>
              <tr>
                <th>
                  <p>Viscoferm/Вискоферм</p>
                  <i>
                    β-глюканазная, целлюлолитическая, ксиланазная активности{" "}
                  </i>
                </th>
                <th>Улучшение фильтрации при переработке Б-крахмала. </th>
                <th>0,1-0,2 кг/т СВ крахмала </th>
                <th>25 кг</th>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrainCardEnzymes;
