import React from "react";
import { Link } from "react-router-dom";

const TechnologyCard = () => {
  return (
    <div className="technologyCard">
      <Link to="/cardgrain">
        <button>Глубокая переработка зерна</button>
      </Link>
      <Link to="/cardprotein">
        <button>Ферментативные гидролизаты белков</button>
      </Link>
      <Link to="/cardalcohol">
        <button>Производство спирта</button>
      </Link>
      <Link to="/pharmaceutical">
        <button>Производство фармсубстанции</button>
      </Link>
      <Link to="/proteinhydroly">
        <button>Гидролизаты белка для питательных сред</button>
      </Link>
      <Link to="/zernovye-osnovy-kormov/">
        <button>Биорешения для премиксов и кормовых добавок</button>
      </Link>
      <Link to="/homeanimalfood">
        <button>Гидролизаты белка в кормах для домашних питомцев</button>
      </Link>
      <Link to="/beverageproduction">
        <button>Производство напитков из зерновых культур</button>
      </Link>
      <Link to="/foodproduction">
        <button>Производство продуктов питания</button>
      </Link>
      <Link to="/juiceproduction">
        <button>Производство сока</button>
      </Link>
      <Link to="/fermentation">
        <button>Ферментация</button>
      </Link>
      <Link to="/bioxim">
        <button>Биологизация химии</button>
      </Link>
    </div>
  );
};

export default TechnologyCard;
