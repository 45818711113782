import { Image } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const NaturalExtracts = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="mainImg">
            <img src="/img/bulion.jpg" alt="" />
            <h3>
              Производство натуральных экстрактов и бульонов с использование
              биотехнологических процессов
            </h3>
          </div>
          <div className="descriptionProduct">
            <h3 className="text__h3">ОПИСАНИЕ ТЕХНОЛОГИИ И ПРОДУКТА</h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="descriptionProductCards">
              <Image className="descriptionProductImg" src="/img/bulpic.jpg" />
              <div>
                <p>
                  Натуральные экстракты и бульоны{" "}
                  <span>
                    производятся с использованием Биотехнологий из малоценных
                    видов пищевого сырья, которые образуются на предприятиях
                    мясной и птицеперерабатывающей индустрий после процессов
                    ручной и механической обвалки сырьевых ресурсов:
                  </span>{" "}
                  мясной обрези, тримминга, костного остатка (шрота)
                </p>
                <p>
                  <span>
                    Отработанные технологические режимы и биотехнологические
                    решения позволяют получать
                  </span>
                  экстракты и бульоны <span>с широким функционалом,</span>
                  высокой биологической ценностью (содержание полноценного
                  животного белка = 4–5% ){" "}
                  <span> и с хорошими потребительскими свойствами</span>
                </p>
                <div
                  style={{
                    border: "1.5px solid #48AA43",
                    background: "#48AA43",
                  }}
                ></div>
                <div className="descriptionProductCard">
                  <h5>
                    При производстве бульона путем ферментативной экстракции
                    процесс происходит при щадящих условиях, что полностью
                    сохраняет биологические свойства животного белка.
                  </h5>
                  <p>
                    Низкие затраты на производство.{" "}
                    <span>
                      Стоимость производства 1 литра бульона может составлять
                      8–10 рублей в зависимости от объемов производства,
                      стоимости сырья и требований к продукту
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ border: "1.5px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="bulonFerment">
            <div>
              <h3 className="text-h3">
                Области применения ферментативных экстрактов и бульонов
              </h3>
              <div
                style={{ border: "1.5px solid #F49F0A", background: "#F49F0A" }}
              ></div>
              <div className="bulonFermentCard">
                <img src="/img/1.jpg" alt="" />
                <p>Мясная и птицеперерабатывающая индустрии</p>
              </div>
              <div className="bulonFermentCard">
                <img src="/img/2.jpg" alt="" />
                <p>Мясная и птицеперерабатывающая индустрии</p>
              </div>
              <div className="bulonFermentCard">
                <img src="/img/3.jpg" alt="" />
                <p>Мясная и птицеперерабатывающая индустрии</p>
              </div>
              <div className="bulonFermentCard">
                <img src="/img/4.jpg" alt="" />
                <p>Мясная и птицеперерабатывающая индустрии</p>
              </div>
              <div className="bulonFermentCard">
                <img src="/img/5.jpg" alt="" />
                <p>Мясная и птицеперерабатывающая индустрии</p>
              </div>
            </div>
            <img src="/img/bulpic-two.jpg" alt="" />
          </div>
          <div className="poultryMeat">
            <h3 className="text__h3">
              ГЛУБОКАЯ ПЕРЕРАБОТКА МЯСА ПТИЦЫ (пример)
            </h3>
            <div
              style={{ border: "1.5px solid #48AA43", background: "#48AA43" }}
            ></div>
            <div className="poultryMeatCards">
              <div>
                <p>
                  Массовый баланс при переработке 1 тонны мясо-костного остатка:
                </p>
                <h5 className="poultryMeatCard">
                  Мясо-костный остаток после производства ММО (18–20% белка)
                  <h4>1.0 тонна</h4>
                </h5>
                <h5 className="poultryMeatCard">
                  Натуральный бульон (СВ – 4,5%, белок – 4,0%)
                  <h4>2.0 тонна ± 10%F</h4>
                </h5>
                <h5 className="poultryMeatCard">
                  Жир
                  <h4>0.05 тонны ± 5%</h4>
                </h5>
              </div>
              <Image src="/img/primer-pererabotki.jpg" />
            </div>
          </div>
          <div className="poultryProcessingExtract">
            <h3 className="text-h3">
              ИСПОЛЬЗОВАНИЕ МЯСНОГО ЭКСТРАКТА В МЯСНОЙ И ПТИЦЕПЕРЕРАБАТЫВАЮЩЕЙ
              ИНДУСТРИЯХ
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="processingExtractCards">
              <div className="processingExtractCard">
                <p>Из 1 тонны мясо — костного остатка можно произвести</p>
                <h4>2 тонны экстракта в жидкой форме</h4>
              </div>
              <div className="processingExtractCard">
                <p>
                  В 2,0 тоннах экстракта содержится (в пересчете на сухое
                  вещество):
                </p>
                <h4>80 кг гидролизата белка</h4>
              </div>
              <div className="processingExtractCard">
                <p>
                  Расход мясного экстракта в продукте (в пересчете сухое
                  вещество):
                </p>
                <h4>4,0 кг на 1 тонну сырья</h4>
              </div>
            </div>
            <div className="processingExtractCardsTwo">
              <p>
                2,0 тонны жидкого мясного экстракта{" "}
                <span>
                  могут быть использованы в качестве основы белоксодержащих
                  рассолов{" "}
                </span>
                для инъецирования или массирования 20 тонн сырья{" "}
                <span>
                  полуфабрикатов из мяса и мяса птицы, деликатесной продукции,
                  варено-копченых изделий, также в составе фаршевых смесей для
                  производства колбасных изделий, котлет, гамбургеров, и других
                  мясных продуктов.
                </span>
              </p>
            </div>
            <div className="processingExtractCardsThree">
              <img src="/img/bulpic-tree.jpg" alt="" />
              <ul>
                <p>
                  Мясные экстракты повышают качество мясных и рыбных продуктов:
                </p>
                <li>Повышение сочности, уплотнение текстуры и др</li>
                <li>
                  Существенное улучшение вкусо-ароматических характеристик
                  (замещение ненатуральных пищевых добавок), пищевой и
                  биологической ценности продуктов
                </li>
                <li>Увеличение выхода продукции</li>
              </ul>
            </div>
          </div>
          <div className="horecaIndustry">
            <h3 className="text-h3">ПИЩЕКОНЦЕНТРАТНАЯ ИНДУСТРИЯ И HORECA</h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="horecaIndustryCards">
              <img src="/img/bulpic-for.jpg" alt="" />
              <div>
                <p>
                  Основные области применения мясных экстрактов / бульонов в
                  пищеконцентратной индустрии и HORECA
                </p>
                <ul>
                  <li>Супы и бульоны</li>
                  <li>Соусы</li>
                  <li>Маринады</li>
                  <li>Протеиновые напитки</li>
                </ul>
                <p>
                  Ключевые преимущества применения мясных экстрактов / бульонов
                  в пищеконцентратной индустрии и HORECA
                </p>
                <ul>
                  <li>Низкая стоимость МЯСНОГО БУЛЬОНА</li>
                  <li>Хорошие потребительские свойства</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="productionFlowChart">
            <h3 className="text-h3">
              УПРОЩЕННАЯ БЛОК-СХЕМА ПРОИЗВОДСТВА ГИДРОЛИЗАТА <br />
              РАСТИТЕЛЬНОГО БЕЛКА В ЖИДКОЙ ФОРМЕ
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <p>
              Для малых предприятий можно предложить значительно <br /> более
              простую и недорогую схему Производства
            </p>
            <Image src="/img/shema-gidro.jpg" />
          </div>
          <div className="bulonFerment">
            <div>
              <h3 className="text-h3">ИНФРАСТРУКТУРА</h3>
              <div
                style={{ border: "1.5px solid #F49F0A", background: "#F49F0A" }}
              ></div>
              <p>
                Требования к площадке для размещения полной линии по
                производству 30 тонн натурального бульона (концентрация белка
                4,0%) в сутки из 15 тонн костного остатка (проект
                высокорентабелен для объемов переработки от 2 тн костного
                остатка в сутки):
              </p>
              <div className="bulonFermentCard">
                <img src="/img/1.jpg" alt="" />
                <p>
                  Площадь цеха (без участка приемки и хранения сырья) – до 350
                  м2
                </p>
              </div>
              <div className="bulonFermentCard">
                <img src="/img/2.jpg" alt="" />
                <p>Высота здания 6–8 м</p>
              </div>
              <div className="bulonFermentCard">
                <img src="/img/3.jpg" alt="" />
                <p>Сервисные среды:</p>
              </div>
              <ul>
                <li>Питьевая холодная вода</li>
                <li>Горячая вода</li>
                <li>Охлажденная вода</li>
                <li>Пар</li>
                <li>Электроэнергия</li>
                <li>Сжатый воздух</li>
              </ul>
            </div>
            <img src="/img/bulpic-f0r2.jpg" alt="" />
          </div>
          <div className="engineeringEquipment">
            <h3 className="text-h3">ИНЖИНИРИНГ И ОБОРУДОВАНИЕ</h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="engineeringEquipmentCard">
              <img
                className="engineeringEquipmentCardImg"
                src="/img/EQUIPMENT1.jpg"
                alt=""
              />
              <div>
                <p>
                  Наши специалисты, совместно с ведущими российскими и
                  европейскими инжиниринговыми и технологическими компаниями,
                  проектируют биотехнологические производства, в частности,{" "}
                  <span>для всех описанных в данной презентации проектов,</span>
                  и оснащают их оборудованием ведущих европейских
                  производителей:{" "}
                  <span>
                    CFT Group, ICF & Welko (Италия), GEA Group, Flottweg
                    (Германия), Bertsch-Laska (Австрия) и других
                  </span>
                </p>
                <img src="/img/EQUIPMENT2.jpg" alt="" />
              </div>
            </div>
          </div>
          <div
            style={{ border: "1.5px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="bioTasksPharm">
            <h3 className="texth3">
              ПОЛУЧИТЕ БИОТЕХНОЛОГИЧЕСКИЕ РЕШЕНИЯ ВАШИХ ЗАДАЧ!
            </h3>
            <div
              style={{ border: "1px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="li">
              <p>НАШИ КОМПЕТЕНЦИИ</p>
              <li>
                Разработка и освоение промышленных биотехнологий глубокой
                переработки сырья животного и растительного происхождения и
                производства продуктов для жизни с уникальными биологическими
                свойствами
              </li>
              <li>
                Производство, дистрибуция и применение ферментативного
                гидролизата белка в различных индустриях
              </li>
              <li>
                Проектирование и запуск производств глубокой переработки сырья,
                в том числе лицензионных, обеспечение трансфера технологии,
                особые условия приобретения оборудования и поставки необходимых
                ферментных препаратов
              </li>
            </div>
            <div
              style={{ border: "1px solid#067BC2", background: "#067BC2" }}
            ></div>
            <h4 className="text__h3">
              СВЯЖИТЕСЬ С НАМИ ЛЮБЫМ УДОБНЫМ ДЛЯ ВАС СПОСОБОМ
            </h4>
            <div className="bioTasksButton">
              <Link to="/contact">
                <button>Контакты</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NaturalExtracts;
