import React from "react";
import { Link } from "react-router-dom";

const ProductsCard = () => {
  return (
    <div className="technologyCard">
      <Link to="/gidrolizaty-kurinogo-belka">
        <button>Гидролизаты куриного белка</button>
      </Link>
      <Link to="/inzhiniring-i-oborudovanie/">
        <button>Инжиниринг и оборудование</button>
      </Link>
      <Link to="/fermentnye-preparaty-proteazy/">
        <button>Ферментные препараты Протеазы</button>
      </Link>
      <Link to="/graincardenzymes">
        <button> Глубокая переработка зерна</button>
      </Link>
      <Link to="/alcoholproduction">
        <button> Производство спирта</button>
      </Link>
      <Link to="/proizvodstvo-zernovogo-moloka">
        <button> Производство зернового молока</button>
      </Link>
      <Link to="/fermenty-dlya-sokov/">
        <button>Ферменты для соков</button>
      </Link>
    </div>
  );
};

export default ProductsCard;
