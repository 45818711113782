import { Image } from "antd";
import React from "react";

const ChickenProtein = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="productsChickenProtein">
            <h3 className="text-h3">ПРОДУКТЫ. ГИДРОЛИЗАТЫ КУРИНОГО БЕЛКА</h3>
            <div
              style={{
                border: "1.5px solid #EE3A43",
                background: "#EE3A43",
                width: "30%",
              }}
            ></div>
            <div className="grainCardTable">
              <div className="productsChickenProteinTable">
                <table>
                  <tr>
                    <td>ГИДРОЛИЗАТ КУРИНОГО БЕЛКА «HCP PREMIUM 150»</td>
                    <td>ГИДРОЛИЗАТ КУРИНОГО БЕЛКА «HCP – B4»</td>
                  </tr>
                </table>
                <p className="text__h3">ОБЩИЕ ПАРАМЕТРЫ</p>
                <ul>
                  <li>
                    100% натуральный продукт, без искусственных добавок,
                    ароматизаторов и консервантов;
                  </li>
                  <li>
                    произведен из свежего куриного сырья пищевого стандарта
                  </li>
                  <li>
                    Получен путём ферментативного гидролиза куриного белка
                  </li>
                  <li> Гипоаллергенный;</li>
                  <li>Высокая степень переваримости белка (≥95%)</li>
                  <li>Полноценный аминокислотный состав</li>
                  <li>высокое содержание незаменимых аминокислот;</li>
                  <li>
                    Содержит важнейшие микроэлементы в органической форме
                    (кальций, фосфор, цинк, магний).
                  </li>
                  <li>Низкое содержание золы и жира</li>
                  <li>Содержат биологически-активные вещества</li>
                </ul>
                <p className="text__h3">КЛЮЧЕВЫЕ ОТЛИЧИЯ</p>
                <table>
                  <tr>
                    <th>100% растворимость </th>
                    <th> Диспергируемый, мутный раствор</th>
                  </tr>
                  <tr>
                    <th>Содержание белка: 88% (±2%) </th>
                    <th>Содержание белка: 85% (±2%)</th>
                  </tr>
                  <tr>
                    <th>Содержание жира: 0% (±0,5%) </th>
                    <th>Содержание жира: ˂5%</th>
                  </tr>
                  <tr>
                    <th>
                      Высокое содержание коротких пептидов и свободных
                      аминокислот{" "}
                    </th>
                    <th>Высокое содержание средних и коротких пептидов</th>
                  </tr>
                  <tr>
                    <th>
                      Технология производства предусматривает дополнительную
                      стадию очистки на установке ультрафильтрации{" "}
                    </th>
                    <th>
                      Стадия дополнительной очистки с помощью ультрафильтрации
                      не предусмотрена.
                    </th>
                  </tr>
                </table>
              </div>
            </div>
            <div
              style={{ border: "1.5px solid #DDDDDD", background: "#DDDDDD" }}
            ></div>
            <div className="keyApplications">
              <h3 className="text-h3">КЛЮЧЕВЫЕ ОБЛАСТИ ПРИМЕНЕНИЯ</h3>
              <div
                style={{
                  border: "1.5px solid #EE3A43",
                  background: "#EE3A43",
                }}
              ></div>
              <p>
                Благодаря своим биологическим и функциональным свойствам
                гидролизаты куриного белка «HCP PREMIUM 150» и «HCP – B4» могут
                успешно и эффективно использоваться в разных индустриях, для
                решения широкого спектра задач
              </p>
              <table>
                <tr>
                  <th>ПРОДУКТ </th>
                  <th>Питательные среды </th>
                  <th>Корма и лакомства для домашних животных </th>
                  <th>Функциональное, здоровое и спортивное питание </th>
                  <th>Птицеперерабатывающая и мясная индустрии </th>
                  <th>Корма для молодняка и аквакультуры</th>
                </tr>
                <tr>
                  <td>ГИДРОЛИЗАТ КУРИНОГО БЕЛКА «HCP PREMIUM 150»</td>
                  <td>√</td>
                  <td>√</td>
                  <td>√</td>
                  <td>√</td>
                  <td>√</td>
                </tr>
                <tr>
                  <td>ГИДРОЛИЗАТ КУРИНОГО БЕЛКА «HCP – B4»</td>
                  <td></td>
                  <td>√</td>
                  <td></td>
                  <td>√</td>
                  <td>√</td>
                </tr>
              </table>
            </div>
            <div
              style={{ border: "1.5px solid #DDDDDD", background: "#DDDDDD" }}
            ></div>
            <div className="imgPageHalal">
              <p>
                Сотрудники нашей компании готовы предоставить дополнительную
                информацию о предлагаемых гидролизатах куриного белка, способах
                их производства, а также проконсультировать специалистов
                предприятий по вопросам использования этих универсальных
                ингредиентов в конкретных индустриях и направлениях.
              </p>
              <div className="imgHalal">
                <Image src="/img/halal1.jpg" />
                <Image src="/img/halal2.jpg" />
                <Image src="/img/halal3.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChickenProtein;
