import React from "react";
import { Link } from "react-router-dom";

const CardFoodProduction = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="mainImg">
            <img src="/img/foodProduction.jpg" alt="" />
            <h3>Производство продуктов питания</h3>
          </div>
          <div className="bioFoodProduction">
            <div className="bioFoodProductionImg">
              <img src="/img/heat.png" alt="" />
              <br />
              <img src="/img/biofoodProduction1.jpg" alt="" />
            </div>
            <div className="bioFoodProductionText">
              <h3 className="text-h3">
                БИОТЕХНОЛОГИИ В ПРОИЗВОДСТВЕ ПРОДУКТОВ ПИТАНИЯ
              </h3>
              <div
                style={{ border: "1.5px solid #48AA43", background: "#48AA43" }}
              ></div>
              <p>Биотехнологии позволяют предприятию :</p>
              <ul>
                <li>Осуществить глубокую переработку сырьевых ресурсов</li>
                <li>
                  Производить натуральные продукты с уникальными биологическими
                  и потребительскими свойствами, в том числе, из малоценных
                  ресурсов сырья
                </li>
                <li>
                  Сформировать свою позицию на быстрорастущих маржинальных
                  рынках
                </li>
                <li>Повысить выход и качество даже традиционной продукции</li>
                <li>Значительно улучшить свою экономику и экологию региона</li>
              </ul>
            </div>
          </div>
          <div className="enzymesAreFor">
            <h3 className="text-h3">ДЛЯ ЧЕГО НУЖНЫ ФЕРМЕНТЫ</h3>
            <div
              style={{ border: "1.5px solid #48AA43", background: "#48AA43" }}
            ></div>
            <div className="enzymesAreForHeat">
              <img src="/img/heat.png" alt="" />
              <div>
                <p>
                  Основной целью применения ферментов в производстве продуктов
                  питания является значительное повышение питательных,
                  биологических и потребительских свойств этих продуктов, в
                  первую очередь:
                </p>
                <ul>
                  <li>
                    Биодоступности, то есть скорости, легкости и полноты
                    усвоения пищи
                  </li>
                  <li>Гипоаллергенности</li>
                  <li>Иммуномодулирующих свойств</li>
                  <li>
                    Антиоксидантных и других полезных свойств продуктов питания
                  </li>
                </ul>
              </div>
            </div>
            <div className="enzymesAreForCard">
              <p>
                Российские покупатели, производители и продавцы стали понимать,
                что надо употреблять и производить легкоусвояемые,
                гипоаллергенные, повышающие иммунитет продукты питания и
                напитки. Даже если эти продукты кажутся немного более дорогими.
                Просто надо считать, как минимум, полезность и цену усвоенных
                организмом питательных веществ, а не просто количество
                купленного и съеденного
              </p>
            </div>
            <span>
              Лучше всего это понимают люди старшего возраста и / или
              испытывающие высокие эмоциональные и физические нагрузки
            </span>
          </div>
          <div className="proteinHydrolyzatesAreFor">
            <h3 className="text-h3">
              ДЛЯ ЧЕГО НУЖНЫ ФЕРМЕНТАТИВНЫЕ ГИДРОЛИЗАТЫ БЕЛКОВ
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="proteinHydrolyzatesAreForImg">
              <img src="/img/heat.png" alt="" />
              <p>
                При употреблении мясных, молочных, растительных продуктов даже в
                приготовленном виде (варёном, жареном, тушеном, печёном, и пр.)
                здоровый молодой организм усваивает не более 50% содержа-щихся в
                них нативных белков. К тому же, нативные белки (молочные,
                пшеничные и др.) сильно аллергенны.
              </p>
            </div>
            <div className="proteinHydrolyzatesAreForCards">
              <div>
                <div className="proteinHydrolyzatesAreForCard">
                  <p>
                    Что же делать, откуда получить дополнительный белок, так
                    необходимый организму? В особенности, когда организм
                    ослаблен, не очень молод и/или испытывает высокие
                    эмоциональные и физические нагрузки, то есть когда ему нужно
                    существенно больше белка. <br />
                    <br /> Можно, конечно, попытаться съесть в два раза больше
                    мяса, или выпить в два раза больше молока, но такой подход
                    доставит Вам большие проблемы
                  </p>
                </div>
                <span>
                  Лучшее, и практически единственное решение – натуральные,
                  полностью и легко усваиваемые, гипоаллергенные Гидролизаты
                  Белков.
                </span>
              </div>
              <img src="/img/proteinHydrolyzatesAreFor.jpg" alt="" />
            </div>
          </div>
          <div className="biosolutions-ForManufacturing">
            <h3 className="texth3">БИОРЕШЕНИЯ ДЛЯ ПРОИЗВОДСТВА</h3>
            <div
              style={{ border: "1px solid #48AA43", background: "#48AA43" }}
            ></div>
            <div className="foodConcentrates">
              <img src="/img/ForManufacturing.jpg" alt="" />
              <ul>
                <li>
                  <p>ПИЩЕКОНЦЕНТРАТОВ:</p>
                  каш, основ, бульонов и супов, продуктов быстрого
                  приготовления, соусов, замороженных продуктов,
                  вкусо-ароматических добавок и приправ
                </li>
                <li>
                  <p>МЯСНЫХ, РЫБНЫХ И МОЛОЧНЫХ ПРОДУКТОВ</p>
                </li>
                <li>
                  <p>СПЕЦИАЛЬНЫХ ПРОДУКТОВ ПИТАНИЯ:</p>
                  функциональных, спортивных и лечебно-профилактических, для
                  людей старшего поколения и детских
                </li>
                <li>
                  <p>КУЛЬТИВИРУЕМЫХ ПРОДУКТОВ</p>
                </li>
                <li>
                  <p>РАСТИТЕЛЬНЫХ ПРОДУКТОВ</p>
                </li>
                <li>
                  <p>СОКОВ, ЗЕРНОВЫХ И ПРОТЕИНОВЫХ НАПИТКОВ</p>
                </li>
              </ul>
            </div>
            <div className="foodConcentratesText">
              <p>
                Биотехнологические решения для Производства различных продуктов
                питания зависят от множества факторов, в частности, от требуемых
                партнером свойств этих продуктов, планируемой их позиции на
                рынке
              </p>
              <div className="foodConcentratesTextCard">
                <p>
                  Поэтому эти биорешения индивидуальны и требуют
                  предварительного анализа производства и продуктов
                </p>
              </div>
            </div>
          </div>
          <div className="fermentationProteinsFood">
            <h3 className="texth3">
              ФЕРМЕНТАЦИЯ – ОСНОВА ИНДУСТРИИ <br /> АЛЬТЕРНАТИВНЫХ БЕЛКОВ И
              КУЛЬТИВИРУЕМЫХ ПРОДУКТОВ
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="fermentationProteinsFoodText">
              <div className="proteinsFoodTextLeft">
                <img src="/img/fermentationProteinsFoodText.jpg" alt="" />
                <p>
                  Спиртовые и пивные заводы, а также предприятия глубокой
                  переработки зерна смогут производить любую еду
                </p>
              </div>
              <div>
                <h6>
                  В ноябре 2020 года Всемирный экономический форум назвал
                  ферментацию ключевой областью инноваций в мире
                </h6>
                <h6>
                  Ферментация{" "}
                  <span>
                    позволяет использовать микроорганизмы как биофабрики для
                  </span>
                  производства практически любых сложных органических молекул,{" "}
                  <span>
                    в частности, любых белков (мясных, молочных, …), жиров и
                    сахаров с высокой скоростью, в больших объемах и по низким
                    ценам (подробнее о Ферментации см. в разделе …)
                  </span>
                </h6>
                <div className="proteinsFoodTextRightCard">
                  <h6>
                    <span>
                      В последние годы быстро развиваются такие применения, как
                      культивирование мясных и молочных продуктов. Согласно
                      прогнозам{" "}
                    </span>
                    цена культивированного мяса к 2030 году будет в 5 раз, а в
                    2035 году – в 10 раз меньше цены обычного мяса.
                  </h6>
                </div>
                <h6>
                  <span>Уже сегодня ферментация широко используется в </span>
                  производстве спирта, пива, кормовых добавок для животных
                  (аминокислоты, дрожжи, …), пищевых ингредиентов и продуктов
                  питания, витаминов, антибиотиков
                  <span> и многих других продуктов</span>
                </h6>
              </div>
            </div>
          </div>
          <div
            style={{ border: "1.5px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="bioTasksPharm">
            <h3 className="texth3">
              ПОЛУЧИТЕ БИОТЕХНОЛОГИЧЕСКИЕ РЕШЕНИЯ ВАШИХ ЗАДАЧ!
            </h3>
            <div
              style={{ border: "1px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="li">
              <p>НАШИ КОМПЕТЕНЦИИ</p>
              <li>
                Разработка и освоение промышленных биотехнологий глубокой
                переработки сырья животного и растительного происхождения и
                производства продуктов для жизни с уникальными биологическими
                свойствами
              </li>
              <li>
                Производство, дистрибуция и применение ферментативного
                гидролизата белка в различных индустриях
              </li>
              <li>
                Проектирование и запуск производств глубокой переработки сырья,
                в том числе лицензионных, обеспечение трансфера технологии,
                особые условия приобретения оборудования и поставки необходимых
                ферментных препаратов
              </li>
            </div>
            <div
              style={{ border: "1px solid#067BC2", background: "#067BC2" }}
            ></div>
            <h4 className="text__h3">
              СВЯЖИТЕСЬ С НАМИ ЛЮБЫМ УДОБНЫМ ДЛЯ ВАС СПОСОБОМ
            </h4>
            <div className="bioTasksButton">
              <Link to="/contact">
                <button>Контакты</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardFoodProduction;
