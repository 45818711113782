import { Image } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const ProteaseEnzyme = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="roteaseEnzyme">
            <h3 className="text__h3">ФЕРМЕНТНЫЕ ПРЕПАРАТЫ ПРОТЕАЗЫ</h3>
            <div
              style={{ border: "1.5px solid #005AAB", background: "#005AAB" }}
            ></div>
            <p>
              Протеазы относят к классу ферментов, ускоряющих реакции гидролиза
              белков, пептидов и других соединений, содержащих пептидные связи.
              Использование протеаз в биотехнологических процессах обработки
              белоксодержащего сырья животного или растительного происхождения
              позволяет получить гидролизаты белков с разной степенью гидролиза
              пептидных связей и заданными биологическими и функциональными
              свойствами.
            </p>
            <div className="roteaseEnzymeCard">
              <img src="/img/proteazy.jpg" alt="" />
              <div>
                <h4>
                  Эндопротеазы гидролизуют белок в середине молекулы, образуя
                  пептиды более или менее одинакового размера при добавлении
                  молекулы воды к пептидной связи.
                </h4>
                <h4>
                  Экзопептидазы гидролизуют с конца пептида или белка, и
                  высвобождают одну аминокислоту.
                </h4>
                <h4>
                  Малые пептиды способствуют базовым ароматам сырья, а свободные
                  аминокислоты выступают в качестве усилителей вкуса и аромата.
                </h4>
              </div>
            </div>
          </div>
          <div
            style={{ border: "1.5px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="proteasePprtfolio">
            <h3 className="text__h3">
              ПОРТФЕЛЬ ПРОТЕАЗ КОМПАНИИ НОВОЗАЙМС (ДАНИЯ)
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <h3 className="text__h3">рекомендуемые области применения</h3>
            <h5>
              Эндопротеазы:{" "}
              <b>
                Alcalase, Neutrase, Protamex, Novo-Pro D <br />
              </b>
              Комплекс эндопротеазы и экзопептидазы: <b> Flavourzyme</b>
            </h5>
            <Image
              className="proteasePprtfolioImg"
              src="/img/flavourzyme.jpg"
            />
          </div>
          <div
            style={{ border: "1.5px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="proteasesPurpose">
            <h3 className="text__h3">
              НАИМЕНОВАНИЕ ПОСТАВЛЯЕМЫХ ПРОТЕАЗ И ИХ НАЗНАЧЕНИЕdiv
            </h3>
            <div
              style={{ border: "1.5px solid #005AAB", background: "#005AAB" }}
            ></div>
            <div className="grainCardTable">
              <table>
                <thead>
                  <tr>
                    <td>Наименование Фермента/упаковка </td>
                    <td>Состав и применение </td>
                    <td>Фасовка</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>ALCALASE 2.4L FG \ (АЛКАЛАЗА 2,4Л ФГ) </th>
                    <th>
                      Высокоэффективная бактериальная протеаза, специально
                      разработанная для гидролиза всех видов белка. Является
                      эндопротеазой{" "}
                    </th>
                    <th>25 кг</th>
                  </tr>
                  <tr>
                    <th>NEUTRASE 0.8L \ (НЕЙТРАЗА 0,8Л) </th>
                    <th>
                      Нейтральная протеаза, которая расщепляет растворимые белки
                      до пептидов. Является эндопротеазой.{" "}
                    </th>
                    <th>25 кг</th>
                  </tr>
                  <tr>
                    <th>PROTAMEX \ (ПРОТАМЕКС) (сухой)</th>
                    <th>
                      Бактериальная эндопротеаза. Образует негорькие белковые
                      гидролизаты даже при низкой степени гидролиза.{" "}
                    </th>
                    <th>20 кг</th>
                  </tr>
                  <tr>
                    <th>FLAVOURZYME1000L \ (ФЛАВОРЗИМ 1000Л) </th>
                    <th>
                      Грибная комплексная протеаза, состоящая из эндопротеазы и
                      экзопептидазы амино- и карбоксипептидазного типа.
                      Используется для гидролиза белка в нейтральной или
                      слабокислой среде.{" "}
                    </th>
                    <th>25 кг</th>
                  </tr>
                  <tr>
                    <th>NOVO-PRO D \ (НОВО-ПРО Д) </th>
                    <th>
                      Серинсодержащая эндопротеаза, гидролизующая внутренние
                      пептидные связи. Используется для получения гидролизатов
                      мясных белков, которые применяются для улучшения
                      поедаемости кормов для домашних животных, а также
                      гидролиза кератина.{" "}
                    </th>
                    <th>25 кг</th>
                  </tr>
                  <tr>
                    <th>NOVOZYME 37071 \ (НОВОЗИМ 37071) </th>
                    <th>
                      Высокоэффективная бактериальная протеаза, специально
                      разработанная для гидролиза всех видов белка. Является
                      эндопротеазой. Имеет тот же функционал, что и протеаза
                      АЛКАЛАЗА 2,4Л ФГ, но более высокую активность.{" "}
                    </th>
                    <th>25 кг</th>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>
              Дозировки протеаз зависят от многих факторов технологического
              процесса, включая:
              <br />
              <br />
              — вид белка; <br />
              <br />
              — режимы обработки сырья; <br />
              <br />
              — требования к получаемому гидролизату с точки зрения
              биологических и функциональных свойств. <br />
              <br /> Рекомендации по дозировкам предоставляются на этапе
              согласования условий проведения тестов.
            </p>
          </div>
          <div
            style={{ border: "1.5px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="bioTasks">
            <h3 className="text__h3">
              ПОЛУЧИТЕ БИОТЕХНОЛОГИЧЕСКИЕ РЕШЕНИЯ ВАШИХ ЗАДАЧ!
            </h3>
            <div
              style={{ border: "1px solid #48AA43", background: "#48AA43" }}
            ></div>
            <p>
              Основываясь на глобальном опыте компании Новозаймс и практической
              работе наших специалистов по продвижению и внедрению
              ферментативных процессов гидролиза белков животного и
              растительного происхождения, мы готовы предложить вашей компании
              следующий набор услуг в области применения протеаз на вашем
              производстве:
            </p>

            <div className="li">
              <li>
                Подбор оптимального портфеля протеаз для ваших
                биотехнологических решений в зависимости от видов сырья, условий
                процесса и требований к получаемому продукту.
              </li>
              <li>
                Предоставление общих рекомендаций по оптимальным условиям
                процесса ферментативного гидролиза.
              </li>
              <li>
                Предоставление бесплатных образцов протеаз для проведения
                лабораторных исследований.
              </li>
              <li>
                Обеспечение коммерческих поставок протеаз, которые были отобраны
                нами совместно со специалистами предприятия во время проведения
                лабораторных и промышленных тестов.
              </li>
            </div>
            <div
              style={{ border: "1px solid#067BC2", background: "#067BC2" }}
            ></div>
            <h4 className="text__h3">
              СВЯЖИТЕСЬ С НАМИ ЛЮБЫМ УДОБНЫМ ДЛЯ ВАС СПОСОБОМ
            </h4>
            <div className="bioTasksButton">
              <Link to="/contact">
                <button>Контакты</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProteaseEnzyme;
