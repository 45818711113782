import { Image } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const VegetableProtein = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="mainImg">
            <img src="/img/gidroliz-ban.jpg" alt="" />
            <h3>Ферментативные гидролизаты растительных белков</h3>
          </div>
          <div className="aminoAcids">
            <h3 className="text__h3">
              ОСНОВНЫЕ ПРЕИМУЩЕСТВА ПРОЦЕССА ФЕРМЕНТАТИВНОГО ГИДРОЛИЗА <br />{" "}
              БЕЛКА ОТНОСИТЕЛЬНО ДРУГИХ СПОСОБОВ ГИДРОЛИЗА
            </h3>
            <div
              style={{ border: "1.5px solid #005AAB", background: "#005AAB" }}
            ></div>
            <div className="aminoAcidsCard">
              <div>
                <img src="/img/gidroliz1.jpg" alt="" />
                <p>
                  Белоксодержащее сырье животного и растительного происхождения
                </p>
              </div>
              <div>
                <p>ФЕРМЕНТАТИВНЫЙ ГИДРОЛИЗ</p>
                <img src="/img/gidroliz2.jpg" alt="" />
              </div>
              <div>
                <img src="/img/gidroliz3.jpg" alt="" />
                <p>Пептиды</p>
              </div>
              <div>
                <img src="/img/gidroliz4.jpg" alt="" />
                <p>Аминокислоты</p>
              </div>
              <div>
                <li>Продукты питания</li>
                <li>Корма для животных. Аквакультуры</li>
                <li>
                  Питательные среды для микроорганизмов, обладающие высокими
                  биологическими свойствами
                </li>
              </div>
            </div>
            <div
              style={{ border: "1.5px solid #48AA43", background: "#48AA43" }}
            ></div>
            <ul className="vigetableUl">
              <li>
                Процесс гидролиза белка проводится в мягких условиях: при
                температуре 45-55 °С и pH 7-8
              </li>
              <li>Не происходит разрушения аминокислот</li>
              <li>Не происходит рацемизации аминокислот</li>
              <li>
                Процесс гидролиза является гибким и позволяет получать
                гидролизаты с разными функциональными и биологическими
                свойствами
              </li>
              <li>Низкие энергозатраты</li>
              <li>Высокая экологическая безопасность</li>
              <li>
                Не требуется дополнительная очистка гидролизатов от солей и
                примесей
              </li>
            </ul>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="vigitableProtein">
            <h3 className="text__h3">
              ПРЕИМУЩЕСТВЕННЫЕ СВОЙСТВА ГИДРОЛИЗАТОВ РАСТИТЕЛЬНОГО БЕЛКА (ГРБ)
            </h3>
            <div
              style={{ border: "1.5px solid #005AAB", background: "#005AAB" }}
            ></div>
            <p>
              Ферментативный гидролизат растительного белка является продуктом
              глубокого расщепления белка с использованием протеаз и
              представляет собой натуральный амино-пептидный комплекс с высоким
              содержанием коротких и средних пептидов и свободных аминокислот.
            </p>
            <div className="vigitableProteinCards">
              <div className="vigitableProteinCard">
                <h5>
                  Ключевые преимущества ГРБ в сравнении с нативными белками
                  растительного происхождения:
                </h5>
                <ul>
                  <li>
                    Полное отсутствие или минимальное количество антипитательных
                    факторов белковой природы
                  </li>
                  <li>Быстрое и полное усвоение</li>
                  <li>Гипоаллергенность</li>
                  <li>Улучшенный вкус</li>
                  <li>Расширение области применения</li>
                  <li>Водорастворимость</li>
                </ul>
              </div>
              <div className="vigitableProteinCard">
                <h5>Преимущества использования ГРБ:</h5>
                <ul>
                  <li>Улучшается вкус и текстура конечных продуктов</li>
                  <li>Повышается пищевая и биологическая ценность продуктов</li>
                  <li>Повышается усвояемость белка в конечных продуктах</li>
                  <li>
                    Расширяется продуктовая линейка, в составе которой
                    применяются гидролизаты белка (например – напитки или
                    питательные среды и тп.)
                  </li>
                  <li>
                    Отсутствие этических проблем с применениями в отличие от
                    животных белков
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="plantProteins">
            <h3 className="text__h3">БЕЛКИ РАСТИТЕЛЬНОГО ПРОИСХОЖДЕНИЯ</h3>
            <div
              style={{ border: "1.5px solid #F49F0A", background: "#F49F0A" }}
            ></div>
            <div className="plantProteinsCards">
              <div className="plantProteinsCard">
                <p>
                  Основные виды растительных белков, которые присутствуют на
                  глобальном рынке:
                </p>
                <ul>
                  <li>Соевый белок</li>
                  <li>Пшеничный белок (глютен)</li>
                  <li>Гороховый белок</li>
                  <li>Кукурузный белок (клейковина)</li>
                  <li>Рисовый белок</li>
                </ul>
                <img src="/img/belki-rast.jpg" alt="" />
              </div>
              <div className="plantProteinsCard">
                <Image src="/img/jadval.jpg" alt="" />
                <p>
                  Аминокислотный состав некоторых растительных белков в
                  сравнении с отдельными белками животного происхождения
                </p>
              </div>
            </div>
          </div>
          <div className="enzymaticPlantProtein">
            <h3 className="text__h3">
              ИНДУСТРИАЛЬНЫЕ ПРИМЕНЕНИЯ ФЕРМЕНТАТИВНЫХ ГИДРОЛИЗАТОВ
              РАСТИТЕЛЬНОГО БЕЛКА
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <p>
              Основные виды гидролизатов белка (HP), которые присутствуют на{" "}
              <br />
              глобальном рынке
            </p>
            <div className="enzymaticPlantProteinTable">
              <table>
                <tr>
                  <th>МОЛОЧНЫЕ </th>
                  <th>РАСТИТЕЛЬНЫЕ </th>
                  <th>МЯСНЫЕ/РЫБНЫЕ</th>
                </tr>
                <tr>
                  <td>Гидролизат казеина</td>
                  <td>Гидролизат глютена</td>
                  <td>Гидролизат куриного белка</td>
                </tr>
                <tr>
                  <td>Гидролизат сыворотки</td>
                  <td>Гидролизат соевого белка</td>
                  <td>Гидролизат коллагена</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Гидролизат горохового белка</td>
                  <td>Рыбный гидролизат</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td>Печеночный гидролизат</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td>Гидролизат кератина</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td>Говяжий гидролизат</td>
                </tr>
              </table>
            </div>
            <h5>
              Наибольшие распространение и применения пока что получили
              Гидролизаты животных белков — молочных и мясных/рыбных. Однако в
              последние годы и Гидролизаты белков растительного происхождения
              находят все большее своё применение в различных индустриях в связи
              с развитием рынка вегетарианских и веганских продуктов и напитков,
              а также из-за расширения ограничений, связанными с этическими
              нормами применения животных белков для производства отдельных
              видов продукции.
            </h5>
          </div>
          <div className="elementorPlant">
            <h3 className="text__h3">
              ДРУГИЕ ПРИМЕНЕНИЯ ФЕРМЕНТАТИВНЫХ ГИДРОЛИЗАТОВ РАСТИТЕЛЬНОГО БЕЛКА
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="elementorPlantCards">
              <div className="elementorPlantCard">
                <img src="/img/pr2.jpg" alt="" />
                <h6>
                  <span>ГРБ </span>используется в качестве белковых обогатителей
                  и вкусо-ароматических комплексов в рецептурах{" "}
                  <span>
                    сухих и влажных кормов и лакомств для домашних питомцев
                  </span>
                </h6>
              </div>
              <div className="elementorPlantCard">
                <img src="/img/pr1.jpg" alt="" />
                <h6>
                  <span>
                    Кормовые добавки, премиксы для с/х животных и птицы, в
                    состав которых входит <br />
                    ГРБ,
                  </span>
                  используются для производства престартерных и стартерных
                  кормов, а также жидких кормовых добавок.
                  <span>HP</span>значительно улучшает кормовую и биологическую
                  ценность готовых кормов
                </h6>
              </div>
              <div className="elementorPlantCard">
                <img src="/img/hcp-fish.jpg" alt="" />
                <h6>
                  <span>ГРБ </span>используются в качестве источника аминного
                  азота (ростового фактора) в составе различных питательных
                  сред, в том числе и для культивирования микроорганизмов,
                  требовательных к составу ПС
                </h6>
              </div>
              <div className="elementorPlantCard">
                <img src="/img/hb-1.jpg" alt="" />
                <h6>
                  Активно используются в рецептурах продуктов функционального,
                  спортивного, диетического и лечебно-профилактического питания,
                  а также для улучшения потребительских свойств и повышения
                  биологической ценности привычных продуктов. Являются
                  популярным ингредиентом в составе бульонов, соусов и маринадов
                  как для частного потребителя, так и в системе HORECA.
                </h6>
              </div>
              <div className="elementorPlantCard">
                <img src="/img/grb-pic.jpg" alt="" />
                <h6>
                  <span>ГРБ </span>необходимы для производства альтернативных
                  протеинов и растительных продуктов — растительного молока,
                  мяса, морепродуктов и др
                </h6>
              </div>
              <div className="elementorPlantCard">
                <img src="/img/lecheb.jpg" alt="" />
                <h6>
                  <span>ГРБ </span>эффективны для{" "}
                  <span>восстанавливающей и лечебной косметики,</span>кремов для
                  рук, лица и тела, шампуней и др
                </h6>
              </div>
            </div>
          </div>
          <div className="fermentationProteinsFood">
            <h3 className="text_h3">
              ФЕРМЕНТАЦИЯ – ОСНОВА ИНДУСТРИИ <br /> АЛЬТЕРНАТИВНЫХ БЕЛКОВ И
              КУЛЬТИВИРУЕМЫХ ПРОДУКТОВ
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="fermentationProteinsFoodText">
              <div className="proteinsFoodTextLeft">
                <img src="/img/fermentationProteinsFoodText.jpg" alt="" />
                <p>
                  Спиртовые и пивные заводы, а также предприятия глубокой
                  переработки зерна смогут производить любую еду
                </p>
              </div>
              <div>
                <h6>
                  В ноябре 2020 года Всемирный экономический форум назвал
                  ферментацию ключевой областью инноваций в мире
                </h6>
                <h6>
                  Ферментация{" "}
                  <span>
                    позволяет использовать микроорганизмы как биофабрики для
                  </span>
                  производства практически любых сложных органических молекул,{" "}
                  <span>
                    в частности, любых белков (мясных, молочных, …), жиров и
                    сахаров с высокой скоростью, в больших объемах и по низким
                    ценам (подробнее о Ферментации см. в разделе …)
                  </span>
                </h6>
                <div className="proteinsFoodTextRightCard">
                  <h6>
                    <span>
                      В последние годы быстро развиваются такие применения, как
                      культивирование мясных и молочных продуктов. Согласно
                      прогнозам{" "}
                    </span>
                    цена культивированного мяса к 2030 году будет в 5 раз, а в
                    2035 году – в 10 раз меньше цены обычного мяса.
                  </h6>
                </div>
                <h6>
                  <span>Уже сегодня ферментация широко используется в </span>
                  производстве спирта, пива, кормовых добавок для животных
                  (аминокислоты, дрожжи, …), пищевых ингредиентов и продуктов
                  питания, витаминов, антибиотиков
                  <span> и многих других продуктов</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="productionFlowChart">
            <h3 className="text__h3">
              УПРОЩЕННАЯ БЛОК-СХЕМА ПРОИЗВОДСТВА ГИДРОЛИЗАТА <br />
              РАСТИТЕЛЬНОГО БЕЛКА В ЖИДКОЙ ФОРМЕ
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <p>
              Для малых предприятий можно предложить значительно <br /> более
              простую и недорогую схему Производства
            </p>
            <Image src="/img/shema-gidro.jpg" />
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="universalProcessLines">
            <h3 className="text__h3">
              УНИВЕРСАЛЬНЫЕ ТЕХНОЛОГИЧЕСКИЕ ЛИНИИ / ОСНОВНЫЕ СЕКЦИИ
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <p>
              Наши специалисты, совместно с ведущими российскими и европейскими
              инжиниринговыми и технологическими компаниями, принимают участие в
              проектировании биотехнологических производств и оснащают их
              оборудованием{" "}
              <span>
                ведущих европейских производителей: CFT Group (Италия), ICF &
                Welko (Италия), GEA Group, Flottweg (Германия), Bertsch-Laska
                (Австрия) и других.
              </span>
            </p>
            <div className="universalProcessLinesCards">
              <div>
                <img
                  style={{ width: "100%", height: "303px" }}
                  src="/img/fergd10.jpg"
                  alt=""
                />
                <li>Отделение подготовки и измельчениях сырья</li>
              </div>
              <div>
                <img
                  style={{ width: "100%", height: "303px" }}
                  src="/img/fergd11.jpg"
                  alt=""
                />
                <li>Секция подготовки ферментов</li>
              </div>
              <div>
                <img
                  style={{ width: "100%", height: "303px" }}
                  src="/img/fergd12.jpg"
                  alt=""
                />
                <li>Секция ферментативного гидролиза</li>
              </div>

              <div>
                <img
                  style={{ width: "100%", height: "303px" }}
                  src="/img/fergd-14.jpg"
                  alt=""
                />
                <li>Секция микро-, ультра- нанофильтрации</li>
              </div>
              <div>
                <img
                  style={{ width: "100%", height: "303px" }}
                  src="/img/fergd15.jpg"
                  alt=""
                />
                <li>Секция концентрирования выпариванием</li>
              </div>
              <div>
                <img
                  style={{ width: "100%", height: "303px" }}
                  src="/img/fergd16.jpg"
                  alt=""
                />
                <li>Секция сушки</li>
              </div>
              <div>
                <img
                  style={{ width: "100%", height: "303px" }}
                  src="/img/fergd17.jpg"
                  alt=""
                />
                <li>Секция упаковки для любых жидких и сухих форм продуктов</li>
              </div>
              <div>
                <img
                  style={{ width: "100%", height: "303px" }}
                  src="/img/fergd31.jpg"
                  alt=""
                />
                <li>Секция очистки продукта</li>
              </div>
            </div>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="engineeringEquipment">
            <h3 className="text__h3">ИНЖИНИРИНГ И ОБОРУДОВАНИЕ</h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="engineeringEquipmentCard">
              <img
                className="engineeringEquipmentCardImg"
                src="/img/EQUIPMENT1.jpg"
                alt=""
              />
              <div>
                <p>
                  Наши специалисты, совместно с ведущими российскими и
                  европейскими инжиниринговыми и технологическими компаниями,
                  проектируют биотехнологические производства, в частности,{" "}
                  <span>для всех описанных в данной презентации проектов,</span>
                  и оснащают их оборудованием ведущих европейских
                  производителей:{" "}
                  <span>
                    CFT Group, ICF & Welko (Италия), GEA Group, Flottweg
                    (Германия), Bertsch-Laska (Австрия) и других
                  </span>
                </p>
                <img src="/img/EQUIPMENT2.jpg" alt="" />
              </div>
            </div>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="bioTasksPharm">
            <h3 className="text__h3">
              ПОЛУЧИТЕ БИОТЕХНОЛОГИЧЕСКИЕ РЕШЕНИЯ ВАШИХ ЗАДАЧ!
            </h3>
            <div
              style={{ border: "1px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="li">
              <p>НАШИ КОМПЕТЕНЦИИ</p>
              <li>
                Разработка и освоение промышленных биотехнологий глубокой
                переработки сырья животного и растительного происхождения и
                производства продуктов для жизни с уникальными биологическими
                свойствами
              </li>
              <li>
                Производство, дистрибуция и применение ферментативного
                гидролизата белка в различных индустриях
              </li>
              <li>
                Проектирование и запуск производств глубокой переработки сырья,
                в том числе лицензионных, обеспечение трансфера технологии,
                особые условия приобретения оборудования и поставки необходимых
                ферментных препаратов
              </li>
            </div>
            <div
              style={{ border: "1px solid#067BC2", background: "#067BC2" }}
            ></div>
            <h4 className="text__h3">
              СВЯЖИТЕСЬ С НАМИ ЛЮБЫМ УДОБНЫМ ДЛЯ ВАС СПОСОБОМ
            </h4>
            <div className="bioTasksButton">
              <Link to="/contact">
                <button>Контакты</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VegetableProtein;
