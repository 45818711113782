import React from "react";
import CardJuiceProduction from "../../Component/technology/CardJuiceProduction";

const JuiceEnzymes = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="grainCardTable">
            <div className="grainBioXim">
              <CardJuiceProduction />
            </div>
            <table>
              <tr>
                <td>Наименование ферментного препарата</td>
                <td>Применение</td>
                <td>Дозировка</td>
                <td>Фасовка</td>
              </tr>
              <tr>
                <th>
                  <p>Pectinex YieldMash Plus/ ПЕКТИНЕКС ЙЕЛД МАШ ПЛЮС</p>
                  <i>Пектинметилэстераза</i>
                </th>
                <th>
                  Ферментный препарат применяется в производстве сока и сокового
                  концентрата из яблок и груш для обработки мезги. Увеличивает
                  выход сока и повышает производственные мощности. Равномерная
                  дальнейшая переработка и высокая степень фильтрования на УФ
                  установке. Уменьшение образования галактуроновой кислоты и
                  метанола. Позволяет получить дополнительный пектин из выжимок
                  и на выходе получать более сухие выжимки
                </th>
                <th>40-80 мл/ 1000 кг яблок </th>
                <th>18 кг</th>
              </tr>

              <tr>
                <th>
                  <p>Amylase AG XXL /АМИЛАЗА АГ ХХЛ</p>
                  <i>
                    Амилоглюкозидаза с большим количеством кислой альфа-амилазы
                  </i>
                </th>
                <th>
                  Применяется для обработки сока после удаления аромата или
                  пастеризации. Предварительно сок должен пройти тепловую
                  обработку с целью желирования крахмала и чтобы позволить
                  ферменту работать. Температура до 70° С. Показатель дозировки
                  сильно зависит от количества крахмала, содержащегося во
                  фруктах.
                </th>
                <th>10 -30 мл/1000 л сока</th>
                <th>18 кг</th>
              </tr>

              <tr>
                <th>
                  <p>
                    Pectinex XXL /<br />
                    ПЕКТИНЕКС ХХЛ
                  </p>
                  <i>Пектинлиаза </i>
                </th>
                <th>
                  Ферментный препарат для обработки сока после отжима/декантинга
                  или после удаления аромата. Пектиназы, базирующиеся в основном
                  на активностях пектинлиазы. Арабиназы как побочная активность.
                  Дозировка основывается на температуре обработки примерено 50⁰
                  С. Работа при температуре окружающей среды может повысить
                  дозировку и/или время.
                </th>
                <th>17 -30 мл/1000 л сока</th>
                <th>18 кг</th>
              </tr>

              <tr>
                <th>
                  <p>Pectinex UF/ПЕКТИНЕКС УФ</p>
                  <i>Полигалактуроназа</i>
                </th>
                <th>
                  Применятся при ультрафильтрации. Содержит пектиновую
                  активность для улучшения скорости потока. Улучшение работы УФ
                  установки, увеличивает скорость потока, увеличение времени
                  работы УФ установки до момента очистки , уменьшение слизи в
                  соке. Время выдержки: 0,5 - 1 час основывается при температуре
                  50 – 55 С.
                </th>
                <th>20-30 мл/1000 л сока</th>
                <th>18 кг</th>
              </tr>

              <tr>
                <th>
                  <p>Pectinex Ultra Color/ПЕКТИНЕКС УЛЬТРА КОЛОР</p>
                  <i>Пектинлиаза </i>
                </th>
                <th>
                  Ферментный препарат для производства соков из ягод. Придает
                  сокам из ягод и винограда яркий, устойчивый цвет и позволяет
                  увеличить выпуск сока. Улучшает прессуемость и обеспечивает
                  равномерность дальнейшей переработки. Подходит для
                  производства органических продуктов.
                </th>
                <th>100-200 мл/тонну ягод</th>
                <th>18 кг</th>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JuiceEnzymes;
