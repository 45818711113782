import React from "react";
import { Link } from "react-router-dom";

const Engineering = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="mainImg">
            <img src="/img/injiniring-ban.jpg" alt="" />
            <h3>Инжиниринг и оборудование биотехнологических производств</h3>
          </div>
          <div className="injiniringText">
            <img src="/img/injiniring-pic.jpg" alt="" />
            <div>
              <p>
                Для всех поставляемых нами биотехнологических решений ваших
                задач специалисты UZBIOPRODUCT совместно с ведущими российскими
                и европейскими технологическими, инжиниринговыми компаниями и
                производителями Оборудования осуществляют:
              </p>
              <ul>
                <li>Технологический аудит</li>
                <li>
                  Трансфер и Адаптацию биотехнологических решений , а также
                  обеспечение ферментами Novozymes ( Дания )
                </li>
                <li>Инжиниринг производств</li>
                <li>
                  Поставку и запуск технологического оборудования CFT Group, ICF
                  & Welko (Италия), GEA Group, Flottweg (Германия),
                  Bertsch-Laska (Австрия) и др.
                </li>
                <li>Непрерывный технологический и технический сервис</li>
              </ul>
            </div>
          </div>
          <div className="universalInjLines">
            <h3 className="text-h3">
              УНИВЕРСАЛЬНЫЕ ТЕХНОЛОГИЧЕСКИЕ ЛИНИИ / ИНЖИНИРИНГ
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="universalInjLinesCard">
              <img src="/img/fergd-5.jpg" alt="" />
              <div>
                <p className="text__h3">
                  Производство ферментативных гидролизатов куриного белка
                  (переработка 100 тн/сутки мясокостного остатка)
                </p>
                <div
                  style={{
                    border: "1.5px solid #48AA43",
                    background: "#48AA43",
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className="universalProcessLines">
            <h3 className="text-h3">
              УНИВЕРСАЛЬНЫЕ ТЕХНОЛОГИЧЕСКИЕ ЛИНИИ / ОСНОВНЫЕ СЕКЦИИ
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="universalProcessLinesCards">
              <div>
                <img
                  style={{ width: "100%", height: "303px" }}
                  src="/img/fergd10.jpg"
                  alt=""
                />
                <li>Отделение подготовки и измельчениях сырья</li>
              </div>
              <div>
                <img
                  style={{ width: "100%", height: "303px" }}
                  src="/img/fergd11.jpg"
                  alt=""
                />
                <li>Секция подготовки ферментов</li>
              </div>
              <div>
                <img
                  style={{ width: "100%", height: "303px" }}
                  src="/img/fergd12.jpg"
                  alt=""
                />
                <li>Секция ферментативного гидролиза</li>
              </div>

              <div>
                <img
                  style={{ width: "100%", height: "303px" }}
                  src="/img/fergd-14.jpg"
                  alt=""
                />
                <li>Секция микро-, ультра- нанофильтрации</li>
              </div>
              <div>
                <img
                  style={{ width: "100%", height: "303px" }}
                  src="/img/fergd15.jpg"
                  alt=""
                />
                <li>Секция концентрирования выпариванием</li>
              </div>
              <div>
                <img
                  style={{ width: "100%", height: "303px" }}
                  src="/img/fergd16.jpg"
                  alt=""
                />
                <li>Секция сушки</li>
              </div>
              <div>
                <img
                  style={{ width: "100%", height: "303px" }}
                  src="/img/fergd17.jpg"
                  alt=""
                />
                <li>Секция упаковки для любых жидких и сухих форм продуктов</li>
              </div>
              <div>
                <img
                  style={{ width: "100%", height: "303px" }}
                  src="/img/fergd31.jpg"
                  alt=""
                />
                <li>Секция очистки продукта</li>
              </div>
            </div>
          </div>
          <div className="elementor-widget-wraP">
            <h3 className="text-h3">
              ОБОРУДОВАНИЕ ДЛЯ ПРОИЗВОДСТВА ГИДРОЛИЗОВАННОЙ, БИОДОСТУПНОЙ И
              ГИПОАЛЛЕРГЕННОЙ ЗЕРНОВОЙ ОСНОВЫ КОРМОВ
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="elementor-element-populated">
              <img src="/img/gidro-injiniring.jpg" alt="" />
              <div>
                <ul>
                  <li>Рабочий бак</li>
                  <li>Диспергатор</li>
                  <li>Электродвигатель</li>
                  <li>Поворотные клапаны</li>
                  <li>Термометр</li>
                </ul>
                <p>
                  Предлагаемая линейка недорогого запатентованного российского ,
                  либо европейского оборудования закрывает потребности любых по
                  размеру и продуктивности предприятий животноводства Мы
                  изготавливаем, поставляем и осуществляем запуск
                  технологического комплекса «под ключ»
                </p>
              </div>
            </div>
          </div>
          <div
            style={{ border: "1.5px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="bioTasksPharm">
            <h3 className="text__h3">
              ПОЛУЧИТЕ БИОТЕХНОЛОГИЧЕСКИЕ РЕШЕНИЯ ВАШИХ ЗАДАЧ!
            </h3>
            <div
              style={{ border: "1px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="li">
              <p>НАШИ КОМПЕТЕНЦИИ</p>
              <li>
                Разработка и освоение промышленных биотехнологий глубокой
                переработки сырья животного и растительного происхождения и
                производства продуктов для жизни с уникальными биологическими
                свойствами
              </li>
              <li>
                Производство, дистрибуция и применение ферментативного
                гидролизата белка в различных индустриях
              </li>
              <li>
                Проектирование и запуск производств глубокой переработки сырья,
                в том числе лицензионных, обеспечение трансфера технологии,
                особые условия приобретения оборудования и поставки необходимых
                ферментных препаратов
              </li>
            </div>
            <div
              style={{ border: "1px solid#067BC2", background: "#067BC2" }}
            ></div>
            <h4 className="text__h3">
              СВЯЖИТЕСЬ С НАМИ ЛЮБЫМ УДОБНЫМ ДЛЯ ВАС СПОСОБОМ
            </h4>
            <div className="bioTasksButton">
              <Link to="/contact">
                <button>Контакты</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Engineering;
