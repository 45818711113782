import { Image } from "antd";
import React from "react";
import { CgFileDocument } from "react-icons/cg";
import { Link } from "react-router-dom";

const CardJuiceProduction = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="mainImg">
            <img src="/img/sok.jpg" alt="" />
            <h3>Технологии, ферменты и оборудование для производства сока</h3>
          </div>
          <div className="cardTick">
            <CgFileDocument style={{ fontSize: "26px", color: "red" }} />
            <p>Новые биорешения</p>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <p className="beverageText">
            Уникальный новый мезговой фермент Pectinex YieldMash Plus для
            производства концентрированных соков из яблок. Подробности и образцы
            по запросу
          </p>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="juiceProductionOffer">
            <h3 className="text-h3">
              ЧТО МЫ ПРЕДЛАГАЕМ ДЛЯ ПРОИЗВОДСТВА КОНЦЕНТРИРОВАННОГО СОКА?
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="juiceProductionOffer__Card">
              <div>
                <img src="/img/juiceProductionOffer.jpg" alt="" />
              </div>
              <div>
                <h5>Только ферменты могут сделать эту работу</h5>
                <p>
                  Все фрукты и овощи содержат разнообразные высокомолекулярные
                  субстраты, которые могут помешать процессу экстракции сока и
                  осветлению:
                </p>
                <ul>
                  <li>КРАХМАЛ</li>
                  <li>ПЕКТИНЫ</li>
                  <li>ГЕМИЦЕЛЛЮЛОЗЫ</li>
                  <li>ЦЕЛЛЮЛОЗЫ</li>
                  <li>БЕЛКИ</li>
                </ul>
                <h5>
                  Разрушение этих субстратов возможно только с помощью
                  ферментов!
                </h5>
                <p>
                  Белки / танины могут быть осаждены (с помощью бентонита,
                  желатина, кизельгура и т.п.) и удалены
                </p>
                <div className="juiceProductionOfferCards">
                  <div>
                    <div
                      style={{
                        border: "1.5px solid #48AA43",
                        background: "#48AA43",
                      }}
                    ></div>
                    <h5>
                      Решения для всех видов фруктов и овощей в соковом сегменте
                    </h5>
                    <p>
                      Ферменты – это единственный компонент, который можно
                      использовать несколько раз во время производственного
                      процесса
                    </p>
                    <ul>
                      <li>«Основа сокового производства»</li>
                      <li>Несколько сфер применения:</li>
                      <li>✓Обработка мезги</li>
                      <li>✓Осветление</li>
                      <li>✓Фильтрация</li>
                      <li>Подходит для всех овощей и фруктов</li>
                    </ul>
                  </div>
                  <div>
                    <div
                      style={{
                        border: "1.5px solid #F49F0A",
                        background: "#F49F0A",
                      }}
                    ></div>
                    <h5>ДОСТАВЛЯЯ ПРЕИМУЩЕСТВА В…</h5>
                    <p>
                      О каком бы шаге в производстве сока мы не говорили,
                      ферменты добавляют экономические и качественные
                      преимущества для производителей соков:
                    </p>
                    <ul>
                      <li>Увеличение выхода сока и мощностей</li>
                      <li>Увеличение процесса осветления и стабилизации</li>
                      <li>Улучшение фильтрации</li>
                      <li>
                        Преимущества для сохранения таких компонентов как
                        бета-каротин и т.п.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="juiceProductionTechnology">
            <h3 className="text-h3">
              ОБЗОР ТЕХНОЛОГИИ ПРОИЗВОДСТВА ЯБЛОЧНОГО / ГРУШЕВОГО СОКА
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="juiceProductionTechnologyImg">
              <Image src="/img/juiceProductionTechnology.jpg" />
            </div>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="pectinuxUltraColor">
            <h3 className="text-h3">
              PECTINEX ULTRA COLOR. <br /> ОБНОВЛЕННАЯ РЕЦЕПТУРА: БОЛЬШЕ ЦВЕТА И
              ВЫШЕ ИНТЕНСИВНОСТЬ
            </h3>
            <div
              style={{ border: "1px solid #067BC2", background: "#067BC2" }}
            ></div>
            <div className="pectinuxUltraColorCards">
              <div className="pectinuxUltraColorCard">
                <h5 className="text_h3">Обработка ягоды и цветных фруктов</h5>
                <p>УСЛОВИЯ И ЦЕЛИ:</p>
                <h6>
                  <img src="/img/logored.png" alt="" />
                  Расщипление пектина для улучшения прессования
                </h6>
                <h6>
                  <img src="/img/logored.png" alt="" />
                  Более высокое экстрагирование цвета и полифенолов / антоцианов
                </h6>
                <h6>
                  <img src="/img/logored.png" alt="" />
                  Повышенная степень стабильности цвета в процессе хранения
                </h6>
                <h6>
                  <img src="/img/logored.png" alt="" />
                  Чистый сок и концентрат – нет или слегка увеличение NTU*
                </h6>
                <h4>
                  ВЫВОД: обновленная рецептура Pectinex Ultra Color включает в
                  себя более необходимые побочные активности и меньше
                  антоцианазы (anthocyanase)
                </h4>
              </div>
              <img src="/img/pectinuxUltraColor.jpg" alt="" />
            </div>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="berriesGrapes">
            <h3 className="text-h3">
              ЯГОДЫ И КРАСНЫЙ ВИНОГРАД. <br /> ПРОИЗВОДСТВЕННАЯ СХЕМА С
              ИСПОЛЬЗОВАНИЕМ PECTINEX ULTRA COLOR
            </h3>
            <div
              style={{ border: "1px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="berriesGrapesImg">
              <img src="/img/berriesGrapes1.jpg" alt="" />
              <img
                className="berriesGrapesImgTwo"
                src="/img/berriesGrapes23.jpg"
                alt=""
              />
            </div>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="secondaryProcessing">
            <h3 className="text-h3">
              ВТОРИЧНАЯ ОБРАБОТКА МЕЗГИ (ОРИГИНАЛЬНОЕ НАЗВАНИЕ AFP*-ТЕХНОЛОГИЯ)
            </h3>
            <div
              style={{ border: "1px solid #067BC2", background: "#067BC2" }}
            ></div>
            <div className="secondaryProcessingCards">
              <img src="/img/secondaryProcessing.jpg" alt="" />
              <div>
                <p>
                  Вторичная обработка мезги – это дополнительный путь увеличения
                  экономии при производстве сока
                </p>
                <p>Это требует некоторых дополнений в производственной линии</p>
                <p>
                  У нашей компании существует длительный опыт работы с данной
                  технологией в разных странах и мы готовы предложить наши
                  услуги в реализации данной технологии
                </p>
                <h4>
                  С данной технологией реалистично получить 1 кг сокового
                  концентрата (70°Bx) из 6 кг яблок (12°Bx)
                </h4>
                <h6>
                  * AFP – advanced fruit processing (продвинутая степень
                  обработки)
                </h6>
              </div>
            </div>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="secondaryProcessingTwo">
            <h3 className="text-h3">
              ВТОРИЧНАЯ ОБРАБОТКА МЕЗГИ (ОРИГИНАЛЬНОЕ НАЗВАНИЕ AFP*-ТЕХНОЛОГИЯ)
            </h3>
            <div
              style={{ border: "1px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <Image src="/img/secondaryProcessing2.jpg" alt="" />
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="novozymesBeveragePage">
            <img src="/img/novozymes.jpg" alt="" />
            <div>
              <h5 className="text-h3">
                Почему крупные производители на рынке концентратов предпочитают
                работать на ферментах Новозаймс
              </h5>
              <div
                style={{ border: "1.5px solid #F49F0A", background: "#F49F0A" }}
              ></div>
              <div className="company-leader-text">
                <p>
                  {" "}
                  <img src="/img/checked.png" alt="" /> Великолепное качество
                  продукции, которое соответствует потребностям клиентов
                </p>
                <p>
                  {" "}
                  <img src="/img/checked.png" alt="" /> Запуск новых продуктов
                  только после успешных лабораторных и промышленных испытаний
                </p>
                <p>
                  {" "}
                  <img src="/img/checked.png" alt="" /> Испытания в различных
                  условиях, на разных яблоках, прессах, клиентах
                </p>
                <p>
                  {" "}
                  <img src="/img/checked.png" alt="" /> Улучшенные результаты
                  легко увидеть
                </p>
                <p>
                  {" "}
                  <img src="/img/checked.png" alt="" /> Комплексные и прозрачные
                  документы и сертификаты по продуктам(PDS, MSDS, Non-GMO,
                  Food-grade, Kosher etc.)
                </p>
                <p>
                  {" "}
                  <img src="/img/checked.png" alt="" /> Мы предлагаем больше чем
                  просто ферменты: комплексный подход и решения, включающие в
                  себя техническую поддержку, испытания в лаборатории.
                </p>
              </div>
            </div>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="foodProducts">
            <div className="foodProductsImg">
              <img src="/img/foodProducts1.jpg" alt="" />
              <br />
              <img src="/img/leaderslft.jpg" alt="" />
            </div>
            <div className="foodProductsCards">
              <h3 className="text_h3">ПИЩЕВЫЕ ПРОДУКТЫ</h3>
              <div
                style={{ border: "1.5px solid #F49F0A", background: "#F49F0A" }}
              ></div>
              <div className="foodProductsCard">
                <p>
                  CFT Group предлагает самые передовые решения для переработки
                  томатов и фруктов, для производства соусов, соков,
                  кубетированных и очищенных от кожуры томатов, а также кетчупа
                  и фруктового пюре.
                </p>

                <p>
                  Группа достигла совершенства в пищевой промышленности
                  благодаря широкому спектру уникальных машин, таких как
                  экстракторы, дезактиваторы ферментов, пиллеры, стерилизаторы,
                  вакуумно-выпарные установки с низким потреблением
                  электроэнергии и асептические наполнители.
                </p>
              </div>
              <div className="foodProductssCard">
                <div>
                  <h6>TOMATO & PROCESSED VEGETABLES</h6>
                  <img src="/img/tomato.jpg" alt="" />
                </div>
                <div>
                  <h6>FRUITS</h6>
                  <img src="/img/fruits.jpg" alt="" />
                </div>
                <div>
                  <h6>EDIBLE OIL</h6>
                  <img src="/img/oil.jpg" alt="" />
                </div>
                <div>
                  <h6>FRESH VEGETABLES</h6>
                  <img src="/img/fresh.jpg" alt="" />
                </div>
                <div>
                  <h6>SPREADABLE & PROCESSED CHEESE</h6>
                  <img src="/img/spreadl.jpg" alt="" />
                </div>
                <div>
                  <h6>FOOD PREPARATION</h6>
                  <img src="/img/food.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="company-competences">
            <div>
              <h3 className="text-h3">25 ЛЕТ ЛИДЕРСТВА</h3>
              <img src="/img/ferment.jpg" alt="" />
              <h6>
                Биотехнологии и технологические линии , поставляемые партнёрами
                UZBIOPRODUCT , универсальны и используются для индустриального
                производства широкой гаммы гидролизатов белка с заданными
                свойствами из различных видов сырья
              </h6>
            </div>
            <div className="company-competences__group">
              <div className="company-competences__text">
                <img src="/img/1.jpg" alt="" />
                <p>
                  Создание, внедрение и развитие промышленных биотехнологий для
                  глубокой переработки сырья животного и растительного
                  происхождения, включая малоценные и вторичные его виды
                </p>
              </div>
              <div className="company-competences__text">
                <img src="/img/2.jpg" alt="" />
                <p>
                  Производство и реализация ферментативных гидролизатов белка, а
                  также продуктов с высокой биологической ценностью на основе
                  этих гидролизатов
                </p>
              </div>
              <div className="company-competences__text">
                <img src="/img/3.jpg" alt="" />
                <p>
                  Проектирование, инжиниринговые услуги и поставка
                  технологического оборудования от лучших европейских компаний
                  для биотехнологических проектов
                </p>
              </div>
              <div className="company-competences__text">
                <img src="/img/4.jpg" alt="" />
                <p>
                  Финансирование и участие в реализации инвестиционных
                  биотехнологических проектов, в том числе инновационных
                </p>
              </div>
              <div className="company-competences__text">
                <img src="/img/5.jpg" alt="" />
                <p>
                  Дистрибуция и адаптация к потребностям клиентов ферментов и
                  технологий Novozymes A/S (Дания), гидролизатов белков и других
                  инструментов глубокой переработки сырья в различных индустриях
                </p>
              </div>
            </div>
          </div>
          <div
            style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
          ></div>
          <div className="bioTasks">
            <h3 className="texth3">
              ПОЛУЧИТЕ БИОТЕХНОЛОГИЧЕСКИЕ РЕШЕНИЯ ВАШИХ ЗАДАЧ!
            </h3>
            <div
              style={{ border: "1px solid #48AA43", background: "#48AA43" }}
            ></div>
            <div className="li">
              <li>Технологический аудит</li>
              <li>Трансфер готовых биотехнологических решений</li>
              <li>
                Поставка ферментов и других биотехнологических инструментов
              </li>
              <li>
                Инжиниринг и особые условия поставки технологического
                оборудования
              </li>
              <li>Технологический сервис</li>
            </div>
            <div
              style={{ border: "1px solid#067BC2", background: "#067BC2" }}
            ></div>
            <h4 className="text__h3">
              СВЯЖИТЕСЬ С НАМИ ЛЮБЫМ УДОБНЫМ ДЛЯ ВАС СПОСОБОМ
            </h4>
            <div className="bioTasksButton">
              <Link to="/contact">
                <button>Контакты</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardJuiceProduction;
