import React from "react";
import CardBeverageProduction from "../../Component/technology/CardBeverageProduction";

const GrainMilkProduction = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="grainCardTable">
            <div className="grainBioXim">
              <CardBeverageProduction />
            </div>
            <table>
              <tr>
                <td>Наименование ферментного препарата</td>
                <td>Применение</td>
                <td>Дозировка</td>
                <td>Фасовка</td>
              </tr>
              <tr>
                <th>
                  <p>BAN 480 L/БАН 480 ЛС</p>
                  <i>Альфа-амилаза</i>
                </th>
                <th>
                  Применяется при производстве зернового молока. С помощью
                  данного фермента происходит гидролиз крахмала до декстринов,
                  олио сахаридов, снижение вязкости. Дозировка (основывается на
                  массе сухого вещества зерна). Оптимальная температура 85-90 С.
                </th>
                <th>0,2-0,4% на 1 тонну муки </th>
                <th>25 кг</th>
              </tr>

              <tr>
                <th>
                  <p>Viscoferm/ВИСКОФЕРМ</p>{" "}
                  <i>
                    Комплексный препарат Содержит ксиланазную, целлюлазную,
                    альфа-амилазную, бета-глюканазную и др. активности
                  </i>
                </th>
                <th>
                  Комплексный препарат для всех видов сырья. Применяется для
                  снижения вязкости при переработке высококонцентрированных
                  замесов (22–30% СВ). Оптимальная температура 60-65 С, время
                  выдержки 40-60 минут.
                </th>
                <th>0,1-0,4% </th>
                <th>25 кг</th>
              </tr>

              <tr>
                <th>
                  <p>
                    Amylase AG 300 L / <br /> Амилаза АГ 300Л
                  </p>
                  <i>Глюкоамилаза </i>
                </th>
                <th>
                  Увеличение декстрозного эквивалента (DE)(производство
                  глюкозы), создание улучшенных вкусовых характеристик и
                  повышение натуральной сладости напитка. Оптимальная
                  температура 60-65 С.
                </th>
                <th>0,2-0,4% </th>
                <th>20 кг</th>
              </tr>

              <tr>
                <th>
                  <p>
                    Fungamyl 800 L/ <br /> Фунгамил 800L{" "}
                  </p>
                  <i>Грибная α-амилаза</i>
                </th>
                <th>
                  Увеличение декстрозного эквивалента (DE)(производство
                  глюкозы), создание улучшенных вкусовых характеристик и
                  повышение натуральной сладости напитка. Оптимальная
                  температура 45-55 С.
                </th>
                <th>0,2-04% </th>
                <th>25 кг</th>
              </tr>

              <tr>
                <th>
                  <p>
                    Maltogenase 2X/ <br /> Мальтогеназа 2X
                  </p>
                  <i>Мальтогенная амилаза </i>
                </th>
                <th>
                  Увеличение декстрозного эквивалента (DE)(производство
                  глюкозы), создание улучшенных вкусовых характеристик и
                  повышение натуральной сладости напитка. Оптимальная
                  температура 70-80 С.
                </th>
                <th>0,2-0,4% </th>
                <th>25 кг</th>
              </tr>

              <tr className="tableCenter">
                <th></th>
                <th>
                  <center>ВСПОМОГАТЕЛЬНЫЕ СРЕДСТВА</center>
                </th>
                <th></th>
                <th></th>
              </tr>

              <tr>
                <th>
                  <p>Лактрол</p>
                  <i>Антибиотик</i>
                </th>
                <th>
                  Основной компонент данного препарата является вирджиниамицин,
                  который в отличие от пенициллиновой группы не вызывает
                  привыкания у посторонней микрофлоры, а также имеет более
                  низкие нормы задачи.
                </th>
                <th>0,10-1,0 г/м3 сусла </th>
                <th>25 кг</th>
              </tr>

              <tr>
                <th>
                  <p>Вектор «Экстра»</p>
                  <i>Антибиотик </i>
                </th>
                <th>
                  Препарат для снижения уровня посторонней микрофлоры при
                  производстве спирта.
                </th>
                <th>1,0-2,0 г/м3 сусла </th>
                <th>1 кг</th>
              </tr>

              <tr>
                <th>
                  <p>Дрожжи «Angel» </p>
                </th>
                <th>
                  Термоталерантные, осмофильные сухие спиртовые дрожжи,
                  разработанные для переработки сусла с высокой концентрацией
                  СВ.
                </th>
                <th>0,25-0,50 кг/м3 сусла </th>
                <th>10 кг</th>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrainMilkProduction;
