import React from "react";
import { TiTickOutline } from "react-icons/ti";

const CompanyAbout = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="company">
          <div className="card">
            <div className="mainImg">
              <img src="/img/company.jpg" alt="" />
              <h3>Биотехнологические решения ваших задач</h3>
            </div>
            <div className="company-product">
              <div>
                <img src="/img/company2.jpg" alt="" />
              </div>
              <div>
                <p>
                  Биотехнологические проекты, очень долго ожидавшие своего
                  времени в России, последние 3-4 года растут как на дрожжах.
                  Сотни малых и средних предприятий разных индустрий приступили
                  к производству продуктов с высокими биологическими и
                  потребительскими свойствами. На сегодняшний день, действующие
                  биотехнологические производства глубокой переработки зерна
                  привлекли инвестиций на сумму порядка 600 млн. ЕВРО.
                  <br /> <br /> Несмотря на достижения последних лет, российские
                  рынки биотехнологий и биотехнологической продукции все ещё в
                  15 раз меньше рынков стран — лидеров с аналогичным по
                  численности населением.
                  <br />
                  <br /> Поэтому мы сейчас с оптимизмом смотрим в будущее
                  российских биотехнологий, тем более, что работаем с
                  жизнеобеспечивающими предприятиями.
                  <br />
                  <br /> Да и чем масштабнее кризис в России , тем быстрее и
                  лучше у нас создаются и осваиваются новые идеи, технологии и
                  продукты.
                  <br />
                  <br />
                  Приглашаем вас в интересный и стремительно развивающийся Мир
                  Биоэкономики
                  <br />
                  <br />
                </p>
                <span>Команда UZBIOPRODUCT</span>
              </div>
            </div>
            <div
              style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
            ></div>

            <div className="companyProduct-solutions">
              <h2 className="text-h3">
                UZBIOPRODUCT - ОДИН ИЗ ЛИДЕРОВ В ОБЛАСТИ РАЗРАБОТКИ И
                ПРОМЫШЛЕННОГО ОСВОЕНИЯ БИОТЕХНОЛОГИЧЕСКИХ РЕШЕНИЙ ДЛЯ :
              </h2>
              <div
                style={{ border: "1px solid blue", background: "blue" }}
              ></div>
              <div className="company-leader">
                <div className="company-leader-text">
                  <p>
                    {" "}
                    <img src="/img/checked.png" alt="" /> Глубокой переработки
                    зерна{" "}
                  </p>
                  <p>
                    {" "}
                    <img src="/img/checked.png" alt="" /> Производства и
                    применений гидролизатов белков растительного и животного
                    происхождения
                  </p>
                  <p>
                    {" "}
                    <img src="/img/checked.png" alt="" /> Производства спирта{" "}
                  </p>
                  <p>
                    {" "}
                    <img src="/img/checked.png" alt="" /> Крахмалопаточной
                    промышленности{" "}
                  </p>
                  <p>
                    {" "}
                    <img src="/img/checked.png" alt="" /> Переработки фруктов,
                    ягод и овощей{" "}
                  </p>
                  <p>
                    {" "}
                    <img src="/img/checked.png" alt="" /> Производства напитков
                    из зерновых культур/растительного молока{" "}
                  </p>
                  <p>
                    {" "}
                    <img src="/img/checked.png" alt="" /> Создания
                    функциональных кормов и лакомств для домашних животных{" "}
                  </p>
                  <p>
                    {" "}
                    <img src="/img/checked.png" alt="" /> Производства
                    комбикормов и премиксов для с/х животных
                  </p>
                  <p>
                    {" "}
                    <img src="/img/checked.png" alt="" /> Микробиологии и
                    биотехнологий{" "}
                  </p>
                </div>
              </div>
              <div
                style={{ border: "1px solid #E0E0E0", background: "#E0E0E0" }}
              ></div>
            </div>

            <div className="company-competences">
              <div>
                <h3>НАШИ КОМПЕТЕНЦИИ</h3>
                <img src="/img/bugdoy.jpg" alt="" />
              </div>
              <div className="company-competences__group">
                <div className="company-competences__text">
                  <img src="/img/1.jpg" alt="" />
                  <p>
                    Создание, внедрение и развитие промышленных биотехнологий
                    для глубокой переработки сырья животного и растительного
                    происхождения, включая малоценные и вторичные его виды
                  </p>
                </div>
                <div className="company-competences__text">
                  <img src="/img/2.jpg" alt="" />
                  <p>
                    Производство и реализация ферментативных гидролизатов белка,
                    а также продуктов с высокой биологической ценностью на
                    основе этих гидролизатов
                  </p>
                </div>
                <div className="company-competences__text">
                  <img src="/img/3.jpg" alt="" />
                  <p>
                    Проектирование, инжиниринговые услуги и поставка
                    технологического оборудования от лучших европейских компаний
                    для биотехнологических проектов
                  </p>
                </div>
                <div className="company-competences__text">
                  <img src="/img/4.jpg" alt="" />
                  <p>
                    Финансирование и участие в реализации инвестиционных
                    биотехнологических проектов, в том числе инновационных
                  </p>
                </div>
                <div className="company-competences__text">
                  <img src="/img/5.jpg" alt="" />
                  <p>
                    Дистрибуция и адаптация к потребностям клиентов ферментов и
                    технологий Novozymes A/S (Дания), гидролизатов белков и
                    других инструментов глубокой переработки сырья в различных
                    индустриях
                  </p>
                </div>
              </div>
            </div>
            <div className="company-partners">
              <h3>ПАРТНЕРЫ</h3>
              <div style={{ border: "1px solid red", background: "red" }}></div>
              <div className="partner-advertising">
                <div className="partners-img">
                  <img
                    style={{ width: "460px", height: "142px" }}
                    src="/img/novozymcs.jpg"
                    alt=""
                  />
                </div>
                <p>
                  Компания «Новозаймс» ( Дания ), создавая уникальные
                  ферментативные комплексы на основе обнаруженных в природе
                  микроорганизмов — более 70 лет, является лидером в области
                  инновационных решений для биотехнологических процессов в
                  различных отраслях пищевой промышленности. Расположенные по
                  всему миру производственные мощности компании «Новозаймс»
                  позволяют обеспечить доступность товара во всех регионах,
                  учитывая стандарты безопасности и качества мировых и локальных
                  рынков. Основные преимущества применения ферментных
                  препаратов, производимых «Новозаймс», — возможность вести
                  технологические процессы на высочайшем производственном уровне
                  при максимальном сохранении энергии предприятия и природных
                  ресурсов.
                  <br />
                  <br />
                  Партнёр UZBIOPRODUCT — в течение 25 лет Эксклюзивный
                  дистрибьютор в РФ ферментных препаратов компании Новозаймс для
                  производства спирта, напитков из зерновых культур, глубокой
                  переработки зерна, гидролизатов белка и других
                  промышленностей.
                </p>
              </div>
              <div
                style={{ border: "1px solid #48AA43", background: "#48AA43" }}
              ></div>
              <div className="partner-advertising">
                <div className="partners-img">
                  <img
                    style={{ width: "276px", height: "292px" }}
                    src="/img/leaderslft.jpg"
                    alt=""
                  />
                </div>
                <p>
                  В компетенцию группы компаний CFT, с главным офисом в г.
                  Парма, входит проектирование, инжиниринг и производство
                  оборудования, установок и комплексных линий «под ключ» для
                  пищевой промышленности и производства напитков. Многолетний
                  опыт и ноу-хау таких ведущих брэндов как: Rossi & Catelli,
                  Manzini, Sima, Comaco, SBC, Comac и Milk Project,
                  объединившихся в холдинг CFT, позволяют компании предложить
                  полный портфель продуктов, разработанных на основе самых
                  передовых технологий и охватывающих полный спектр промышленных
                  решений для пищевой отрасли. Ежедневно миллионы тонн свежих
                  продуктов перерабатываются на нашем оборудовании и миллионы
                  банок, бутылок и других ёмкостей заполняются и обрабатываются
                  на наших машинах и линиях
                  <br />
                  <br />
                  Партнёр UZBIOPRODUCT — владелец биотехнологий производства
                  гидролизатов белка и эксклюзивный дистрибьютор инижинириговых
                  решений и технологических линий CFT для их производства.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyAbout;
