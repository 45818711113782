import React from "react";
import { Link } from "react-router-dom";

const CardPharmaceutical = () => {
  return (
    <div className="relative">
      <div className="body">
        <div className="card">
          <div className="mainImg">
            <img src="/img/pharmaceutical.jpg" alt="" />
            <h3>
              ПРОИЗВОДСТВО ФАРМСУБСТАНЦИЙ С использованием ферментативной
              обработки сырья
            </h3>
          </div>
          <div className="glikoGag">
            <h3 className="texth3">
              ГЛИКОЗАМИНОГЛИКАНЫ И МУКОПОЛИСАХАРИДЫ (ГАГ)
            </h3>
            <div
              style={{ border: "1.5px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="glikoGagCard">
              <img src="/img/gliko.jpg" alt="" />
              <div>
                <p>
                  Гликозаминогликаны и мукополисахариды,{" "}
                  <span>
                    также известные как ГАГ, широко распространены в животных
                    тканях.
                  </span>
                </p>
                <p>
                  <span>Чаще всего их</span>экстрагируют из таких видов сырья,
                  как лёгкие, слизистая оболочка кишечников, трахеи, петушиные
                  гребни, шкуры и т.д.
                </p>
                <div className="glikoCard">
                  <p>
                    ГАГ широко применяются в медицине, ветеринарии,
                    фармацевтической и косметической индустриях.
                  </p>
                  <p>
                    Наиболее важными и коммерчески успешными в линейке продуктов
                    ГАГ, являются такие фармсубстанции, как гепарин и
                    гиалуроновая кислота, активно применяемая в косметической
                    индустрии
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="gagExtraction">
            <h3 className="texth3">
              ПРЕИМУЩЕСТВА ЭКСТРАКЦИИ ГАГ ИСПОЛЬЗОВАНИЕМ ФЕРМЕНТНЫХ ТЕХНОЛОГИЙ
            </h3>
            <div
              style={{ border: "1px solid #48AA43", background: "#48AA43" }}
            ></div>
            <div className="gagExtractionMain">
              <div className="gagExtractionLeft">
                <h5>ЭКСТРАКЦИЯ ГАГ (общий принцип):</h5>
                <p>
                  В процессе экстракции происходит разрушение окружающих тканей
                  и ядерных белков, которые соединены с ГАГ ковалентной связью.
                  ГАГ можно высвобождать либо щелочным, либо ферментативным
                  гидролизом. При использовании ферментов протеолитического
                  действия необходимость в традиционном щелочном гидролизе
                  отпадает!
                </p>
                <div className="gagExtractionLeftCard">
                  <h4>
                    ПРЕИМУЩЕСТВА ИСПОЛЬЗОВАНИЯ ФЕРМЕНТАТИВНОГО СПОСОБА
                    ЭКСТРАКЦИИ ГАГ В СРАВНЕНИИ С ЩЕЛОЧНЫМ:
                  </h4>
                  <ul>
                    <li>Повышается выход и качество продуктов</li>
                    <li>Снижаются затраты на производство</li>
                    <li>Снижается количество стоков и твердых отходов</li>
                    <li>
                      Обеспечивается стабильность и гибкость процесса экстракции
                    </li>
                  </ul>
                </div>
              </div>
              <img src="/img/gagExtraction.jpg" alt="" />
            </div>
          </div>
          <div className="generalSchemeHeparin">
            <h3 className="texth3">ОБЩАЯ СХЕМА ЭКСТРАКЦИИ ГЕПАРИНА</h3>
            <div
              style={{ border: "1px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="generalShemeCards">
              <div className="generalShemeCard">
                <img src="/img/cheme1.png" alt="" />
                <h4>СЫРЬЁ:</h4>
                <ul>
                  <li>Слизистая оболочка кишечников животных</li>
                  <li>Лёгкие</li>
                  <li>Трахеи</li>
                  <li>Прочее</li>
                </ul>
              </div>
              <div className="generalShemeCard">
                <img src="/img/cheme2.png" alt="" />
                <h4>ПРЕДВАРИТЕЛЬНАЯ ОБРАБОТКА:</h4>
                <ul>
                  <li>Промывка подпрессовой жидкостью</li>
                  <li>Регулирование pH</li>
                </ul>
              </div>
              <div className="generalShemeCard">
                <img src="/img/cheme3.png" alt="" />
                <h4>ДОЗИРОВАНИЕ ФЕРМЕНТОВ И ГИДРОЛИЗ:</h4>
                <ul>
                  <li>Нагрев и выдерживание при оптимальной температуре</li>
                  <li>
                    Мониторинг pH, градусов Брикса и степени гидролиза до
                    желаемой конечной точки
                  </li>
                  <li>Тепловая инактивация ферментов</li>
                </ul>
              </div>
              <div className="generalShemeCard">
                <img src="/img/cheme4.png" alt="" />
                <h4>ПОСЛЕДУЮЩИЕ ПРОЦЕССЫ:</h4>
                <ul>
                  <li>Переработка экстракта в сырой гепарин</li>
                  <li>Фильтрация</li>
                  <li>Абсорбция смолой</li>
                  <li>Промывка хлоридом натрия</li>
                  <li>Спиртовое осаждение</li>
                  <li>Сушка</li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="generalSchemeHeparin">
            <h3 className="texth3">
              ОБЩАЯ СХЕМА ЭКСТРАКЦИИ СУЛЬФАТА ХОНДРОИТИНА
            </h3>
            <div
              style={{ border: "1px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="generalShemeCards">
              <div className="generalShemeCard">
                <img src="/img/cheme1.png" alt="" />
                <h4>СЫРЬЁ:</h4>
                <ul>
                  <li>Хрящи коров, свиней, куриц или морских видов</li>
                </ul>
              </div>
              <div className="generalShemeCard">
                <img src="/img/cheme2.png" alt="" />
                <h4>ПРЕДВАРИТЕЛЬНАЯ ОБРАБОТКА:</h4>
                <ul>
                  <li>Промывка и удаление соединительных тканей</li>
                  <li>Добавление воды</li>
                  <li>Регулирование pH</li>
                  <li>Нагрев</li>
                </ul>
              </div>
              <div className="generalShemeCard">
                <img src="/img/cheme3.png" alt="" />
                <h4>ДОЗИРОВАНИЕ ФЕРМЕНТОВ И ГИДРОЛИЗ:</h4>
                <ul>
                  <li>Нагрев и выдерживание при оптимальной температуре</li>
                  <li>
                    Мониторинг pH, градусов Брикса и степени гидролиза до
                    желаемой конечной точки
                  </li>
                  <li>Тепловая инактивация ферментов</li>
                </ul>
              </div>
              <div className="generalShemeCard">
                <img src="/img/cheme4.png" alt="" />
                <h4>ПОСЛЕДУЮЩИЕ ПРОЦЕССЫ:</h4>
                <ul>
                  <li>Дальнейшая обработка сульфата хондроитина</li>
                  <li>Фильтрация</li>
                  <li>Очистка</li>
                  <li>Сушка</li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="bioTasksPharm">
            <h3 className="texth3">
              ПОЛУЧИТЕ БИОТЕХНОЛОГИЧЕСКИЕ РЕШЕНИЯ ВАШИХ ЗАДАЧ!
            </h3>
            <div
              style={{ border: "1px solid #EE3A43", background: "#EE3A43" }}
            ></div>
            <div className="li">
              <p>НАШИ КОМПЕТЕНЦИИ</p>
              <li>
                Разработка и освоение промышленных биотехнологий глубокой
                переработки сырья животного и растительного происхождения и
                производства продуктов для жизни с уникальными биологическими
                свойствами
              </li>
              <li>
                Производство, дистрибуция и применение ферментативного
                гидролизата белка в различных индустриях
              </li>
              <li>
                Проектирование и запуск производств глубокой переработки сырья,
                в том числе лицензионных, обеспечение трансфера технологии,
                особые условия приобретения оборудования и поставки необходимых
                ферментных препаратов
              </li>
            </div>
            <div
              style={{ border: "1px solid#067BC2", background: "#067BC2" }}
            ></div>
            <h4 className="text__h3">
              СВЯЖИТЕСЬ С НАМИ ЛЮБЫМ УДОБНЫМ ДЛЯ ВАС СПОСОБОМ
            </h4>
            <div className="bioTasksButton">
              <Link to="/contact">
                <button>Контакты</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardPharmaceutical;
